import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import Pin from "../../../Components/dashComponents/pin";
import nigeria from "./assets/img/nigeria.svg";

import smile from "./assets/img/smile.jpg";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";
import { MdDelete } from "react-icons/md";

let typingTimer,
  doneTypingInterval = 2000;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone_number: "",
      phone_ok: false,
      isSubmitting: false,
      isLoaded: false,
      isEmpty: true,
      account: "invalid",
      token: null,
      bundles: [],
      product: [],
      beneficiary: false,
      beneficiaries: [],
      beneficiary_type: "smile",
      // {
      //   variation_amount: "0",
      //   variation_code: ""
      // },
    };

    this.setPhone = this.setPhone.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getDataBundle = this.getDataBundle.bind(this);
  }

  deletePost = (beneficiary) => {
    axios
      .post("/user/remove_beneficiary", {
        token: localStorage.getItem("token"),
        beneficiary_type: beneficiary.type,
        beneficiary_number: beneficiary.number,
        // pin: pin,
      })
      .then((response) => {
        console.log(response, "removeBeneficiary");
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDataBundle();

    axios
      .post(ConstantVar.API_BASE_URL + "/user/beneficiaries", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        console.log(response, "dashboard beneficiaries");
        if (response.data.success === "true") {
          self.setState({
            beneficiaries: response.data.data.filter(
              (item) => item.type === "smile"
            ),

            isLoading: false,
          });
        }
        console.log(this.state.beneficiaries.number, "empy");
      });

    //get subscriptions
    let self = this;
    console.log(this.state.bundle);
  }

  setPhone(e) {
    let phoneNo = e.target.value.replace(/\s+/g, "");

    this.setState({
      phone_number: phoneNo,
      phone_ok: phoneNo.length === 11,
    });
  }

  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === "product") {
      this.state.bundles.find((x) => {
        if (x.variation_code === e.target.value) {
          this.setState({
            amount: x.variation_amount,
            sub_plan: x.variation_code,
          });
        }
        return;
      });
    }
  }

  validateCustomerID = async () => {
    const smileData = {
      phone_no: this.state.phone,
      email: this.state.email,
    };

    const { data } = await axios.post(
      `${ConstantVar.API_BASE_URL}/verify_smile`,
      smileData
    );
    console.log("data", data);

    if (data.success === "false") {
      this.setState({ isLoaded: true });
    } else {
      iziToast.error({
        title: data.message.toUpperCase(),
        message: "",
        position: "center",
        theme: "dark",
        color: "red",
      });
    }
  };

  getDataBundle = async () => {
    const { data } = await axios.get(
      `${ConstantVar.API_BASE_URL}/data/subscriptions?provider=smile-direct`
    );

    console.log(data, " :data");
    if (data.success === "true") {
      this.setState({ bundles: data.data });
    }
  };

  render() {
    let smilePayload = {
      phone_number: this.state.phone_number,
      contact_name: this.state.email,
      provider: "smile-direct",
      sub_plan: this.state.sub_plan,
      amount: this.state.amount,
      save_as_beneficiary: this.state.beneficiary?.toString(),
    };

    console.log("smilePayload:", smilePayload);

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              {!this.state.isLoaded ? (
                <div className="col-sm-6">
                  <div className="card-bill card">
                    <div className="card-body">
                      <div className="d-flex justify-content-center">
                        <h4
                          style={{ padding: 30 }}
                          className="text-center font-weight-bold"
                        >
                          Buy Smile Product
                        </h4>
                        <img
                          src={smile}
                          width={20}
                          height={20}
                          alt="smile_logo"
                        />
                      </div>
                      <div style={{ padding: 10 }}>
                        <div className="form-group">
                          <div className="my-2">
                            <div>
                              <label
                                htmlFor="exampleInputEmail1"
                                className="mt-4"
                              >
                                Smile Phone Number{" "}
                              </label>
                              <input
                                name="phone_number"
                                onChange={this.handleChange}
                                required
                                className="form-control login-input phone-input"
                                type="phone"
                                placeholder="Enter smile phone number"
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="exampleInputEmail1"
                                className="mt-4"
                              >
                                Smile Email Address
                              </label>
                              <input
                                onChange={this.handleChange}
                                required
                                className="form-control login-input phone-input"
                                type="email"
                                name="email"
                                placeholder="Enter smile email address"
                              />
                            </div>

                            <div className="row">
                              <select
                                name="product"
                                value={this.state.product}
                                className="custom-select form-control form-control-pad"
                                onChange={this.handleChange}
                              >
                                <option selected value="">
                                  Select data bundle
                                </option>
                                {this.state.bundles.length &&
                                  this.state.bundles.map((item, key) => (
                                    <option
                                      value={item.variation_code}
                                      key={key}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <input
                          type="checkbox"
                          id="beneficiary"
                          name="beneficiary"
                          onChange={this.handleChange}
                        />{" "}
                        <label for="beneficiary">Save as beneficiary</label>
                        <br />
                        <NumberFormat
                          style={{
                            borderBottom: "1px dashed #eee",
                            display: "inline-block",
                            marginBottom: 5,
                          }}
                          value={truncate(this.context.user.available_bal, 2)}
                          className="text-blue"
                          displayType={"text"}
                          prefix={"Balance: NGN "}
                          decimalScale={2}
                          thousandSeparator={true}
                        />
                        <button
                          // disabled={
                          //   !this.state.email || !this.state.phone_number
                          // }
                          onClick={() => {
                            this.validateCustomerID();
                            // this.setState({ ...this.state, isLoaded: true });
                          }}
                          type="submit"
                          className="col-sm-12 pl-0 btn signup-btn text-white"
                        >
                          Proceed now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-sm-6">
                  <div className="card-bill card">
                    <div className="card-body">
                      <h4
                        style={{ padding: 30 }}
                        className="text-center font-weight-bold"
                      >
                        Pay for Smile NGN {this.state.amount}
                      </h4>
                      <div className="">
                        <p>
                          Recipient Number:{" "}
                          <span>{this.state.phone_number}</span>
                        </p>
                        <p>Product Type: {this.state.email}</p>
                        {this.state.waec_option && (
                          <p>Product Name: {this.state.waec_option}</p>
                        )}
                        <p>Charges: NGN {this.state.charges}</p>
                      </div>
                      <div>
                        <button
                          data-toggle="modal"
                          data-target="#pinModal"
                          className="col-sm-12 pl-0 btn btn-success text-white"
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <Pin endpoint="/user/load_airtime" data={smilePayload} /> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.beneficiaries.length > 0 ? (
          <div className="col-sm-8 container dashbord-con">
            <p className="card-inner-pad-tran-text text-muted">
              Saved Beneficiaries
            </p>
            <table className="table  card-bill ">
              <tbody>
                {this.state.beneficiaries.map((beneficiary, index) => (
                  <tr className="table-hover">
                    <td
                      className="table-dash-img text-center"
                      style={{ width: "17%" }}
                    >
                      <div className="phone-bckg">
                        <i className="fas fa-phone phone-color" />
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 1 }}>
                        <p>{beneficiary.number}</p>
                        <br />
                        <p className="table-dash-details2">
                          {beneficiary.name}{" "}
                        </p>
                      </div>
                    </td>
                    <td>
                      <button
                        data-toggle="modal"
                        type="submit"
                        onClick={() => this.setState({ beneficiary: true })}
                        data-target="#pinModal"
                        style={{ color: "red", border: "none" }}
                      >
                        <MdDelete />
                      </button>

                      <Pin
                        endpoint={
                          !this.state.beneficiary
                            ? "/user/load_airtime"
                            : "/user/remove_beneficiary"
                        }
                        data={
                          this.state.beneficiary
                            ? {
                                beneficiary_type: beneficiary.type,
                                beneficiary_number: beneficiary.number,
                              }
                            : {
                                provider: this.state.selected,
                                phone_no: this.state.phone_number,
                                sub_plan: this.state.key_code,
                                save_as_beneficiary: `${this.state.checked}`,
                                use_loan_wallet: "false",
                              }
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}

Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
