import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import airtime from "./assets/img/airtime.png";
import betting from "./assets/img/betting.png";
import data from "./assets/img/data.png";
import cable from "./assets/img/cable.png";
import electricity from "./assets/img/electricity.png";
import history from "./assets/img/history.png";
import work from "./assets/img/work.png";

class Action_Bar extends Component {
  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        <div className="card card-bill" style={{ marginTop: 0 }}>
          <div className="row">
            <div className="col-sm-2 col-4">
              <Link className="no-decoration" to="/dashboard/services/airtime">
                <div className="text-center action-card">
                  <img src={airtime} />
                  <p>Load Airtime</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-2 col-4">
              <Link className="no-decoration" to="/dashboard/services/betting">
                <div className="text-center action-card">
                  <img src={betting} />
                  <p>Fund Bet Account</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-2 col-4">
              <Link className="no-decoration" to="/dashboard/services/data">
                <div className="text-center action-card">
                  <img src={data} />
                  <p>Buy Data</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-2 col-4">
              <Link className="no-decoration" to="/dashboard/services/cable">
                <div className="text-center action-card">
                  <img src={cable} />
                  <p>Pay for Cable</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-2 col-4">
              <Link
                className="no-decoration"
                to="/dashboard/services/electricity"
              >
                <div className="text-center action-card">
                  <img src={electricity} />
                  <p>Buy Electricity</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-2 col-4">
                <div className="text-center action-card">
                  <img src={work} />
                  <div className="dropdown">
                    <p
                      className="dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      More Services
                    {" "}
                    </p>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link className="no-decoration" to="/dashboard/services/waec">
                        <p className="dropdown-item">Waec Services</p>
                      </Link>
                      <Link className="no-decoration" to="/dashboard/services/smile">
                        <p className="dropdown-item">Smile Services</p>
                      </Link>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-sm-2 col-4">
              <Link className="no-decoration" to="/dashboard/transactions">
                <div className="text-center action-card">
                  <img src={history} />
                  <p>Transaction History</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Action_Bar;
