import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Key from "../../homeAssets/key.png";
import nigeria from "../../homeAssets/nigeria.svg";
import { Link } from "react-router-dom";
import { getSignup } from "../../repo.js";
import ReactCodeInput from "react-code-input";
import AppContext from "../../AppContext";
import axios from "axios";
import iziToast from "izitoast";
import * as ConstantVar from "../../constants_var";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneFocus: false,
      pin: "",
      fname: "",
      lname: "",
      email: "",
      phone: "",
      isLoading: true,
      isSubmitting: false,
      stage: 1,
      otp: "",
      timer_active: true,
      timer: 60,
      is_timer: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handlePinChange(evt) {
    this.setState({ pin: evt });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    let fname = evt.target.fname.value,
      lname = evt.target.lname.value,
      phone = this.state.phone,
      pin = this.state.pin,
      email = evt.target.email.value,
      ref = null; //this.props.match.params.ref ||

    this.setState({
      isSubmitting: true,
      timer_active: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/user/register", {
        fname: fname,
        lname: lname,
        phone: phone,
        pin: pin,
        email: email,
        ref: ref,
        phone_verified: "true",
      })
      .then((response) => {
        if (response.data.success === "true") {
          //bind token
          localStorage.setItem("token", response.data.data);

          //redirect to dashboard
          window.location.href = "/dashboard/overview";
        } else {
          this.setState({
            isSubmitting: false,
          });

          //show error message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  }

  checkPhoneAvailabilityAndSendOtp = (evt) => {
    if (evt !== undefined) {
      evt.preventDefault();
    }

    let phone = this.state.phone;
    if (phone.length < 10) {
      return false;
    }

    this.setState({
      isSubmitting: true,
      timer_active: true,
      timer: 60,
    });

    axios
      .post(
        ConstantVar.API_BASE_URL + "/check_phone_availability_and_send_otp",
        {
          phone: phone,
        }
      )
      .then((response) => {
        if (response.data.success === "true") {
          this.setState({
            isSubmitting: false,
            stage: 2,
          });

          // otp sent
          iziToast.success({
            title: "Otp has been sent to " + this.state.phone,
            message: "",
            position: "topRight",
            theme: "light",
            color: "green",
          });

          if (!this.state.is_timer) {
            setInterval(this.countDown, 1000);
            this.setState({
              is_timer: true,
            });
          }
        } else {
          //show error message
          this.setState({
            isSubmitting: false,
          });
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  };

  verifyOtp = (evt) => {
    evt.preventDefault();
    let otp = evt.target.otp.value;

    if (otp.length !== 6) {
      return false;
    }

    this.setState({
      isSubmitting: true,
    });
    let phone = this.state.phone;
    axios
      .post(ConstantVar.API_BASE_URL + "/verify_otp", {
        phone: phone,
        otp: otp,
      })
      .then((response) => {
        if (response.data.success === "true") {
          this.setState({
            isSubmitting: false,
            stage: 3,
          });
          // otp verified
          iziToast.success({
            title: "Otp verified ",
            message: "",
            position: "topRight",
            theme: "light",
            color: "green",
          });
        } else {
          //show error message
          this.setState({
            isSubmitting: false,
          });
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  };

  componentDidMount() {
    /*document.getElementBy
    Id("fname").focus();
    setTimeout(() => {
      document.getElementById("fname").type = "text";
      document.getElementById("lname").type = "text";
      document.getElementById("mail").type = "email";
    }, 1000);*/
  }
  countDown = () => {
    let timer = this.state.timer;
    if (timer === 0) {
      this.setState({
        timer_active: false,
      });
      return;
    }
    this.setState({
      timer: timer - 1,
    });
  };

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar />
        <section className="login-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-3" />
              <div className=" col-sm-6">
                <div className="card all-card login-card">
                  {(() => {
                    if (this.state.stage === 1) {
                      return (
                        <div>
                          <h2 className="text-center">Welcome!</h2>
                          <p className="text-center pb-4">
                            Take The First Step Towards Getting Your First Loan
                          </p>

                          <form
                            onSubmit={this.checkPhoneAvailabilityAndSendOtp}
                            autoComplete="off"
                          >
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Phone Number
                              </label>
                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div
                                    className="input-group-text"
                                    style={{
                                      width: 50,
                                      backgroundColor: "white",
                                      borderColor: this.state.phoneFocus
                                        ? "#495057"
                                        : "#eee",
                                    }}
                                  >
                                    <img
                                      src={nigeria}
                                      style={{ width: "100%", marginLeft: 8 }}
                                    />
                                  </div>
                                </div>
                                <input
                                  required
                                  onClick={() => {
                                    this.setState({ phoneFocus: true });
                                  }}
                                  onBlur={() => {
                                    this.setState({ phoneFocus: false });
                                  }}
                                  type="number"
                                  name="phone"
                                  value={this.state.phone}
                                  onChange={this.handleChange}
                                  style={{ borderLeft: 0 }}
                                  className="form-control login-input phone-input"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="081xxxxxxxx"
                                />
                              </div>
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                Your one time password will be sent to this
                                number
                              </small>
                            </div>
                            <button
                              disabled={this.state.isSubmitting}
                              type="submit"
                              className="col-sm-12 pl-0 btn signup-btn text-white"
                            >
                              Proceed
                            </button>
                          </form>
                        </div>
                      );
                    } else if (this.state.stage === 2) {
                      return (
                        <div>
                          <h2 className="text-center">Enter OTP</h2>
                          <p className="text-center pb-4">
                            Please enter the otp sent to
                            <b> {this.state.phone}</b>
                            <div class="text-info">
                              <small>
                                {this.state.timer_active ? (
                                  "Retry in " + this.state.timer + "s"
                                ) : (
                                  <label
                                    onClick={
                                      this.checkPhoneAvailabilityAndSendOtp
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    Retry
                                  </label>
                                )}
                              </small>
                            </div>
                          </p>

                          <form onSubmit={this.verifyOtp} autoComplete="off">
                            <div className="form-group">
                              <label>Enter OTP</label>
                              <div className="form-group">
                                <input
                                  type="number"
                                  autoComplete="off"
                                  name="otp"
                                  value={this.state.otp}
                                  onChange={this.handleChange}
                                  className="form-control login-input"
                                  placeholder="Enter OTP"
                                />
                                {/*<small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                Please enter the otp sent to{" "}
                                <b>{this.state.phone}</b>
                              </small>*/}
                              </div>
                            </div>
                            <button
                              disabled={this.state.isSubmitting}
                              type="submit"
                              className="col-sm-12 pl-0 btn signup-btn text-white"
                            >
                              Confirm
                            </button>
                          </form>
                        </div>
                      );
                    } else if (this.state.stage === 3) {
                      return (
                        <div>
                          <h2 className="text-center">Continue</h2>
                          <p className="text-center pb-4">
                            Provide below information to complete your
                            registration
                          </p>

                          <form onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label htmlFor="exampleInputFirstname">
                                    First Name
                                  </label>
                                  <input
                                    id="fname"
                                    type="text"
                                    name="fname"
                                    value={this.state.fname}
                                    onChange={this.handleChange}
                                    className="form-control login-input"
                                    placeholder="First Name"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label htmlFor="exampleInputLastname">
                                    Last Name
                                  </label>
                                  <input
                                    id="lname"
                                    type="text"
                                    name="lname"
                                    value={this.state.lname}
                                    onChange={this.handleChange}
                                    className="form-control login-input"
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <input
                                type="email"
                                id="mail"
                                autoComplete="off"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                className="form-control login-input"
                                placeholder="Enter email"
                              />
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                We'll never share your email with anyone else.
                              </small>
                            </div>
                            <div className="form-group">
                              <label
                                className="d-block"
                                htmlFor="exampleInputPassword1"
                              >
                                Pin
                              </label>
                              <ReactCodeInput
                                type="password"
                                inputMode="number"
                                fields={4}
                                value={this.state.pin}
                                onChange={this.handlePinChange}
                              />
                            </div>
                            <button
                              disabled={this.state.isSubmitting}
                              type="submit"
                              className="col-sm-12 pl-0 btn signup-btn text-white"
                            >
                              Sign Up
                            </button>
                          </form>
                        </div>
                      );
                    }
                  })()}

                  <div class="text-left">
                    <label
                      style={{ marginTop: 10 }}
                      className="text-right pr-0"
                      htmlFor="exampleInputPassword1"
                    >
                      Already signed up? <Link to="/login/account">Log In</Link>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
Signup.contextType = AppContext;
export default Signup;
