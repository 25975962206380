import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import Pin from "../../../Components/dashComponents/pin";
import nigeria from "./assets/img/nigeria.svg";
import ekedc from "./assets/img/ekedc.jpg";
import ikedc from "./assets/img/ikedc.jpg";
import phed from "./assets/img/phed.jpg";
import kano from "./assets/img/kedc.jpg";
import kaduna from "./assets/img/kaedc.jpg";
import ibadan from "./assets/img/ibedc.png";
import jos from "./assets/img/jedc.jpg";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";
import { MdDelete } from "react-icons/md";

let typingTimer,
  doneTypingInterval = 2000;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waec_product: "",
      phone_number: "",
      phone_ok: false,
      isSubmitting: false,
      isLoading: false,
      isEmpty: true,
      account: "invalid",
      token: null,
      beneficiary: false,
      checked: false,
      beneficiaries: [],
      beneficiary_type: "waec",
    };

    this.setPhone = this.setPhone.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ checked: !this.state.checked }, () => {
      console.log(this.state.checked, "this.state.checked");
    });
  };

  deletePost = (beneficiary) => {
    axios
      .post("/user/remove_beneficiary", {
        token: localStorage.getItem("token"),
        beneficiary_type: beneficiary.type,
        beneficiary_number: beneficiary.number,
        // pin: pin,
      })
      .then((response) => {
        console.log(response, "removeBeneficiary");
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCharge();
    // get beneficiaries
    axios
      .post(ConstantVar.API_BASE_URL + "/user/beneficiaries", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        console.log(response, "dashboard beneficiaries");
        if (response.data.success === "true") {
          self.setState({
            beneficiaries: response.data.data.filter(
              (item) => item.type === "waec"
            ),
            isLoading: false,
          });
        }
        console.log(this.state.beneficiaries.number, "empy");
      });

    //get subscriptions
    let self = this;
  }

  setPhone(e) {
    let phoneNo = e.target.value.replace(/\s+/g, "");

    this.setState({
      phone_number: phoneNo,
      phone_ok: phoneNo.length === 11,
    });
  }

  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getCharge = async () => {
    const { data } = await axios.get(
      `${ConstantVar.API_BASE_URL}/get/waec_charges`
    );

    if (data.success === "true") {
      this.setState({ charges: data.charges });
    }
  };

  getPriceVariation = async () => {
    const endpoint =
      this.state.waec_product === "waec-registration"
        ? "data/subscriptions?provider=waec-registration"
        : "data/subscriptions?provider=waec";
    const { data } = await axios.get(`${ConstantVar.API_BASE_URL}/${endpoint}`);

    console.log("data", data);
    if (data.success === "true") {
      this.setState({
        amount: data.data[0].variation_amount,
        code: data.data[0].variation_code,
      });
    }
    if (data.success === "false") {
      iziToast.error({
        title: data.message.toUpperCase(),
        message: "",
        position: "center",
        theme: "dark",
        color: "red",
      });
    }
  };

  render() {
    let waecPayload = {
      phone_number: this.state.phone_number,
      productName: this.state.waec_product,
      productType:
        this.state.waec_product === "waec-registration"
          ? "WAEC Registration"
          : this.state.waec_product === "waec"
          ? "WAEC Result Checker"
          : null,
      amount: this.state.amount,
      variationCode: this.state.code,
      sub_plan: this.state.code,
      serviceId: this.state.waec_product,
      provider: this.state.waec_product,
      charges: this.state.charges,
    };

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              {!this.state.isLoading ? (
                <div className="col-sm-6">
                  <div className="card-bill card">
                    <div className="card-body">
                      <h4
                        style={{ padding: 30 }}
                        className="text-center font-weight-bold"
                      >
                        Buy WAEC Product
                      </h4>
                      <div style={{ padding: 10 }}>
                        <h6></h6>

                        <div className="form-group">
                          <div className="my-2">
                            <div className="row">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="mt-4"
                              >
                                WAEC Product{" "}
                              </label>

                              <select
                                name="waec_product"
                                className="custom-select form-control form-control-pad"
                                onChange={this.handleChange}
                              >
                                <option selected value="">
                                  Select a Product
                                </option>
                                <option value="waec-registration">
                                  WAEC Registration
                                </option>
                                <option value="waec">
                                  WAEC Result Checker
                                </option>
                              </select>
                            </div>
                            {this.state.waec_product == "waec" && (
                              <div className="row">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="mt-4"
                                >
                                  WAEC Option{" "}
                                </label>

                                <select
                                  name="waec_option"
                                  className="custom-select form-control form-control-pad"
                                  onChange={this.handleChange}
                                >
                                  <option selected value="">
                                    Select Product Option
                                  </option>
                                  <option value="wassce/gce">WASSCE/GCE</option>
                                </select>
                              </div>
                            )}
                            <div className="row">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="mt-4"
                              >
                                Phone Number
                              </label>
                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div
                                    className="input-group-text"
                                    style={{
                                      width: 50,
                                      backgroundColor: "white",
                                      borderColor: this.state.phoneFocus
                                        ? "#495057"
                                        : "#eee",
                                    }}
                                  >
                                    <img
                                      src={nigeria}
                                      style={{ width: "100%", marginLeft: 8 }}
                                    />
                                  </div>
                                </div>
                                <input
                                  required
                                  onClick={() => {
                                    this.setState({ phoneFocus: true });
                                  }}
                                  onBlur={() => {
                                    this.setState({ phoneFocus: false });
                                  }}
                                  type="number"
                                  name="phone_number"
                                  value={this.state.phone_number}
                                  onChange={this.setPhone}
                                  style={{ borderLeft: 0 }}
                                  className="form-control login-input phone-input"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Recipient Phone number"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={this.handleChange}
                            defaultChecked={this.state.checked}
                            style={{ marginRight: 10 }}
                          />
                          <label>Save as beneficiary</label>
                        </div>
                        <NumberFormat
                          style={{
                            borderBottom: "1px dashed #eee",
                            display: "inline-block",
                            marginBottom: 5,
                          }}
                          value={truncate(this.context.user.available_bal, 2)}
                          className="text-blue"
                          displayType={"text"}
                          prefix={"Balance: NGN "}
                          decimalScale={2}
                          thousandSeparator={true}
                        />

                        <button
                          disabled={
                            !this.state.waec_product || !this.state.phone_number
                          }
                          onClick={() => {
                            this.getPriceVariation();
                            this.setState({ ...this.state, isLoading: true });
                          }}
                          type="submit"
                          className="col-sm-12 pl-0 btn signup-btn text-white"
                        >
                          Proceed now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-sm-6">
                  <div className="card-bill card">
                    <div className="card-body">
                      {this.state.amount && (
                        <h4
                          style={{ padding: 30 }}
                          className="text-center font-weight-bold"
                        >
                          Pay for Waec NGN {this.state.amount}
                        </h4>
                      )}
                      <div className="">
                        <p>
                          Recipient Number:{" "}
                          <span>{this.state.phone_number}</span>
                        </p>
                        <p>Product Type: {this.state.waec_product}</p>
                        {this.state.waec_option && (
                          <p>Product Name: {this.state.waec_option}</p>
                        )}
                        <p>Charges: NGN {this.state.charges}</p>
                      </div>

                      <div>
                        <button
                          data-toggle="modal"
                          data-target="#pinModal"
                          className="col-sm-12 pl-0 btn btn-success text-white"
                          disabled={!this.state.amount}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <Pin endpoint="/user/pay_waec" data={waecPayload} /> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.beneficiaries.length > 0 ? (
          <div className="col-sm-8 container dashbord-con">
            <p className="card-inner-pad-tran-text text-muted">
              Saved Beneficiaries
            </p>
            <table className="table  card-bill ">
              <tbody>
                {this.state.beneficiaries.map((beneficiary, index) => (
                  <tr className="table-hover">
                    <td
                      className="table-dash-img text-center"
                      style={{ width: "17%" }}
                    >
                      <div className="phone-bckg">
                        <i className="fas fa-phone phone-color" />
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 1 }}>
                        <p>{beneficiary.number}</p>
                        <br />
                        <p className="table-dash-details2">
                          {beneficiary.name}{" "}
                        </p>
                      </div>
                    </td>
                    <td>
                      <button
                        data-toggle="modal"
                        type="submit"
                        onClick={() => this.setState({ beneficiary: true })}
                        data-target="#pinModal"
                        style={{ color: "red", border: "none" }}
                      >
                        <MdDelete />
                      </button>

                      <Pin
                        endpoint={
                          !this.state.beneficiary
                            ? "/user/pay_waec"
                            : "/user/remove_beneficiary"
                        }
                        data={
                          this.state.beneficiary
                            ? {
                                beneficiary_type: beneficiary.type,
                                beneficiary_number: beneficiary.number,
                              }
                            : {
                                waecPayload,
                                save_as_beneficiary: `${this.state.checked}`,
                                use_loan_wallet: "false",
                              }
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}

Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
