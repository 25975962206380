import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import AppContext from '../AppContext'
import about from '../homeAssets/aboutUs.png'
import hometownMission from '../homeAssets/hometownMission.png'
import hometownVision from '../homeAssets/hometownVision.png'


class About extends React.Component{

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return(
             <div style={{backgroundColor: "white"}}>
                <Navbar/>
               <section>
                  <div className="container">
                  <div className="text-box">
                  <h3 className="text-center">What is Hometown™</h3>
                  <p>
                  Hometown™ is a sociopreneur application designed to offer credit and loan facilities to Nigerians within the shortest possible time and with a flexible repayment plan ranging from 15 days to six months and with interest rates beginning from as low as 15%! The greater your loan request, the lower your interest!

We are not just a loan service provider. Our other mobile app services allows you carry out banking services all from your mobile phone. With the Hometown App, you can send and receive money, pay for your essential services (DSTV, PHCN, subscriptions, etc), recharge your phone, get a loan, invest your spare funds, etc.
                  </p>
                  <blockquote className="about-blockquote">
                      <q className="text-white blockquote-size">
                      <p className=""> The greater your loan request, the lower your interest!</p>
                     </q>
                    
                  </blockquote>
                 </div>
                  </div>
                </section>
                <section>
                <div className="container">
                    <div className="row">
                         <div className="col-sm-1"/>
                        <div className="col-12 col-sm-5">
                            <p className="text-center">
                                <img className="about-icon" src={hometownVision}/>
                            </p>
                            <h4 className="text-center">VISION</h4>
                            <p className="text-center">Our VISION is to provide a platform where every Nigerian irrespective of their income level can have unencumbered access to credit facilities to meet up with their immediate need while allowing them a flexible repayment plan and period.
                            </p>
                        </div>
                        <div className="col-12 col-sm-5">
                        <p className="text-center">
                                <img className="about-icon" src={hometownMission}/>
                            </p>
                        <h4 className="text-center">MISSION</h4>
                            <p className="text-center">Our MISSION is to continually redefine various means of sourcing for affordable capital and mitigating the risk of repayment so as to continually provide affordable credit facilities to Nigerians via our platform at every point of their financial need.
                             </p>
                        
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                </div>
              
                </section>
                 <Footer/>
            </div>  
        )
    }
}
About.contextType = AppContext
export default About