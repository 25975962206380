import React, { Component } from "react";
import "./assets/index.css";
import axios from "axios";
import * as ConstantVar from "./../../constants_var";

class Verification_Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      isLoading: true,
      banks: [],
    };
  }
  componentDidMount() {
    let self = this;

    axios.get(ConstantVar.API_BASE_URL + "/banks").then((response) => {
      self.setState({
        isLoading: false,
        banks: response.data.data,
      });
    });
  }
  render() {
    if (!this.state.isLoading)
      return (
        <select
          className="custom-select form-control form-control-pad"
          required
          name="bank"
          id="nigeriaBanksList"
          onChange={this.props.handleChange}
        >
          <option hidden className="text-muted" selected>
            Select bank
          </option>
          {this.state.banks.map((bank, index) => {
            return (
              <option key={index} value={bank.Code}>
                {bank.Name}
              </option>
            );
          })}
        </select>
      );
    else
      return (
        <div
          style={{ display: "block" }}
          className="spinner-border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
  }
}

export default Verification_Bar;
