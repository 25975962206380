import React, { Component } from "react";
import "./assets/index.css";
import axios from "axios";
import * as ConstantVar from "../../constants_var";

class Verification_Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
    this.resendEmail = this.resendEmail.bind(this);
  }
  resendEmail() {
    //for resending verification email
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/user/resend_ver_email", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        //
        if (response.data.success === "true") self.setState({ sent: true });
      });
  }
  render() {
    if (this.props.email_verified_at === null)
      return (
        <div style={{ paddingBottom: 0, marginTop: 30 }}>
          <div className="email-ver">
            <div style={{ padding: 20 }}>
              <div
                className="fifteen wide computer thirteen wide mobile column"
                style={{ paddingLeft: 0 }}
              >
                <span className="font-weight-bold">
                  Email verification required
                </span>
                <br />
                <span style={{ fontSize: "1em" }}>
                  You cannot take a loan or fund your wallet without verifying
                  your email address. Check your email for verification email.
                </span>
                {(() => {
                  if (this.state.sent === false)
                    return (
                      <a
                        href="#"
                        onClick={this.resendEmail}
                        style={{ cursor: "pointer", fontSize: "0.85em" }}
                      >
                        {" "}
                        Resend verification email
                      </a>
                    );
                  else
                    return (
                      <span
                        className="text-green"
                        style={{
                          cursor: "pointer",
                          fontSize: "0.85em",
                          color: "limegreen",
                        }}
                      >
                        {" "}
                        Email re-sent successfully.
                      </span>
                    );
                })()}
              </div>
            </div>
          </div>
        </div>
      );
    else return null;
  }
}

export default Verification_Bar;
