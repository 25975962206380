import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";
import AppContext from "../../AppContext";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import ReactCodeInput from "react-code-input";
import * as ConstantVar from "../../constants_var";

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      isSubmitting: false,
    };
    this.handlePinChange = this.handlePinChange.bind(this);
    this.verifyPasswordChange = this.verifyPasswordChange.bind(this);
  }
  handlePinChange(evt) {
    this.setState({ pin: evt });
  }

  verifyPasswordChange(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });
    let self = this,
      token = localStorage.getItem("resetToken");

    axios
      .post(ConstantVar.API_BASE_URL + "/account/update", {
        new_pin: this.state.pin,
        token: token,
      })
      .then(function (response) {
        self.setState({ isSubmitting: false });

        //check response and decide on what to do
        if (response.data.success === "false") {
          //show error
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        } else if (response.data.success === "true") {
          iziToast.success({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "green",
          });

          setTimeout(() => {
            window.location = "/login/account";
          }, 1000);
        }
      });
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar />
        <section className="login-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-3" />
              <div className=" col-sm-6">
                <div className="card all-card login-card">
                  <h2 className="text-center pb-4">Update Pin</h2>
                  <form onSubmit={this.verifyPasswordChange}>
                    <div className="form-group">
                      <label
                        className="col-sm-6 pl-0"
                        htmlFor="exampleInputPassword1"
                      >
                        New Pin
                      </label>
                      <br />
                      <ReactCodeInput
                        type="password"
                        inputMode="number"
                        fields={4}
                        value={this.state.pin}
                        onChange={this.handlePinChange}
                      />
                    </div>
                    <button
                      disabled={this.state.isSubmitting}
                      type="submit"
                      className="col-sm-12 pl-0 btn signup-btn text-white"
                    >
                      Update Pin
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Verify.contextType = AppContext;

export default Verify;
