import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import loanimg from "./assets/img/loan-img.jpg";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import ActionBar from "../../../Components/dashComponents/action_bar";
import transimg from "../Transactions/assets/img/transactions.png";
import transfer from "./assets/img/transfer.png";
import invest from "./assets/img/invest.png";
import deposit from "./assets/img/deposit.png";
import SlickSlider from "../DashboardSlick/index.js";
import googleButton from "../../../homeAssets/googleButton.png";
import NumberFormat from "react-number-format";
import * as ConstantVar from "../../../constants_var";
import iziToast from "izitoast";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      akawo: {},
      isLoading: true,
      type: "spin",
      account_number: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //bind this to self
    let self = this;

    axios
      .post(ConstantVar.API_BASE_URL + "/user/retrieve_banks", {
        token: localStorage.getItem("token"),
      })
      .then(function (response) {
        //remove loading
        self.setState({
          isLoading: false,
        });

        if (response.data.success === "true") {
          self.setState({
            bank: response.data.data.bank,
            account_number: response.data.data.account_number,
          });
        } else {
          //show message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });

    axios
      .post(ConstantVar.API_BASE_URL + "/user/web_transactions", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        console.log(response, "dashboard trans");
        if (response.data.success === "true") {
          self.setState({
            transactions: response.data.data.data,
            isLoading: false,
          });
        }
      });

    axios
      .post(ConstantVar.API_BASE_URL + "/user/akawos", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            akawo: response.data.data,
            isLoading: false,
          });
        }
      });
  }

  render() {
    let akawo = this.state.akawo,
      user = this.context.user;

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar email_verified_at={user.email_verified_at} />
            <SlickSlider />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            <ActionBar />

            {this.state.isLoading ? (
              <div style={{ marginTop: 0 }} className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <Link to="/dashboard/deposit/bank" className="account-link">
                <div className="account-container">
                  <div>Account Details : </div>
                  <div>
                    {this.state.account_number} ({this.state.bank})
                  </div>
                </div>
              </Link>
            )}

            <div className="row row-m pad-top-m">
              <div className="col-sm-4">
                {(() => {
                  if (this.state.isLoading)
                    return (
                      <div style={{ marginTop: 0 }} className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    );
                  else
                    return (
                      <div className="card card-balance card-inner-pad">
                        <div className="light-blue-bckg p-3">
                          <p className="card-bal-header text-white">
                            <span style={{ opacity: 0.5 }}>Wallet Balance</span>
                            <span className="float-right wallet-status font-weight-bold">
                              ACTIVE
                            </span>
                          </p>
                          <NumberFormat
                            style={{ fontSize: 30, marginTop: 10 }}
                            value={truncate(this.context.user.available_bal, 2)}
                            className="card-bal-your text-white font-weight-bold"
                            displayType={"text"}
                            prefix={"NGN "}
                            decimalScale={2}
                            thousandSeparator={true}
                          />
                        </div>
                        <div className="p-3">
                          <div className="row">
                            <div className="col-sm-4 col-4 round-btn-m">
                              <div className="text-center pt-4 depo-img-m">
                                <Link
                                  to="/dashboard/deposit/amount"
                                  className="round-btn"
                                >
                                  <img className="img-dash" src={deposit} />
                                </Link>
                                <p
                                  className="depo-m"
                                  style={{ paddingTop: 20 }}
                                >
                                  Fund wallet
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 col-4">
                              <div className="text-center pt-4">
                                <Link
                                  to="/dashboard/invest"
                                  className="round-btn"
                                >
                                  <img className="img-dash" src={invest} />
                                </Link>
                                <p style={{ paddingTop: 20 }}>Invest</p>
                              </div>
                            </div>
                            <div className="col-sm-4 col-4">
                              <div className="text-center pt-4">
                                <Link
                                  to="/dashboard/transfer"
                                  className="round-btn"
                                >
                                  <img className="img-dash" src={transfer} />
                                </Link>
                                <p style={{ paddingTop: 20 }}>Transfer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                })()}
                <div className="row">
                  <div className="col-12">
                    <p>Download our mobile app for faster transactions</p>
                  </div>
                  <div
                    className="col-5 col-sm-6 pb-2"
                    style={{ marginRight: "inherit" }}
                  >
                    <a className="https://play.google.com/store/apps/details?id=com.hometown.app&hl=en">
                      <img
                        onClick={() => {
                          window.location.href =
                            "https://play.google.com/store/apps/details?id=com.hometown.app&hl=en";
                        }}
                        className="google-btn"
                        src={googleButton}
                        alt="googleButton"
                      />
                    </a>
                  </div>
                  {/*
                                    <div className="col-5 col-sm-3">
                                        <a className="">
                                            <img className="google-btn" src={appleButton} alt="googleButton"/>
                                        </a>
                                    </div>*/}
                  <div className="col-sm-3" />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card card-inner-pad-tran">
                  <p className="card-inner-pad-tran-text text-muted">
                    Recent transactions
                  </p>
                  {(() => {
                    if (this.state.isLoading)
                      return (
                        <div style={{ marginTop: 0 }} className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      );
                    else
                      return (
                        <table className="table">
                          <tbody>
                            {this.state.transactions.length > 0 ? (
                              this.state.transactions.map((trx, index) => {
                                return (
                                  <tr className="table-hover" key={trx.id}>
                                    <td
                                      className="table-dash-img text-center"
                                      style={{ width: "17%" }}
                                    >
                                      {trx.slug === "wallet_deposit" ? (
                                        <div className="wallet-bckg">
                                          <i className="fas fa-wallet wallet-color" />
                                        </div>
                                      ) : trx.slug === "airtime_recharge" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-phone phone-color" />
                                        </div>
                                      ) : trx.slug === "pay_cable" ? (
                                        <div className="tv-bckg">
                                          <i className="fas fa-tv tv-color" />
                                        </div>
                                      ) : trx.slug === "tv_subscription" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-phone phone-color" />
                                        </div>
                                      ) : trx.slug === "pay_electric" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-bolt phone-color" />
                                        </div>
                                      ) : trx.slug === "fund_bet" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-money-bill-alt phone-color" />
                                        </div>
                                      ) : trx.slug === "invest" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-wallet phone-color" />
                                        </div>
                                      ) : trx.slug === "akawo_credit" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-wallet phone-color" />
                                        </div>
                                      ) : trx.slug === "transfer" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-money-bill-alt phone-color" />
                                        </div>
                                      ) : trx.slug === "loan" ? (
                                        <div className="phone-bckg">
                                          <i className="fas fa-money-bill-alt phone-color" />
                                        </div>
                                      ) : (
                                        <p className="reverse-bckg">Hometown</p>
                                      )}
                                    </td>
                                    <td className="table-dash2">
                                      <p className="text-muted trans-date">
                                        {moment(trx.created_at).format(
                                          "MMM Do, YYYY"
                                        )}
                                      </p>{" "}
                                      <br />
                                    </td>
                                    <td>
                                      <div>
                                        <p className="font-weight-bold table-dash-details1 table-trans-details1">
                                          {trx.type}
                                        </p>{" "}
                                        <br />
                                        <p className="table-dash-details2">
                                          {trx.description}{" "}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="amount-td">
                                      {trx.direction === "credit" ? (
                                        <span className="float-right trans-credit-color font-weight-normal">
                                          <NumberFormat
                                            value={trx.amount}
                                            className="card-bal-your"
                                            displayType={"text"}
                                            prefix={"+ NGN "}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                          />
                                        </span>
                                      ) : (
                                        <span className="float-right trans-debit-color font-weight-normal">
                                          <NumberFormat
                                            value={trx.amount}
                                            className="card-bal-your"
                                            displayType={"text"}
                                            prefix={"- NGN "}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                          />
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td>
                                  <div className="text-center no-data">
                                    <img
                                      className="no-trans-overview"
                                      src={transimg}
                                    />
                                    <p className="text-muted no-trans-text-o">
                                      No transaction done yet!
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
