import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './assets/index.css';
import Navbar from '../../../Components/dashComponents/navbar';


class Settings extends Component {

    render() {
        return (
            <div>
                <Navbar />
                <div className="bckgrd-color">
                    <div className="container">
                        <h4>Settings</h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;