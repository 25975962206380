import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import imgDeposit from "./assets/bank_grey.png";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      bank: "",
      account_number: "",
      account_name: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //get bank accounts
    let self = this;

    axios
      .post(ConstantVar.API_BASE_URL + "/user/retrieve_banks", {
        token: localStorage.getItem("token"),
      })
      .then(function (response) {
        //remove loading
        self.setState({
          isLoading: false,
        });

        if (response.data.success === "true") {
          self.setState({
            bank: response.data.data.bank,
            account_number: response.data.data.account_number,
          });
        } else {
          //show message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  }
  render() {
    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card-bill card">
                  <div className="card-body">
                    <div className="text-center">
                      <h4 style={{ paddingTop: 30 }}>Bank Deposit</h4>
                      <p style={{ margin: 20 }}>
                        <img style={{ height: 60 }} src={imgDeposit} />
                      </p>
                      <hr />
                      <p>
                        This is your unique Hometown Bank account, any funds
                        sent here will be automatically funded to your Hometown
                        wallet
                      </p>
                      <hr />
                    </div>
                    {(() => {
                      if (this.state.isLoading)
                        return (
                          <div style={{ marginTop: 0 }} className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        );
                      else
                        return (
                          <div style={{ padding: 20 }}>
                            <div style={{ marginBottom: 25 }}>
                              <div className="row">
                                <div className="col-4">
                                  <p>Bank Account: </p>
                                  <p>Bank: </p>
                                  <p>Account Name: </p>
                                </div>
                                <div className="col-8">
                                  <p className="font-weight-bold">
                                    {this.state.account_number}
                                  </p>
                                  <p className="font-weight-bold">
                                    {this.state.bank}
                                  </p>
                                  <p className="font-weight-bold">
                                    {this.state.account_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="alert alert-success"
                              style={{ width: "100%" }}
                            >
                              <p className="text-success mb-0 text-center">
                                After making a transfer, kindly wait for a few
                                minutes for your payment to be confirmed
                                automatically.
                              </p>
                            </div>
                          </div>
                        );
                    })()}
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
