import React, { Component } from "react";
import "./assets/index.css";
import axios from "axios";
import * as ConstantVar from "./../../constants_var";
import VirtualizedSelect from 'react-virtualized-select'
 
import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'


class SearchableBanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      isLoading: true,
      banks: [],
    };
  }
  componentDidMount() {
    let self = this;

    axios.get(ConstantVar.API_BASE_URL + "/banks").then((response) => {

      let filteredBanks = []
      response.data.data.map((bank, index) => {
          filteredBanks.push({
            value:bank.Code,
            label:bank.Name
          })
      });

      self.setState({
        isLoading: false,
        banks: filteredBanks,
      });
    });
  }
  render() {
    if (!this.state.isLoading)
      return (
        <VirtualizedSelect
        options={this.state.banks}
        onChange={(selectValue) => { 
          this.props.handleChange(selectValue);
          this.setState({ selectValue }) }}
        value={this.state.selectValue}
      />
      );
    else
      return (
        <div
          style={{ display: "block" }}
          className="spinner-border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
  }
}

export default SearchableBanks;
