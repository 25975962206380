import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

class Verify extends Component {
    render() {
        return(
            <div style={{backgroundColor: "white"}}>
                <Navbar/>
                <section className="login-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3"/>
                            <div className=" col-sm-6">
                                <div className="card all-card login-card">
                                    <h2 className="text-center">
                                        Link Verification Failed
                                    </h2>
                                    <p className="text-center pb-4">The pin reset link seems to have an issue, kindly ensure you're clicking the right link.</p>
                                    <a
                                        href="/account/recovery"
                                        className="btn btn-danger text-white">Retry
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-3"/>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        )
    }
}

export default Verify;
