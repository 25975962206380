import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import Pin from "../../../Components/dashComponents/pin";
import nigeria from "./assets/img/nigeria.svg";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";
import { MdDelete } from "react-icons/md";

import ekedc from "./assets/img/ekedc.jpg";
import ikedc from "./assets/img/ikedc.jpg";
import phed from "./assets/img/phed.jpg";
import kano from "./assets/img/kedc.jpg";
import kaduna from "./assets/img/kaedc.jpg";
import ibadan from "./assets/img/ibedc.png";
import jos from "./assets/img/jedc.jpg";

let typingTimer,
  doneTypingInterval = 2000;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      selected: "eko",
      meter_number: null,
      meter_type: "prepaid",
      phone_ok: false,
      amount: 0,
      amount_ok: false,
      phone_number: null,
      isSubmitting: false,
      isLoading: true,
      isVerifyingIuc: false,
      isEmpty: true,
      account: null,
      mtn_subs: [],
      glo_subs: [],
      airtel_subs: [],
      ninemobile_subs: [],
      smile_subs: [],
      plan_options: [],
      key_code: "",
      beneficiary: false,
      checked: false,
      beneficiaries: [],
      beneficiary_type: "electric",

      isVerifyingMeter: false,
    };
    this.setPhone = this.setPhone.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.setMeter = this.setMeter.bind(this);
  }

  handleChange = (e) => {
    this.setState({ checked: !this.state.checked }, () => {
    });
  };
  setMeter(e) {
    let meterNo = e.target.value.replace(/\s+/g, ""),
      self = this;

    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      //start loader
      self.setState({
        meter_number: meterNo,
        isVerifyingMeter: true,
        isEmpty: !(meterNo.length > 0),
      });

      //verify meter details
      axios
        .post(ConstantVar.API_BASE_URL + "/verify_meter", {
          provider: self.state.selected,
          meter_no: meterNo,
          meter_type: self.state.meter_type.toLowerCase(),
        })
        .then((response) => {
          //check response
          if (
            response.data.success === "true" &&
            response.data.data.cust_name !== undefined
          ) {
            //user found, load profile picture in picture-box
            let data = response.data.data;
            self.setState({
              isVerifyingMeter: false,
              account: data.cust_name + ", " + data.address,
            });
          } else {
            //notify user that account is invalid
            self.setState({
              isVerifyingMeter: false,
              account: "invalid",
            });
          }
        });
    }, doneTypingInterval);
  }

  deleteBeneficiary = (beneficiary) => {
    axios
      .post("/user/remove_beneficiary", {
        token: localStorage.getItem("token"),
        beneficiary_type: beneficiary.type,
        beneficiary_number: beneficiary.number,
        // pin: pin,
      })
      .then((response) => {
        console.log(response, "removeBeneficiary");
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    // get beneficiaries
    axios
      .post(ConstantVar.API_BASE_URL + "/user/beneficiaries", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        console.log(response, "dashboard beneficiaries");
        if (response.data.success === "true") {
          self.setState({
            beneficiaries: response.data.data.filter(
              (item) => item.type === "airtime"
            ),
            isLoading: false,
          });
        }
      });

    //get subscriptions
    let self = this;

  }

  setPhone(e) {
    let phoneNo = e.target.value.replace(/\s+/g, "");

    this.setState({ ...this.state, checked: false });

    let covered = this.state.beneficiaries.filter(
      (item) => item.number === phoneNo
    );
  
    this.setState(
      {
        phone_number: phoneNo,
        phone_ok: phoneNo.length === 11,
      },
      () => {
        if (covered[0]?.number === phoneNo) {
          this.setState({ checked: true });
        }
      }
    );
  }

  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      amount: amount,
      amount_ok: amount >= 500,
    });
  }
  render() {
    let electricityPayload = {
      phone_number: this.state.phone_number,
      amount: this.state.amount,
      provider: this.state.selected,
      meter_no: this.state.meter_number,
      meter_type: this.state.meter_type.toLowerCase(),
    };

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card-bill card">
                  <div className="card-body">
                    <h4
                      style={{ padding: 30 }}
                      className="text-center font-weight-bold"
                    >
                      Buy Electricity
                    </h4>
                    <div style={{ padding: 20 }}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              style={{
                                width: 50,
                                backgroundColor: "white",
                                borderColor: this.state.phoneFocus
                                  ? "#495057"
                                  : "#eee",
                              }}
                            >
                              <img
                                src={nigeria}
                                style={{ width: "100%", marginLeft: 8 }}
                              />
                            </div>
                          </div>
                          <input
                            required
                            onClick={() => {
                              this.setState({ phoneFocus: true });
                            }}
                            onBlur={() => {
                              this.setState({ phoneFocus: false });
                            }}
                            type="number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.setPhone}
                            style={{ borderLeft: 0 }}
                            className="form-control login-input phone-input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="081xxxxxxxx"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "kano" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "kano"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" src={kano} />
                          </div>
                        </div>
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "ibadan" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "ibadan"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" src={ibadan} />
                          </div>
                        </div>
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "kaduna" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "kaduna"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" src={kaduna} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "eko" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "eko" ? " selected" : "")
                            }
                          >
                            <img className="airtime-card" src={ekedc} />
                          </div>
                        </div>

                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "ikeja" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "ikeja"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" src={ikedc} />
                          </div>
                        </div>

                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "ph" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "ph" ? " selected" : "")
                            }
                          >
                            <img className="airtime-card" src={phed} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "jos" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "jos" ? " selected" : "")
                            }
                          >
                            <img className="airtime-card" src={jos} />
                          </div>
                        </div>

                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({ selected: "benin" });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "benin"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" alt="BEDC" />
                          </div>
                        </div>

                      </div>

                      <div className="form-group row">
                        <div className="col-6">
                          <label htmlFor="exampleInputEmail1" className="mt-4">
                            Meter type
                          </label>
                          <div
                            onChange={(e) =>
                              this.setState({ meter_type: e.target.value })
                            }
                          >
                            <select className="custom-select form-control form-control-pad">
                              <option selected value="prepaid">
                                Prepaid
                              </option>
                              <option value="postpaid">Post paid</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group col-6">
                          <label htmlFor="exampleInputEmail1" className="mt-4">
                            Meter number
                          </label>
                          <input
                            onChange={this.setMeter}
                            required
                            className="form-control login-input phone-input"
                            type="number"
                            placeholder="Enter meter number"
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginBottom: 15 }}></div>
                      <div className="form-group">
                        <div style={{ marginTop: 10 }}>
                          <div
                            style={{ marginTop: 0 }}
                            className={
                              "text-center " +
                              (this.state.isEmpty
                                ? "hide"
                                : this.state.isVerifyingMeter
                                ? "show"
                                : "hide")
                            }
                          >
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                          <div
                            style={{ marginTop: 0 }}
                            className={
                              this.state.isEmpty
                                ? "hide"
                                : this.state.isVerifyingMeter
                                ? "hide"
                                : "show"
                            }
                          >
                            <div
                              className="item"
                              style={{ marginTop: 0, marginBottom: 0 }}
                            >
                              <div className="text-center">
                                {(() => {
                                  if (this.state.account === "invalid") {
                                    return (
                                      <div
                                        className="alert alert-warning"
                                        style={{ width: "100%" }}
                                      >
                                        <p className="text-warning mb-0">
                                          <i className="fa fa-exclamation-circle" />
                                          &nbsp;Could not verify IUC number.
                                        </p>
                                      </div>
                                    );
                                  } else
                                    return (
                                      <div
                                        className="alert alert-success"
                                        style={{ width: "100%" }}
                                      >
                                        <p className="text-success mb-0">
                                          <i className="fa fa-check" />
                                          &nbsp;{this.state.account}
                                        </p>
                                      </div>
                                    );
                                })()}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-2">
                          <label htmlFor="exampleInputEmail1">
                            Amount you want to buy
                          </label>
                          <NumberFormat
                            required
                            allowNegative={false}
                            placeholder="Amount (minimum is 500)"
                            onChange={this.setAmount}
                            thousandSeparator={true}
                            className="form-control login-input phone-input"
                          />
                        </div>
                        <small>
                          A fee of NGN 100 will be deducted from your recharge
                        </small>
                      </div>
                      {!this.state.checked && (
                        <div>
                          <input
                            type="checkbox"
                            onChange={this.handleChange}
                            defaultChecked={this.state.checked}
                            // value={this.state.checked}
                            name="checked"
                            style={{ marginRight: 10 }}
                          />
                          <label>Save as beneficiary </label>
                        </div>
                      )}

                      <button
                        data-toggle="modal"
                        data-target="#pinModal"
                        disabled={
                          !(this.state.phone_ok && this.state.amount_ok &&  this.state.account !== null)
                        }
                        type="submit"
                        className="col-sm-12 pl-0 btn signup-btn text-white"
                      >
                        Buy power now
                      </button>
                    </div>
                  </div>
                  {/* <Pin endpoint="/user/pay_data" data={dataPayload} /> */}
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
        {this.state.beneficiaries.length > 0 ? (
          <div className="col-sm-8 container dashbord-con">
            <p className="card-inner-pad-tran-text text-muted">
              Saved Beneficiaries
            </p>
            <table className="table  card-bill ">
              <tbody>
                {this.state.beneficiaries.map((beneficiary, index) => (
                  <tr className="table-hover" key={index}>
                    <td
                      className="table-dash-img text-center"
                      style={{ width: "17%" }}
                    >
                      <div className="phone-bckg">
                        <i className="fas fa-phone phone-color" />
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 1 }}>
                        <p>{beneficiary.number}</p>
                        <br />
                        <p className="table-dash-details2">
                          {beneficiary.name}{" "}
                        </p>
                      </div>
                    </td>
                    <td>
                      <button
                        data-toggle="modal"
                        type="submit"
                        onClick={() => this.setState({ beneficiary: true })}
                        data-target="#pinModal"
                        style={{ color: "red", border: "none" }}
                      >
                        <MdDelete />
                      </button>

                      <Pin
                        endpoint={
                          !this.state.beneficiary
                            ? "/user/pay_electric"
                            : "/user/remove_beneficiary"
                        }
                        data={
                          this.state.beneficiary
                            ? {
                                beneficiary_type: beneficiary.type,
                                beneficiary_number: beneficiary.number,
                              }
                            : {
                              ...electricityPayload,
                              save_as_beneficiary: `${this.state.checked}`,
                              use_loan_wallet: "false",
                              }
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
