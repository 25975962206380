import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import LeftAngle from "../homeAssets/Path23.png";
import RightAngle from "../homeAssets/Path24.png";
import DotIcon from "../homeAssets/faqdot.png";
import FaqImg from "../homeAssets/FAQs.png";
import AppContext from "../AppContext";

class FAQs extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.changePlus = this.changePlus.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  changePlus() {
    document.getElementById("demo").innerHTML = "Hello World";
  }
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar />
        <section className="faqs-section">
          <div className="">
            <img className="faq-left-img" src={LeftAngle} alt="" />
            <img className="faq-right-img float-right" src={RightAngle} alt="" />
            <img className="faq-dots-icon" src={DotIcon} alt="" />
            <img className="faq-img" src={FaqImg} alt="" />
          </div>
        </section>
        <section className="">
          <div className="">
            <h2 className="para-text text-center">Answers to all of your questions</h2>
          </div>
          <div className="container">
            <div className="accordion" id="accordionExample">
              <div className="faq-card">
                <div className="" id="headingOne">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p className="mb-0">What is Hometown™</p>
                  </button>
                  <button
                    onclick="changePlus"
                    className="btn btn-link float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <i id="demo" class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Hometown™ is a sociopreneur application designed to offer credit and
                    loan facilities to Nigerians within the shortest possible time and
                    with a flexible repayment plan ranging from 15 days to six months and
                    with interest rates beginning from as low as 15%! The greater your
                    loan request, the lower your interest! We are not just a loan service
                    provider. Our other mobile app services allows you carry out banking
                    services all from your mobile phone. With the Hometown App, you can
                    send and receive money, pay for your essential services (DSTV, PHCN,
                    subscriptions, etc), recharge your phone, get a loan, invest your
                    spare funds, etc.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingTwo">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <p className="mb-0">About us</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    As a people and an organization and with our history of the challenges
                    the Nigerian populace have faced over the years due to the difficulty
                    in holistically addressing the disconnect in bridging the absence of
                    financial inclusion to a large number of Nigerians, specifically those
                    in the urban areas, we at Hometown realized that there laid an
                    opportunity. The vision was born. As young leaders, our Founders
                    admitted that despite the increase in GDP and the overall growth of
                    the Nigerian economy, more Nigerians found it difficult to access
                    credit facilities, particularly students, start-ups and even
                    successful existing entrepreneurs. This became the foundation upon
                    which Hometown was built.
                  </div>
                </div>
              </div>
              <div className="faq-card">
                <div className="" id="headingThree">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <p className="mb-0">Vision</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Our VISION is to provide a platform where every Nigerian irrespective
                    of their income level can have unencumbered access to credit
                    facilities to meet up with their immediate need while allowing them a
                    flexible repayment plan and period.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingFour">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <p className="mb-0">Mission</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Our MISSION is to continually redefine various means of sourcing for
                    affordable capital and mitigating the risk of repayment so as to
                    continually provide affordable credit facilities to Nigerians via our
                    platform at every point of their financial need.
                  </div>
                </div>
              </div>
              {/*
  <div className=" faq-card">
    <div className="" id="headingFive">
     
      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      <p className="mb-0">
      About it's founders?
      </p>
        </button>
      <button className="btn btn-link collapsed float-right" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        <i class="fas fa-plus"></i>
        </button>
    </div>
    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
      <div className="card-body faq-card-body">
      OBI PEDRO CHIBUZO, FOUNDER – COO/CEO <br/>
Hometown is founded by Obi Pedro Chibuzo, a Nigerian innovator and socio-political leader with passion for identifying needs while pulling together resources to meet such needs. 

Pedro is  an Educator, A Guidance and Counselor with vast experience in unionism, politics and administration. He is an associate professional member of the Institute of Science Laboratory Technology and International Dispute Resolution Institute.  He is a youth leader and has toured virtually most cities of Nigeria.

With experience in student unionism, trade negotiations, project management, project funding and peace resolution, Pedro positions himself as an astute administrator. Aside having interest in politics and local development issues, Pedro has gained numerous experiences working with top grade administrators, thereby gaining first-hand knowledge of complex administrative issues.

He is the founder of Hometown App and Chief Operations Officer/Managing Director of D’Revson Nigeria Limited. <br/>

TONY OSBORG, CO-FOUNDER – HEAD OF OPERATIONS <br/>
Tony Osborg is an experienced project and procurement administrator whose interest ranges from investigative journalism to politics, public procurement, tourism and community development. He is a strategist with experience in web development, procurement documentation (bidding), sociopolitical research, community relations, policy making and project management.

He is a co-founder of Hometown App and is the Head of Operations, Development and Market Penetration. <br/>

ONOMOR ESEOGHENE BENJAMIN – HEAD, DATA & RETAIL ANALYTICS <br/>
Benjamin is …

</div>
    </div>
  </div>
  */}
              <div className=" faq-card">
                <div className="" id="headingSix">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <p className="mb-0">Where is Hometown™ located?</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseSix"
                  className="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Hometown corporate head office is located in Port Harcourt City of
                    Rivers State with upcoming branches in Asaba, Warri, Onitsha, Lagos
                    and Abuja, all in Nigeria.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingSeven">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <p className="mb-0">In which countries does Hometown™ operate?</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseSeven"
                  className="collapse"
                  aria-labelledby="headingSeven"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    We are currently only available within the Federal Republic of
                    Nigeria.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingEight">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <p className="mb-0">
                      Can i call a Hometown™ representative or visit the office?
                    </p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseEight"
                  className="collapse"
                  aria-labelledby="headingEight"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Yes. Our customer representatives can be reached via
                    connect@gethometown.app, connect@hometownapp.co +234 90 56097944. You
                    can as well reach us using our app chat us feature for instant
                    response to whatever issues you might have using the app.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingNine">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    <p className="mb-0">
                      What are other customers saying about Hometown™?
                    </p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseNine"
                  className="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    We are a new innovation in the Nigerian industry; however, you can
                    find out what others are saying about us by visiting the review
                    sections on Google Play Store or on our website.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingTen">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    <p className="mb-0">Eligibility criteria?</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseTen"
                  className="collapse"
                  aria-labelledby="headingTen"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    To be eligible for a loan, the following minimum conditions must be
                    met;
                    <br />
                    1. Be a Nigerian <br />
                    2. Have a Nigerian Bank Verification Number (BVN) <br />
                    3. Have a Nigerian Phone number linked to your BVN <br />
                    4. Have a Nigerian Bank Account <br />
                    5. Have a Nigerian Debit Card <br />
                    6. Have any valid Nigerian means of Identification <br />
                    7. Have a smart phone with internet connectivity <br />
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingEleven">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    <p className="mb-0">Loan offers?</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseEleven"
                  className="collapse"
                  aria-labelledby="headingEleven"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Our loan offer ranges from a minimum of 15,000 Naira to a maximum of
                    2,000,000. To be eligible for a higher loan amount, simply pay your
                    outstanding loan on time and automatically unlock a greater amount.
                    Our algorithm allows a 100% higher credit once you repay an
                    outstanding loan for loans below 100k while a 50% higher access if
                    your existing loan is above 100k.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingTwelve">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    <p className="mb-0">Repayment?</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseTwelve"
                  className="collapse"
                  aria-labelledby="headingTwelve"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Repayments can be made via the APP through an auto debit or
                    installment payment system.
                  </div>
                </div>
              </div>
              <div className=" faq-card">
                <div className="" id="headingThirteen">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    <p className="mb-0">Late repayments</p>
                  </button>
                  <button
                    className="btn btn-link collapsed float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div
                  id="collapseThirteen"
                  className="collapse"
                  aria-labelledby="headingThirteen"
                  data-parent="#accordionExample"
                >
                  <div className="card-body faq-card-body">
                    Late repayments are not good for we both. It reduces our confidence in
                    your credit rating while reducing our investors’ confidence in our own
                    credit rating. This is exactly why we have offered a very flexible
                    repayment model. You do not have to pay your loan repayment amount in
                    full at a go to make a repayment, you can pay in piecemeal and just
                    ensure it is all repaid before the loan tenure expiration.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
FAQs.contextType = AppContext;
export default FAQs;
