import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Key from "../../homeAssets/key.png";
import { Link } from "react-router-dom";
import nigeria from "../../homeAssets/nigeria.svg";
import axios from "axios";
import ReactCodeInput from "react-code-input";
import AppContext from "../../AppContext";
import iziToast from "izitoast";
import * as ConstantVar from "../../constants_var";

class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      phoneFocus: false,
      pin: "",
    };

    this.loginUser = this.loginUser.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
  }

  handlePinChange(evt) {
    this.setState({ pin: evt });
  }

  loginUser(e) {
    e.preventDefault();

    let self = this,
      phone = e.target.phone.value;

    axios
      .post(ConstantVar.API_BASE_URL + "/user/authenticate", {
        phone: phone,
        pin: this.state.pin,
      })
      .then((response) => {
        if (response.data.success === "true") {
          //save token to storage
          localStorage.setItem("token", response.data.data);

          window.location.href = "/dashboard/overview";
        } else if (response.data.success === "pending_verification") {
          //extra authentication is required

          //save auth email in local storage
          localStorage.setItem("authUser", phone);

          document.getElementById("gotoVerify").click();

          //show message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        } else {
          //show message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  }

  componentDidMount() {
    document.getElementById("phone").focus();
    this.setState({ phoneFocus: true });
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar />
        <section className="login-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-3" />
              <div className=" col-sm-6">
                <div className="card all-card login-card">
                  <h2 className="text-center">Welcome Back!</h2>
                  <p className="text-center pb-4">
                    Take The First Step Towards Getting Your First Loan
                  </p>
                  <form onSubmit={this.loginUser}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Phone Number</label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              width: 50,
                              backgroundColor: "white",
                              borderColor: this.state.phoneFocus
                                ? "#495057"
                                : "#eee",
                            }}
                          >
                            <img
                              src={nigeria}
                              style={{ width: "100%", marginLeft: 8 }}
                            />
                          </div>
                        </div>
                        <input
                          id="phone"
                          required
                          onClick={() => {
                            this.setState({ phoneFocus: true });
                          }}
                          onBlur={() => {
                            this.setState({ phoneFocus: false });
                          }}
                          type="number"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.handleChange}
                          style={{ borderLeft: 0 }}
                          className="form-control login-input phone-input"
                          aria-describedby="emailHelp"
                          placeholder="081xxxxxxxx"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="col-sm-6 pl-0"
                        htmlFor="exampleInputPassword1"
                      >
                        Pin
                      </label>
                      <label
                        className="col-sm-6 text-right pr-0"
                        htmlFor="exampleInputPassword1"
                      >
                        <Link to="/account/recovery">Forgot pin?</Link>
                      </label>
                      <ReactCodeInput
                        type="password"
                        inputMode="number"
                        fields={4}
                        value={this.state.pin}
                        onChange={this.handlePinChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="col-sm-12 pl-0 btn signup-btn text-white"
                    >
                      Login to your account
                    </button>
                    <label
                      className="pr-0 pt-3"
                      htmlFor="exampleInputPassword1"
                    >
                      Not yet signed up?{" "}
                      <Link to="/signup/details">Join Now</Link>
                    </label>
                  </form>
                  <Link id="gotoVerify" to="/login/verify" type="hidden" />
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
Index.contextType = AppContext;
export default Index;
