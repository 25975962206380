import React from 'react'
import Main from './Signin/index'
import Verify from './Signin/verify'


class Signin extends React.Component{
  
    constructor(props){
        super(props);

    }
    render(){
        let stage = this.props.match.params.stage;

        if (stage === 'account')
            return(
                <Main/>
            );
        else if (stage === 'verify')
            return(
                <Verify/>
            )
    }
}
export default Signin