import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import Pin from "../../../Components/dashComponents/pin";
import Banks from "../../../Components/dashComponents/banks";
import person from "./assets/img/person.jpg";
import NumberFormat from "react-number-format";
import axios from "axios";
import AppContext from "../../../AppContext.js";
import ReactCodeInput from "react-code-input";
import moment from "moment";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      accounts: [],
      cards: [],
      account_name: "",
      account_number: "",
      bank: "",
      bank_name: "",
      isLoading: true,
      pin: "",
      general: {},
      bvn: "",
      dob: "",

      ninPage: "main-page",
      nin: "",
      ninErrorMsg: "",
      ninPhone: "",
    };
    this.showTimes = this.showTimes.bind(this);
    this.hideTimes = this.hideTimes.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
    this.updateData = this.updateData.bind(this);
  }
  updateData(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
    
    if (e.target.type === "select") e.target.value = e.target.selected;
  }

  handlePinChange(evt) {
    this.setState({ pin: evt });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  openError(message) {
    iziToast.error({
      title: message.charAt(0).toUpperCase() + message.slice(1),
      message: "",
      position: "topRight",
      theme: "light",
      color: "yellow",
    });
  }

  componentDidMount() {
    //bind this to self
    let self = this;

    setTimeout(() => {
      self.setState({
        user: self.context.user,
      });
      console.log("user:", this.state.user)
    }, 1000);
    
    axios
      .post(ConstantVar.API_BASE_URL + "/user/profile/general", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {

        if (response.data.success === "true") {
          self.setState({
            general: response.data.data,
            isLoading: false,
          });
        }
      });

    axios
      .post(ConstantVar.API_BASE_URL + "/user/profile/bvn", {
        token: localStorage.getItem("appToken"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            general: response.data.data,
            isLoading: false,
          });
          // console.log(response.data.data);
        }
      });

    this.setState({
      isLoading: false,
    });
  }

  showTimes(index) {
    document.getElementById("times" + index).classList.add("show-times");
    document
      .getElementById("times-color" + index)
      .classList.add("show-times-color");
  }

  hideTimes(index) {
    document.getElementById("times" + index).classList.remove("show-times");
    document
      .getElementById("times-color" + index)
      .classList.remove("show-times-color");
  }

  verifyNIN = (e) => {
    e.preventDefault();

    let self = this;
    this.setState({
      isLoading: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/user/verify/nin", {
        token: localStorage.getItem("token"),
        nin: e.target.nin.value,
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            ninPage: "verified",
          });
        } else if (response.data.success === "otp") {
          // show verify NIN phone message
          self.setState({
            ninPage: "verify-phone-message-page",
            ninErrorMsg: response.data.message,
            ninPhone: response.data.phone,
            isLoading: false,
          });
        } else {
          //show error message
          self.setState({
            isLoading: false,
          });

          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  };

  sendOTP = (e) => {
    e.preventDefault();

    let self = this;
    this.setState({
      isLoading: true,
    });

    axios
      .get(ConstantVar.API_BASE_URL + "/send/otp?phone=" + this.state.ninPhone)
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            ninPage: "verify-phone-otp",
          });
        }
      });
  };

  verifyNINPhone = (e) => {
    e.preventDefault();

    let self = this;
    self.setState({
      isLoading: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/verify/nin/otp", {
        token: localStorage.getItem("token"),
        nin: this.state.nin,
        phone: this.state.ninPhone,
        otp: e.target.otp.value,
      })
      .then((response) => {
        self.setState({
          isLoading: false,
        });
        if (response.data.success === "true") {
          self.setState({
            ninPage: "verified",
          });
        } else {
          //show error message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  };

  updateAcctNoData = (e) => {
    let acct_no = e.target.value.replace(/\*/g, "");
    this.setState(
      {
        [e.target.name]: acct_no,
      },
      () => {
        if (acct_no.length === 10 && this.state.bank) {
          this.resolveAccountNumber();
        }
      }
    );
  };

  resolveAccountNumber() {
    this.setState({
      isLoading: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/paystack_resolve_account_number", {
        bank_code: this.state.bank,
        account_number: this.state.account_number,
      })
      .then((response) => {
        this.setState({
          account_name:
            response.data.success === "true"
              ? response.data.data
              : response.data.message,
          isLoading: false,
        });
      })
      .catch((response) => {
        this.setState({
          account_name: response.data.message,
          isLoading: false,
        });
      });
  }

  handleBankChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        bank_name: window.$("#nigeriaBanksList option:selected").html(),
      },

      () => {
        if (this.state.account_number.length === 10) {
          this.resolveAccountNumber();
        }
      }
    );
  };

  verifyAcctNo = (e) => {
    e.preventDefault();
    let self = this;
    let bank = this.state.bank,
      account_number = this.state.account_number.replace(/\*/g, "");

    if (!bank) {
      this.openError("Please select the name of your bank");
      return;
    } else if (!account_number) {
      this.openError("Please provide your account number");
      return;
    } else if (account_number.length < 10) {
      this.openError("Account number can not less than 10 digits");
      return;
    } else if (
      this.state.account_name === undefined ||
      this.state.account_name.indexOf("Not") > -1 ||
      this.state.account_name.indexOf("found") > -1 ||
      this.state.account_name.indexOf("error") > -1 ||
      this.state.account_name.indexOf("record") > -1
    ) {
      this.openError(
        "Account number not found please re-enter your account number and check the bank name."
      );
      return;
    }
    this.setState({
      isLoading: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/user/verify/acct_number", {
        token: localStorage.getItem("token"),
        account_number: this.state.account_number,
        account_name: this.state.account_name,
        bank_name: this.state.bank_name,
      })
      .then((response) => {
        self.setState({
          isLoading: false,
        });
        if (response.data.success === "true") {
          let user = this.state.user;
          user.account_no_verified_at = "1";
          self.setState({
            user: user,
          });
        } else {
          //show error message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  };

  NINVerificationPages = () => {
    return (
      <div>
        {this.state.ninPage === "main-page" ? (
          <form onSubmit={this.verifyNIN} autoComplete="off">
            <h4> NIN Verification</h4>
            <address>
              Enter your NIN number below to verify your account.
            </address>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="formGroupNinInput"
                placeholder="Enter your NIN"
                name="nin"
                required
                onChange={this.handleChange}
                value={this.state.nin}
              />

              <div className="p-3">
                <button
                  disabled={this.state.isLoading}
                  type="submit"
                  className="btn btn-success float-right text-white"
                >
                  Verify
                </button>
              </div>
            </div>
          </form>
        ) : this.state.ninPage === "verify-phone-message-page" ? (
          <div>
            <h4 className="text-warning">Phone number does not match</h4>

            <address
              style={{
                fontSize: "22px",
              }}
            >
              {this.state.ninErrorMsg}
            </address>

            <div className="p-3">
              <button
                type="button"
                disabled={this.state.isLoading}
                onClick={this.sendOTP}
                className="btn btn-success float-right text-white"
              >
                Verify Phone
              </button>
            </div>
          </div>
        ) : this.state.ninPage === "verify-phone-otp" ? (
          <form onSubmit={this.verifyNINPhone} autoComplete="off">
            <h4>Verify Phone Number</h4>
            <address>
              Enter the OTP sent to *******
              {this.state.ninPhone.substr(this.state.ninPhone.length - 4)} to
              complete your verification.
            </address>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="formGroupNinInput"
                placeholder="Enter OTP"
                name="otp"
                onChange={this.handleChange}
                value={this.state.otp}
              />

              <div className="p-3">
                <button
                  disabled={this.state.isLoading}
                  type="submit"
                  className="btn btn-success float-right text-white"
                >
                  Confirm
                </button>
              </div>
            </div>
          </form>
        ) : this.state.ninPage === "verified" ? (
          <div className="p-5 text-center">
            <div className="fa fa-check fa-3x text-success"></div>
            <h3>NIN Verified</h3>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  render() {
    let profileDataPayload = {
      bvn: this.state.bvn,
      dob: this.state.dob,
      bank_code: this.state.bank,
      account_number: this.state.account_number,
      account_name: this.state.account_name,
      bank_name: this.state.bank_name,
    };

    let profileGeneralDataPayload = {
      marital_status: this.state.marital_status,
      dob: this.state.dob,
      level_of_education: this.state.level_of_education,
      monthly_income: this.state.monthly_income,
      employment_status: this.state.employment_status,
      employment_date: this.state.employment_date,
      type_of_residence: this.state.tor,
      state_of_residence: this.state.sor,
      nearest_bus_stop: this.state.nbs,
      local_gov_area: this.state.lga,
      house_address: this.state.house_address,
    };

    return (
      <div>
        <Navbar />
        <div>
          <div className="container dashbord-con">
            <div className="card card-main-akawo card-settings">
              <div className="row">
                <div className="col-sm-3">
                  <div className="">
                    <div
                      className="nav flex-column nav-pills"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <a
                        className="nav-link backgrd-color active card profile-nav"
                        id="personal-details-tab"
                        data-toggle="pill"
                        href="#personal-details"
                        role="tab"
                        aria-controls="personal-details"
                        aria-selected="true"
                      >
                        Personal details
                      </a>
                      <a
                        className="nav-link backgrd-color card profile-nav"
                        id="nin-verification-tab"
                        data-toggle="pill"
                        href="#nin-verification"
                        role="tab"
                        aria-controls="bank-details"
                        aria-selected="false"
                      >
                        NIN Verification
                      </a>
                      <a
                        className="nav-link backgrd-color card profile-nav"
                        id="acct-verification-tab"
                        data-toggle="pill"
                        href="#acct-verification"
                        role="tab"
                        aria-controls="bank-details"
                        aria-selected="false"
                      >
                        Account Number Verification
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-9">
                  <div className="tab-content" id="v-pills-home">
                    <div
                      className="tab-pane fade show active"
                      id="personal-details"
                      role="tabpanel"
                      aria-labelledby="personal-details-tab"
                    >
                      <div>
                        <div className="row img-settings">
                          <div className="text-center w-100">
                            <img
                              src={person}
                              className="rounded-circle"
                              width="130"
                              height="130"
                              alt=""
                            />
                            <span
                              className="text-center pro-image-upload"
                              data-toggle="tooltip"
                              data-placement="right"
                              title="Upload image"
                            >
                              <i className="fas fa-camera" />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: 40 }}>
                        <h4>General Details</h4>
                        <hr />
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                htmlFor="formGroupExampleInput"
                                className="profile-input-label text-muted"
                              >
                                First name
                              </label>
                              <input
                                onChange={this.updateData}
                                value={this.state.user.fname}
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="First name"
                                type="text"
                                name="fname"
                                readOnly
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="formGroupExampleInput2"
                                className="profile-input-label text-muted"
                              >
                                Marital status
                              </label>
                              {this.state.general.marital_status && (
                                <strong>
                                  {" "}
                                  :{" "}
                                  {this.state.general.marital_status.toUpperCase()}
                                </strong>
                              )}
                              <select
                                value={this.state.marital_status}
                                // defaultValue="selected"
                                className="custom-select form-control form-control-pad"
                                name="marital_status"
                                onChange={this.updateData}
                                id="inputGroupSelect01"
                              >
                                <option
                                  className="text-muted selected"
                                  value=""
                                >
                                  Select marital status
                                </option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                                <option value="widowed">Widowed</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="formGroupExampleInput"
                                className="profile-input-label text-muted"
                              >
                                Email
                              </label>
                              <input
                                value={this.state.user.email}
                                className="form-control"
                                id="formGroupExampleInput"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                htmlFor="formGroupExampleInput2"
                                className="profile-input-label text-muted"
                              >
                                Surname
                              </label>
                              <input
                                onChange={this.updateData}
                                value={this.state.user.lname}
                                className="form-control"
                                id="formGroupExampleInput2"
                                placeholder="Surname"
                                type="text"
                                name="lname"
                                readOnly
                                
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="formGroupExampleInput"
                                className="profile-input-label text-muted"
                              >
                                Date of birth
                              </label>
                              <NumberFormat
                                onChange={this.updateData}
                                format="##/##/####"
                                mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Date of birth"
                                name="dob"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="formGroupExampleInput"
                                className="profile-input-label text-muted"
                              >
                                Phone number
                              </label>
                              <input
                                value={this.state.user.phone}
                                readOnly
                                prefix={""}
                                className="form-control"
                                id="formGroupExampleInput"
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 40 }}>
                          <h4>Education and employment</h4>
                          <hr />
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput"
                                  className="profile-input-label text-muted"
                                >
                                  Level of education
                                </label>
                                {this.state.general.level_of_education && (
                                  <strong>
                                    {" "}
                                    :{" "}
                                    {this.state.general.level_of_education.toUpperCase()}
                                  </strong>
                                )}

                                <select
                                  onChange={this.updateData}
                                  // value={this.state.general.level_of_education}
                                  className="custom-select form-control form-control-pad "
                                  defaultValue="selected"
                                  name="level_of_education"
                                  id="inputGroupSelect01"
                                >
                                  <option
                                    className="text-muted selected"
                                    value=""
                                  >
                                    Select education level
                                  </option>
                                  <option value="ssce">SSCE</option>
                                  <option value="undergraduate">
                                    Undergraduate
                                  </option>
                                  <option value="degree">Degree</option>
                                  <option value="masters">Masters</option>
                                  <option value="phd">Phd</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput2"
                                  className="profile-input-label text-muted"
                                >
                                  Employment status
                                </label>
                                {this.state.general.level_of_education && (
                                  <strong>
                                    {" "}
                                    :{" "}
                                    {this.state.general.employment_status.toUpperCase()}
                                  </strong>
                                )}

                                <select
                                  onChange={this.updateData}
                                  // value={this.state.general.employment_status}
                                  // defaultValue="selected"
                                  className="custom-select form-control form-control-pad"
                                  name="employment_status"
                                  id="inputGroupSelect01"
                                >
                                  <option
                                    className="text-muted selected"
                                    value=""
                                    defaultValue
                                  >
                                    Select employment status
                                  </option>
                                  <option value="self_employed">
                                    Self employed
                                  </option>
                                  <option value="employed">Employed</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput2"
                                  className="profile-input-label text-muted"
                                >
                                  Monthly income
                                </label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  prefix={"NGN "}
                                  value={this.state.general.monthly_income}
                                  className="form-control"
                                  id="formGroupExampleInput2"
                                  placeholder="Enter monthly income"
                                  name="monthly_income"
                                  onChange={this.updateData}
                                />
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput"
                                  className="profile-input-label text-muted"
                                >
                                  Employment date
                                </label>
                                {this.state.general.employment_date && (
                                  <strong>
                                    {" "}
                                    :{" "}
                                    {moment(
                                      this.state.general.employment_date
                                    ).format("LL")}
                                  </strong>
                                )}

                                <NumberFormat
                                  onChange={this.updateData}
                                  value={this.state.general.employment_date}
                                  format="##/##/####"
                                  mask={[
                                    "D",
                                    "D",
                                    "M",
                                    "M",
                                    "Y",
                                    "Y",
                                    "Y",
                                    "Y",
                                  ]}
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Enter employment date"
                                  name="employment_date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 40 }}>
                          <h4>Contact details</h4>
                          <hr />
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput"
                                  className="profile-input-label text-muted"
                                >
                                  Type of residence
                                </label>
                                {this.state.general.type_of_residence && (
                                  <strong>
                                    {" "}
                                    :{" "}
                                    {this.state.general.type_of_residence.toUpperCase()}
                                  </strong>
                                )}
                                <select
                                  onChange={this.updateData}
                                  // value={this.state.general.type_of_residence}
                                  className="custom-select form-control form-control-pad"
                                  name="tor"
                                  id="inputGroupSelect01"
                                >
                                  <option className="text-muted" value="">
                                    Select option
                                  </option>
                                  <option value="one_room">
                                    One room (rented)
                                  </option>
                                  <option value="self_contain">
                                    Self contain (rented)
                                  </option>
                                  <option value="1flat">
                                    1 bedroom flat (rented)
                                  </option>
                                  <option value="2flat">
                                    2 bedroom flat (rented)
                                  </option>
                                  <option value="3flat">
                                    3 bedroom flat (rented)
                                  </option>
                                  <option value="m_3flat">
                                    More than 3 bedroom flat (rented)
                                  </option>
                                  <option value="duplex">
                                    Duplex (rented)
                                  </option>
                                  <option value="own_building">
                                    Own building
                                  </option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput"
                                  className="profile-input-label text-muted"
                                >
                                  Local government area{" "}
                                </label>
                                <input
                                  onChange={this.updateData}
                                  value={this.state.general.local_govt_area}
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  placeholder="Enter local government area"
                                  name="lga"
                                />
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput2"
                                  className="profile-input-label text-muted"
                                >
                                  Nearest bus stop
                                </label>
                                <input
                                  onChange={this.updateData}
                                  value={this.state.general.nearest_bus_stop}
                                  className="form-control"
                                  id="formGroupExampleInput2"
                                  placeholder="Enter nearest bus stop"
                                  type="text"
                                  name="nbs"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput2"
                                  className="profile-input-label text-muted"
                                >
                                  State of residence
                                </label>
                                {this.state.general.state_of_residence && (
                                  <strong>
                                    {" "}
                                    :{" "}
                                    {this.state.general.state_of_residence.toUpperCase()}
                                  </strong>
                                )}
                                <select
                                  onChange={this.updateData}
                                  // value={this.state.general.state_of_residence}
                                  // defaultValue="selected"
                                  className="custom-select form-control form-control-pad"
                                  name="sor"
                                  id="inputGroupSelect01"
                                >
                                  <option
                                    className="text-muted"
                                    // value="selected"
                                    value=""
                                  >
                                    Select state
                                  </option>
                                  <option value="abia">Abia</option>
                                  <option value="adamawa">Adamawa</option>
                                  <option value="akwaibom">Akwa Ibom</option>
                                  <option value="bauchi">Bauchi</option>
                                  <option value="bayelsa">Bayelsa</option>
                                  <option value="benue">Benue</option>
                                  <option value="borno">Borno</option>
                                  <option value="crossriver">
                                    Cross River
                                  </option>
                                  <option value="delta">Delta</option>
                                  <option value="ebonyi">Ebonyi</option>
                                  <option value="edo">Edo</option>
                                  <option value="ekiti">Ekiti</option>
                                  <option value="Enugu">Enugu</option>
                                  <option value="gombe">Gombe</option>
                                  <option value="imo">Imo</option>
                                  <option value="jigawa">Jigawa</option>
                                  <option value="kano">Kano</option>
                                  <option value="katsina">Katsina</option>
                                  <option value="kebbi">Kebbi</option>
                                  <option value="kogi">Kogi</option>
                                  <option value="kwara">Kwara</option>
                                  <option value="lagos">Lagos</option>
                                  <option value="nasarawa">Nasarawa</option>
                                  <option value="niger">Niger</option>
                                  <option value="ogun">Ogun</option>
                                  <option value="ondo">Ondo</option>
                                  <option value="osun">Osun</option>
                                  <option value="oyo">Oyo</option>
                                  <option value="plateau">Plateau</option>
                                  <option value="rivers">Rivers</option>
                                  <option value="sokoto">Sokoto</option>
                                  <option value="taraba">Taraba</option>
                                  <option value="yobe">Yobe</option>
                                  <option value="zamfara">Zamfara</option>
                                  <option value="abuja">FCT</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="formGroupExampleInput2"
                                  className="profile-input-label text-muted"
                                >
                                  House address
                                </label>
                                <input
                                  onChange={this.updateData}
                                  value={this.state.general.house_address}
                                  className="form-control"
                                  id="formGroupExampleInput2"
                                  placeholder="Enter address"
                                  type="text"
                                  name="house_address"
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              data-toggle="modal"
                              data-target="#pinModal"
                              type="submit"
                              className="btn btn-success float-right text-white"
                            >
                              Update
                            </button>
                            <Pin
                              endpoint="/user/update/general"
                              data={profileGeneralDataPayload}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="nin-verification"
                      role="tabpanel"
                      aria-labelledby="add-account-tab"
                    >
                      {!this.state.user.nin_verified_at &&
                      !this.state.user.bvn_verified_at ? (
                        this.NINVerificationPages()
                      ) : (
                        <div className="p-5 text-center">
                          <div className="fa fa-check fa-3x text-success"></div>
                          <h3>NIN Verified</h3>
                        </div>
                      )}
                    </div>

                    <div
                      className="tab-pane fade"
                      id="acct-verification"
                      role="tabpanel"
                      aria-labelledby="add-account-tab"
                    >
                      {!this.state.user.account_no_verified_at ? (
                        <form onSubmit={this.verifyAcctNo} autoComplete="off">
                          <h4> Account Number Verification</h4>
                          <address>Add your Bank account number</address>
                          <div className="form-group">
                            <label
                              htmlFor="formGroupExampleInput"
                              className="profile-input-label text-muted"
                            >
                              Bank name
                            </label>
                            <Banks handleChange={this.handleBankChange} />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="formGroupExampleInput"
                              className="profile-input-label text-muted"
                            >
                              Account Number
                            </label>
                            <NumberFormat
                              format="##########"
                              mask={[
                                "*",
                                "*",
                                "*",
                                "*",
                                "*",
                                "*",
                                "*",
                                "*",
                                "*",
                                "*",
                              ]}
                              className="form-control"
                              id="formGroupExampleInput"
                              placeholder="Enter your Account Number"
                              name="account_number"
                              onChange={this.updateAcctNoData}
                              value={this.state.account_number}
                            />
                            <label>
                              {!this.state.account_name ? (
                                <small>
                                  Please make sure the account number belongs to
                                  you
                                </small>
                              ) : (
                                <small>{this.state.account_name}</small>
                              )}
                            </label>
                          </div>

                          <div className="p-3">
                            <button
                              disabled={this.state.isLoading}
                              type="submit"
                              className="btn btn-success float-right text-white"
                            >
                              Verify
                            </button>
                          </div>
                        </form>
                      ) : (
                        <div className="p-5 text-center">
                          <div className="fa fa-check fa-3x text-success"></div>
                          <h3>Account Number Verified</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="addBank"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-m-pad" role="document">
              <div className="modal-content">
                <div className="modal-header bckgrd-color">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add bank account
                  </h5>
                  <button
                    type="button"
                    className="close akawo-modal-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body bckgrd-color">
                  <div className="card akawo-card">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label
                              htmlFor="formGroupExampleInput"
                              className="profile-input-label text-muted"
                            >
                              Bank name
                            </label>
                            <Banks handleChange={this.handleChange} />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="formGroupExampleInput2"
                              className="profile-input-label text-muted"
                            >
                              Account name
                            </label>
                            <input
                              type="text"
                              name="account_name"
                              className="form-control"
                              id="formGroupExampleInput2"
                              placeholder="Account name"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="formGroupExampleInput"
                              className="profile-input-label text-muted"
                            >
                              Account number
                            </label>
                            <input
                              required
                              type="number"
                              name="account_number"
                              className="form-control"
                              id="formGroupExampleInput"
                              placeholder="Account number"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="formGroupExampleInput"
                              className="profile-input-label text-muted"
                            >
                              Your pin
                            </label>
                            <ReactCodeInput
                              type="text"
                              inputMode="number"
                              fields={4}
                              value={this.state.pin}
                              onChange={this.handlePinChange}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-success text-white float-right"
                      >
                        Click to add
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.contextType = AppContext;
export default Profile;
