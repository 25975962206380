import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import ActionBar from "../../../Components/dashComponents/action_bar";
import Pin from "../../../Components/dashComponents/pin";
import nigeria from "./assets/img/nigeria.svg";
import mtn from "./assets/img/mtn.jpg";
import glo from "./assets/img/glo.jpg";
import airtel from "./assets/img/airtel.jpg";
import nineMobile from "./assets/img/9mobile.jpg";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";
// import { useState } from "react";
import { MdDelete, MdNoEncryption } from "react-icons/md";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      selected: "null",
      phone_number: "",
      phone_ok: false,
      isLoading: true,
      amount: 0,
      amount_ok: false,
      beneficiary: false,
      checked: false,
      beneficiaries: [],
      beneficiary_type: "airtime",
    };

    this.setPhone = this.setPhone.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.loadAirtime = this.loadAirtime.bind(this);
  }

  handleChange = (e) => {
    this.setState({ checked: !this.state.checked }, () => {
      console.log(this.state.checked, "this.state.checked");
    });
  };

  deletePost = (beneficiary) => {
    axios
      .post("/user/remove_beneficiary", {
        token: localStorage.getItem("token"),
        beneficiary_type: beneficiary.type,
        beneficiary_number: beneficiary.number,
        // pin: pin,
      })
      .then((response) => {
        console.log(response, "removeBeneficiary");
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    let self = this;

    axios
      .post(ConstantVar.API_BASE_URL + "/user/beneficiaries", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        if (response.data.success === "true") {
          // console.log(response.data.data, "dashboard beneficiaries");
          self.setState({
            beneficiaries: response.data.data.filter(
              (item) => item.type === "airtime"
            ),
            isLoading: false,
          });
        }
        //  console.log([...this.state.beneficiaries].type, "6t");
      });
  }

  loadAirtime(e) {
    e.preventDefault();

    //on loader
    this.setState({
      isSubmitting: true,
    });

    //confirm 2FA
    axios
      .post(ConstantVar.API_BASE_URL + "/user/load_airtime", {
        token: localStorage.getItem("token"),
        phone_number: this.state.phone_number,
        amount: this.state.amount,
      })
      .then((response) => {
        //off loader
        this.setState({
          isSubmitting: false,
        });

        //check response
        if (response.data.success === "true") {
          //airtime recharge was successful
          console.log(response, "load airtime");
          iziToast.success({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "green",
          });

          setTimeout(function () {
            //redirect to wallet
            window.location = "/wallet";
          }, 1000);
        } else {
          //something went wrong, notify user
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  }

  setPhone(e) {
    let phoneNo = e.target.value.replace(/\s+/g, "");

    console.log("got called");
    this.setState({ ...this.state, checked: false });
    //get the first 4 characters to determine what network
    let first4 = phoneNo.substring(0, 4);

    let mtn = [
        "0703",
        "0704",
        "0706",
        "0803",
        "0806",
        "0810",
        "0813",
        "0814",
        "0816",
        "0903",
        "0906",
        "0913",
        "0916",
        "07025",
        "07026",
      ],
      glo = ["0705", "0805", "0807", "0811", "0815", "0905", "0915"],
      airtel = [
        "0701",
        "0708",
        "0802",
        "0808",
        "0812",
        "0901",
        "0902",
        "0904",
        "0907",
      ],
      smile = ["0702"],
      nineMob = ["0809", "0817", "0818", "0909", "0908"];

    if (mtn.includes(first4))
      this.setState({
        selected: "mtn",
      });
    else if (glo.includes(first4))
      this.setState({
        selected: "glo",
      });
    else if (airtel.includes(first4))
      this.setState({
        selected: "airtel",
      });
    else if (nineMob.includes(first4))
      this.setState({
        selected: "9mobile",
      });
    else this.setState({ selected: "null" });
    let covered = this.state.beneficiaries.filter(
      (item) => item.number === phoneNo
    );
    // console.log('phoneNo:',typeof(phoneNo),'covered:',covered[0])

    this.setState(
      {
        phone_number: phoneNo,
        phone_ok: phoneNo.length === 11,
      },
      () => {
        if (covered[0]?.number === phoneNo) {
          console.log("covered");
          this.setState({ checked: true });
        }
      }
    );
  }
  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      amount: amount,
      amount_ok: amount >= 50,
    });
  }

  render() {
    let airtimePayload = {
      // phone_number: this.state.phone_number,
      // amount: this.state.amount,
    };

    const beneficiary_type = this.state.beneficiaries.type;
    const beneficiary_number = this.state.beneficiaries.number;

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card-bill card">
                  <div className="card-body">
                    <h4
                      style={{ padding: 30 }}
                      className="text-center font-weight-bold"
                    >
                      Buy Airtime
                    </h4>
                    <div style={{ padding: 20 }}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              style={{
                                width: 50,
                                backgroundColor: "white",
                                borderColor: this.state.phoneFocus
                                  ? "#495057"
                                  : "#eee",
                              }}
                            >
                              <img
                                src={nigeria}
                                style={{ width: "100%", marginLeft: 8 }}
                              />
                            </div>
                          </div>
                          <input
                            required
                            onClick={() => {
                              this.setState({ phoneFocus: true });
                            }}
                            onBlur={() => {
                              this.setState({ phoneFocus: false });
                            }}
                            type="number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.setPhone}
                            style={{ borderLeft: 0 }}
                            className="form-control login-input phone-input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="081xxxxxxxx"
                          />
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: 15 }}>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "mtn" ? "selected" : ""
                            }
                          >
                            <img className="airtime-card" src={mtn} />
                          </div>
                        </div>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "glo" ? "selected" : ""
                            }
                          >
                            <img className="airtime-card" src={glo} />
                          </div>
                        </div>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "airtel" ? "selected" : ""
                            }
                          >
                            <img className="airtime-card" src={airtel} />
                          </div>
                        </div>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "9mobile"
                                ? "selected"
                                : ""
                            }
                          >
                            <img className="airtime-card" src={nineMobile} />
                          </div>
                        </div>
                      </div>
                      <div style={{ marginBottom: 20 }}>
                        <label>Amount you want to recharge</label>
                        <NumberFormat
                          allowNegative={false}
                          placeholder="Amount (minimum is 50)"
                          onChange={this.setAmount}
                          thousandSeparator={true}
                          className="form-control"
                        />
                        <NumberFormat
                          style={{
                            borderBottom: "1px dashed #eee",
                            display: "inline-block",
                            marginTop: 10,
                          }}
                          value={truncate(this.context.user.available_bal, 2)}
                          className="text-blue"
                          displayType={"text"}
                          prefix={"Balance: NGN "}
                          decimalScale={2}
                          thousandSeparator={true}
                        />
                      </div>
                      {!this.state.checked && (
                        <div>
                          <input
                            type="checkbox"
                            // onChange={this.handleChange}
                            defaultChecked={this.state.checked}
                            // value={this.state.checked}
                            style={{ marginRight: 10 }}
                          />
                          <label>Save as beneficiary </label>
                        </div>
                      )}

                      <button
                        data-toggle="modal"
                        data-target="#pinModal"
                        type="submit"
                        disabled={
                          !(
                            this.state.phone_ok &&
                            this.state.amount_ok &&
                            this.state.selected !== "null"
                          )
                        }
                        className="col-sm-12 pl-0 btn signup-btn text-white"
                      >
                        Recharge now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
        {this.state.beneficiaries.length > 0 ? (
          <div className="col-sm-8 container dashbord-con">
            <p className="card-inner-pad-tran-text text-muted">
              Saved Beneficiaries
            </p>
            <table className="table  card-bill ">
              <tbody>
                {this.state.beneficiaries.map(
                  (beneficiary, index) => (
                    // beneficiary.type == "airtime" ? (
                    <tr className="table-hover" key={index}>
                      <td
                        className="table-dash-img text-center"
                        style={{ width: "17%" }}
                      >
                        <div className="phone-bckg">
                          <i className="fas fa-phone phone-color" />
                        </div>
                      </td>
                      <td>
                        <div style={{ marginTop: 1 }}>
                          <p>{beneficiary.number}</p>
                          <br />
                          <p className="table-dash-details2">
                            {beneficiary.name}{" "}
                          </p>
                        </div>
                      </td>
                      <td>
                        <button
                          data-toggle="modal"
                          type="submit"
                          onClick={() => this.setState({ beneficiary: true })}
                          data-target="#pinModal"
                          style={{ color: "red", border: "none" }}
                        >
                          <MdDelete />
                        </button>

                        <Pin
                          endpoint={
                            !this.state.beneficiary
                              ? "/user/load_airtime"
                              : "/user/remove_beneficiary"
                          }
                          data={
                            this.state.beneficiary
                              ? {
                                  beneficiary_type: beneficiary.type,
                                  beneficiary_number: beneficiary.number,
                                }
                              : {
                                  phone_number: this.state.phone_number,
                                  amount: this.state.amount,
                                  save_as_beneficiary: `${this.state.checked}`,
                                  use_loan_wallet: "false",
                                }
                          }
                        />
                      </td>
                    </tr>
                  )
                  // ) : null
                )}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
