import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './assets/index.css';
import imgInvest from './assets/invest.png'
import Navbar from '../../../Components/dashComponents/navbar';
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import Pin from "../../../Components/dashComponents/pin";
import AppContext from '../../../AppContext'
import person from "../Akawo/assets/img/person.jpg";
import NumberFormat from "react-number-format";
import axios from "axios";

class Invest extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: {},
            isCalculated: false,
            is_amount_ok: false,
            amount: 0
        };

        this.setAmount = this.setAmount.bind(this);
    }

    setAmount(e){

        let amount = e.target.value.toString(), self = this;

        //strip amount of the commas and all
        if (amount !== ''){
            amount = parseFloat(amount.replace(/,/g, ''));
        }

        this.setState({
            amount: amount,
            is_amount_ok: (amount >= 1000),
        });

        if (amount >= 1000){

            //
            setTimeout(() => {

                self.setState({
                    isCalculated: true
                })
            }, 2000)
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    showPayment(){
        document.getElementById("next").classList.add("nodisplay");
        document.getElementById("paymetOption").classList.remove("nodisplay");
    }


    render() {

        let akawoPayload = {
            amount: this.state.amount
        };

        return (
            <div className="bckgrd-color">
                <div>
                    <Navbar />
                    <div className="container">
                        <VerificationBar email_verified_at={this.context.user.email_verified_at}/>
                    </div>
                </div>
                <div className="bckgrd-color">
                    <div className="container dashbord-con">
                        {/*<ActionBar/>*/}
                        <div className="row">
                            <div className="col-sm-3"/>
                            <div className="col-sm-6">
                                <div className="card card-trans">
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <h4>Invest Funds</h4>
                                            <p>
                                                <img style={{height:100}} src={imgInvest}/>
                                            </p>
                                        </div>
                                        <div className="card akawo-card">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-3 col-sm-2">
                                                        <img src={person} className="rounded-circle" width="45" height="45" alt="" />
                                                    </div>
                                                    <div className="col-6 col-sm-6 akawo-wal-pad">
                                                        <p className="text-muted">Wallet Balance</p>
                                                        <h5 className="amt-akawo font-weight-bold">
                                                            <NumberFormat
                                                                style={{fontSize:16,marginTop:10}}
                                                                value={truncate(this.context.user.available_bal, 2)}
                                                                className="card-bal-your"
                                                                displayType={'text'}
                                                                prefix={'NGN '}
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                            />
                                                        </h5>
                                                    </div>
                                                    <div className="col-3 col-sm-4">
                                                        <Link to="/dashboard/deposit/amount" className="btn btn-success btn-top-up mobile-hide">Top up</Link>
                                                    </div>
                                                </div>
                                                <button className="btn btn-success btn-top-up-m desktop-hide">Top up</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="akawo-amount-range-div" style={{paddingBottom:10}}>
                                        <p className="text-muted amt-style">Amount you want to invest</p>
                                        <NumberFormat
                                            onChange={this.setAmount}
                                            thousandSeparator={true}
                                            className="form-control"
                                            placeholder="Enter amount, minimum is 1,000 Naira"
                                        />
                                    </div>
                                    {(() => {
                                        if (this.state.is_amount_ok)
                                            if (this.state.isCalculated)
                                                return (
                                                    <div className="m-3" style={{border:'1px dashed orange'}}>
                                                        <div className="card akawo-card">
                                                            <p className="text-center akawo-table text-muted">
                                                                Investing
                                                                <NumberFormat
                                                                    style={{color:'orange'}}
                                                                    value={truncate(this.state.amount, 2)}
                                                                    className="card-bal-your"
                                                                    displayType={'text'}
                                                                    prefix={' NGN '}
                                                                    suffix={' '}
                                                                    decimalScale={0}
                                                                    thousandSeparator={true}
                                                                />
                                                                at 10% interest yields
                                                            </p>
                                                            <hr />
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-1 col-sm-2">
                                                                    </div>
                                                                    <div className="col-4 col-sm-3">
                                                                        <p className="text-muted">Daily</p>
                                                                    </div>
                                                                    <div className="col-1 col-sm-1">
                                                                    </div>
                                                                    <div className="col-5 col-sm-6">
                                                                        <p className="text-muted">
                                                                            <NumberFormat
                                                                                style={{color:'orange'}}
                                                                                value={truncate(((((this.state.amount*10)/100)*1)/365), 2)}
                                                                                className="card-bal-your"
                                                                                displayType={'text'}
                                                                                prefix={'NGN '}
                                                                                decimalScale={1}
                                                                                thousandSeparator={true}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-1 col-sm-2">
                                                                    </div>
                                                                    <div className="col-4 col-sm-3">
                                                                        <p className="text-muted">Weekly</p>
                                                                    </div>
                                                                    <div className="col-1 col-sm-1">
                                                                    </div>
                                                                    <div className="col-5 col-sm-6">
                                                                        <p className="text-muted">
                                                                            <NumberFormat
                                                                                style={{color:'orange'}}
                                                                                value={truncate(((((this.state.amount*10)/100)*7)/365), 2)}
                                                                                className="card-bal-your"
                                                                                displayType={'text'}
                                                                                prefix={'NGN '}
                                                                                decimalScale={1}
                                                                                thousandSeparator={true}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-1 col-sm-2">
                                                                    </div>
                                                                    <div className="col-4 col-sm-3">
                                                                        <p className="text-muted">Monthly</p>
                                                                    </div>
                                                                    <div className="col-1 col-sm-1">
                                                                    </div>
                                                                    <div className="col-5 col-sm-6">
                                                                        <p className="text-muted">
                                                                            <NumberFormat
                                                                                style={{color:'orange'}}
                                                                                value={truncate(((((this.state.amount*10)/100)*31)/365), 2)}
                                                                                className="card-bal-your"
                                                                                displayType={'text'}
                                                                                prefix={'NGN '}
                                                                                decimalScale={1}
                                                                                thousandSeparator={true}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-1 col-sm-2">
                                                                    </div>
                                                                    <div className="col-4 col-sm-3">
                                                                        <p className="text-muted">Yearly</p>
                                                                    </div>
                                                                    <div className="col-1 col-sm-1">
                                                                    </div>
                                                                    <div className="col-5 col-sm-6">
                                                                        <p className="text-muted">
                                                                            <NumberFormat
                                                                                style={{color:'orange'}}
                                                                                value={truncate(((((this.state.amount*10)/100)*365)/365), 2)}
                                                                                className="card-bal-your"
                                                                                displayType={'text'}
                                                                                prefix={'NGN '}
                                                                                decimalScale={1}
                                                                                thousandSeparator={true}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            else
                                                return(
                                                    <div style={{display:'block',margin:'0 auto'}} className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                );
                                        else return null
                                    })()}
                                    <div style={{padding:15}}>
                                        <button
                                            disabled={!(this.state.is_amount_ok)}
                                            type="button"
                                            className="col-sm-12 pl-0 btn signup-btn text-white"
                                            onClick={this.showPayment} id="next">
                                            Continue
                                        </button>
                                    </div>
                                    <div className="p-4 nodisplay" id="paymetOption">
                                        <div className="text-center">
                                            <p className="text-muted font-weight-bold" style={{fontSize: "1.03rem"}}>Select payment method</p>
                                        </div>
                                        <div className="card akawo-card akawo-card-wallet-pay">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-3 col-sm-2">
                                                        <div className="akawo-wallet-bckgrd">
                                                            <i className="fas fa-wallet akawo-wallet-icon"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-9 col-sm-10">
                                                        <p
                                                            data-toggle="modal"
                                                            data-target="#pinModal"
                                                            className="mb-0" style={{lineHeight:'2rem', cursor:'pointer'}}>
                                                            <span className="text-muted m-payment-text">Pay with wallet</span>
                                                            <span className="font-weight-bold float-right text-dark">
                                                                <NumberFormat
                                                                    value={truncate(this.state.amount, 2)}
                                                                    className="card-bal-your"
                                                                    displayType={'text'}
                                                                    prefix={'NGN '}
                                                                    decimalScale={0}
                                                                    thousandSeparator={true}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <Pin endpoint="/user/fund_akawo" data={akawoPayload}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card akawo-card akawo-card-card-pay" style={{marginTop: "2%"}}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-3 col-sm-2">
                                                        <div className="akawo-card-bckgrd">
                                                            <i className="fas fa-credit-card akawo-card-icon"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-9 col-sm-10">
                                                        <Link to="/dashboard/deposit/amount" className="text-decoration-none">
                                                            <p className="mb-0" style={{lineHeight:'2rem', cursor:'pointer'}}>
                                                                <span className="text-muted m-payment-text">Pay with debit card</span>
                                                                <span className="font-weight-bold float-right text-dark">
                                                                    <NumberFormat
                                                                        value={truncate(this.state.amount, 2)}
                                                                        className="card-bal-your"
                                                                        displayType={'text'}
                                                                        prefix={'NGN '}
                                                                        decimalScale={0}
                                                                        thousandSeparator={true}
                                                                    />
                                                                </span>
                                                            </p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Invest.contextType = AppContext;
export default Invest;

function truncate (num, places) {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}