import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import NumberFormat from "react-number-format";
import axios from "axios";
import moment from "moment";
import akawoimg from "./assets/img/akawo.png";
import ReactLoading from "react-loading";
import person from "../Akawo/assets/img/person.jpg";
import Banks from "../../../Components/dashComponents/banks";
import ReactCodeInput from "react-code-input";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";

class Akawo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      akawo: {},
      akawohis: [],
      isLoading: true,
      isWithdrawing: true,
      amount: "",
      pin: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/user/akawos", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === "true") {
          self.setState({
            akawo: response.data.data,
            isLoading: false,
            akawohis: response.data.data.history,
          });
        }
      });
  }
  handlePinChange(evt) {
    this.setState({ pin: evt });
  }
  handleChange(event) {
    let amount = event.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({ [event.target.name]: amount });
  }
  handleSubmit(event) {
    event.preventDefault();

    let amount = this.state.amount,
      pin = this.state.pin;

    this.setState({
      isWithdrawing: false,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/user/withdraw_akawo", {
        token: localStorage.getItem("token"),
        amount: amount,
        pin: pin,
      })
      .then((response) => {
        if (response.data.success === "true") {
          //success
          iziToast.success({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "green",
          });

          setTimeout(() => {
            //go to dashboard
            window.location.href = "/dashboard/overview";
          }, 1000);
        } else {
          this.setState({
            isWithdrawing: true,
          });

          //show error message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });
  }

  render() {
    let akawo = this.state.akawo;

    return (
      <div>
        <Navbar />
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-main-akawo">
                  <div
                    className="card-inner-pad"
                    style={{ backgroundColor: "rgb(43, 26, 100)" }}
                  >
                    <div className="p-5">
                      <p className="text-center text-muted">
                        <span className="wallet-status font-weight-bold">
                          AKAWO BALANCE
                        </span>
                      </p>
                      <h3 className="text-center font-weight-bold text-white">
                        <NumberFormat
                          style={{ marginTop: 10 }}
                          value={truncate(akawo.current_amount, 2)}
                          className="card-bal-your"
                          displayType={"text"}
                          prefix={"NGN "}
                          decimalScale={2}
                          thousandSeparator={true}
                        />
                      </h3>
                      <div className="text-center card-bal-invest">
                        <p className="amt-invest-main font-weight-bold">
                          <NumberFormat
                            style={{ marginTop: 10 }}
                            value={truncate(akawo.invested_amount, 2)}
                            className="card-bal-your"
                            displayType={"text"}
                            prefix={"AMOUNT INVESTED NGN "}
                            decimalScale={2}
                            thousandSeparator={true}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="akawo-p text-center text-muted">
                    Earn 10% interest per annum, get your Interest <br />
                    <span>paid everyday and withdraw anytime</span>
                  </p>
                  <div className="row mobile-hide">
                    <div className="col-sm-4" />
                    <div className="col-sm-2">
                      <div className="text-center">
                        <button
                          className="btn-withdraw text-white"
                          data-toggle="modal"
                          data-target="#withdrawModal"
                        >
                          <i className="fas fa-university pr-2" />
                          Withdraw
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="text-center">
                        <button className="btn-withdraw btn-invest text-white">
                          <Link
                            to="/dashboard/invest"
                            className="no-decoration text-white"
                          >
                            <i className="fas fa-piggy-bank pr-2" />
                            Invest
                          </Link>
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-4" />
                  </div>
                  <div className="row desktop-hide">
                    <div className="text-center">
                      <button
                        className="btn-withdraw withdraw-btn-m text-white"
                        data-toggle="modal"
                        data-target="#withdrawModal"
                      >
                        <i className="fas fa-university pr-2" />
                        Withdraw
                      </button>
                      <Link
                        to="/dashboard/invest"
                        className="btn-withdraw btn-invest text-white"
                      >
                        <i className="fas fa-piggy-bank pr-2" />
                        Invest
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container pb-4 card-mag-b bckgrd-color">
            <div className="card card-inner-pad-tran">
              <h5 className="card-inner-akawo-tran-text text-muted">
                Akawo history
              </h5>
              {(() => {
                if (this.state.isLoading)
                  return (
                    <div className="row">
                      <div className="col-sm-5" />
                      <div className="col-sm-2">
                        <ReactLoading
                          type="spin"
                          color="#fffff"
                          className="spin-s"
                        />
                        <div className="col-sm-5" />
                      </div>
                    </div>
                  );
                else
                  return (
                    <table className="table">
                      <tbody>
                        {this.state.akawohis.length > 0 ? (
                          this.state.akawohis.map((akawohis, index) => {
                            return (
                              <tr className="table-hover" key={akawohis.id}>
                                <td className="table-img-akawo akawo-his-img-pos text-center">
                                  {akawohis.slug === "deposit" ? (
                                    <div className="akawo-his-img">
                                      <i className="far fa-money-bill-alt phone-color-dash" />
                                    </div>
                                  ) : akawohis.slug === "interest" ? (
                                    <div className="akawo-his-img">
                                      <i className="fas fa-percent phone-color-dash" />
                                    </div>
                                  ) : akawohis.slug === "withdrawal" ? (
                                    <div className="akawo-his-img">
                                      <i className="fas fa-university phone-color-dash" />
                                    </div>
                                  ) : (
                                    <p>Hometown</p>
                                  )}
                                </td>
                                <td className="table-dash">
                                  <div>
                                    <h6 className="text-muted akawo-date">
                                      {moment(akawohis.created_at).format(
                                        "MMM Do"
                                      )}
                                    </h6>{" "}
                                    <br />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p className="font-weight-normal table-dash-details1 akawo-dash-details1">
                                      {akawohis.type}
                                    </p>{" "}
                                    <br />
                                    <p className="table-dash-details2">
                                      {akawohis.description}
                                    </p>
                                  </div>
                                </td>
                                <td className="td-width">
                                  {akawohis.direction === "credit" ? (
                                    <span className="float-right akawo-credit-color font-weight-normal">
                                      <NumberFormat
                                        value={akawohis.amount}
                                        className="card-bal-your"
                                        displayType={"text"}
                                        prefix={"+ NGN "}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                      />
                                    </span>
                                  ) : (
                                    <span className="float-right akawo-debit-color font-weight-normal">
                                      <NumberFormat
                                        value={akawohis.amount}
                                        className="card-bal-your"
                                        displayType={"text"}
                                        prefix={"- NGN "}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                      />
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>
                              <div className="text-center no-data full-width">
                                <img src={akawoimg} />
                                <p className="text-muted">
                                  No akawo transaction done yet!
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  );
              })()}
            </div>
          </div>
          <div
            className="modal fade"
            id="withdrawModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-m-pad" role="document">
              <div className="modal-content">
                <div className="modal-header bckgrd-color">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Withdraw from Akawo
                  </h5>
                  <button
                    type="button"
                    className="close akawo-modal-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body bckgrd-color">
                  <div className="card akawo-card">
                    <form onSubmit={this.handleSubmit}>
                      <div className="container">
                        <div className="row">
                          <div className="col-3 col-sm-2">
                            <img
                              src={person}
                              className="rounded-circle"
                              width="45"
                              height="45"
                              alt=""
                            />
                          </div>
                          <div className="col-6 col-sm-10 akawo-wal-pad">
                            <p className="text-muted">Akawo Balance</p>
                            <h5 className="amt-akawo font-weight-bold">
                              <NumberFormat
                                style={{ marginTop: 10 }}
                                value={truncate(akawo.current_amount, 2)}
                                className="card-bal-your"
                                displayType={"text"}
                                prefix={"NGN "}
                                decimalScale={2}
                                thousandSeparator={true}
                              />
                            </h5>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-12">
                            <div>
                              <p className="text-muted amt-style">
                                Amount you want to withdraw
                              </p>
                              <NumberFormat
                                required
                                name="amount"
                                onChange={this.handleChange}
                                thousandSeparator={true}
                                className="form-control"
                                placeholder="Enter amount minimum is 1,000 Naira"
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ paddingTop: 20, paddingBottom: 10 }}
                            >
                              <label
                                htmlFor="formGroupExampleInput"
                                className="profile-input-label text-muted"
                              >
                                Your pin
                              </label>
                              <br />
                              <ReactCodeInput
                                required
                                type="text"
                                inputMode="number"
                                fields={4}
                                value={this.state.pin}
                                onChange={this.handlePinChange}
                              />
                            </div>
                            <button
                              disabled={!this.state.isWithdrawing}
                              type="submit"
                              className="col-sm-12 pl-0 btn signup-btn text-white"
                            >
                              Withdraw now
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Akawo;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
