import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Key from "../../homeAssets/key.png";
import NumberFormat from "react-number-format";
import axios from "axios";
import iziToast from "izitoast";
import * as ConstantVar from "../../constants_var";

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.submitCode = this.submitCode.bind(this);
  }

  submitCode(event) {
    //for handling logging in
    this.setState({
      isLoading: true,
    });

    let self = this;

    //get phone from local storage
    let phone = localStorage.getItem("authUser");

    //send request to server for verification of login credentials
    axios
      .post(ConstantVar.API_BASE_URL + "/user/auth/verify", {
        phone: phone,
        auth_code: event.target.code.value,
      })
      .then((response) => {
        //check response received
        if (response.data.success === "true") {
          //login was successful

          //clear auth user
          localStorage.removeItem("authUser");
          localStorage.setItem("token", response.data.data);

          //redirect
          setTimeout(function () {
            //redirect to wallet
            window.location = "/dashboard/overview";
          }, 1000);
        } else {
          //login failed
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
        self.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    event.preventDefault();
  }
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar />
        <section>
          <div className="container">
            <img className="fifth1-section" src={Key} alt="paymentIcon" />
            <div className="row">
              <div className="col-sm-3" />
              <div className=" col-sm-6">
                <div className="card all-card login-card text-center">
                  <h3 className="font-weight-bold">Verify it's you</h3>
                  <p>
                    We've sent a verification code to your phone number and
                    email. Enter the code in the field below.
                  </p>
                  <form onSubmit={this.submitCode}>
                    <NumberFormat
                      className="form-control"
                      required
                      placeholder="Enter 4 digit code"
                      name="code"
                      format="######"
                    />
                    <button
                      type="submit"
                      disabled={this.state.isLoading}
                      className="btn btn-success w-100 mt-3"
                    >
                      Verify
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Verify;
