import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import AppContext from "../AppContext";

class Terms extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar />
        <section>
          <div className="container">
            <div className="card all-card p-5">
              <h3 className="font-weight-bold">
                Terms and Conditions for Hometown Fintech Limited
              </h3>

              <p>
                At Hometown App, accessible from{" "}
                <a href="https://gethometown.app/">https://gethometown.app/</a>,
                one of our main priorities is the privacy of our visitors.
              </p>

              <p>
                If you have additional questions or require more information
                about our Terms and Conditions, please do not hesitate to contact us.
              </p>

              <p>
                These Terms and Conditions apply to and regulate the provision
                of financial services provided by Hometown Fintech Limited, a
                financial licensed lender which provides financial services via
                its flagship mobile app – Hometown™ (hereinafter called
                "Hometown Fintech") to the Customer herein. These Terms and
                Conditions constitute Hometown Fintech's offer and sets out the
                terms governing this Agreement.
              </p>
              <p>
                Hometown™® is an open-end credit plan offered by Hometown
                Fintech and BY ACCEPTING THIS ONLINE OFFER, A HOMETOWN™ ACCOUNT
                IS SET UP WITH Hometown Fintech and you agree that you have read
                these Terms and Conditions. You authorize Hometown Fintech to
                review your credit report and you understand that this account
                may be subject to transaction fees and default fees and is
                governed by the Laws of the Federal Republic of Nigeria
              </p>

              <h3 className="font-weight-bold">Consent</h3>

              <p>
                Hometown™® is an online investments platform that enables you to
                put away funds you do not want to touch easily. By setting up an
                AKAWO® account, you have chosen to invest a minimum of NGN 100
                in an investment account subject to the terms and conditions
                herein. By accepting these Terms and Conditions, you have agreed
                to open a virtual wallet (hereinafter called "Wallet") wherein
                all approved loan applications on Hometown™ shall be disbursed.
                You may also use your Wallet for certain payment services
                available on Hometown™, or you may use the same for Hometown
                Akawo® wherein you may opt to invest funds subject to the terms
                thereof.
              </p>

              <h3 className="font-weight-bold">Information we collect</h3>

              <p>
                You will be asked to provide information (such as your date of
                birth and your Bank Verification Number) when you apply for a
                Hometown™ loan, as well as your current residential address,
                and/or any other KYC requirement as may be prescribed by the
                Central Bank of Nigeria from time to time, for the opening of a
                Hometown® account. This information shall be used for
                verification purposes.
              </p>
              <p>
                In order to ensure that we are able to provide communications to
                you electronically, you must notify us of any change in your
                email address and your mobile device number or other text
                message address by updating your profile on the Hometown™ App.
              </p>
              <p>
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>

              <h3 className="font-weight-bold">How to use Hometown account</h3>

              <p>
                This is an open-end credit account where you access direct loan
                or credit facility using Hometown™, while you repay the loan or
                credit facility on a future date via cash transfer or electronic
                repayment from your Wallet on the Payment Due Date at a flat
                interest on the loan, depending on your disbursed loan.
              </p>

              <p></p>

              <h4 className="font-weight-bold">
                1. Definitions In this agreement, the terms:
              </h4>

              <p>
                "You", "your", "Customer", "Borrower" shall mean the person who
                applied for this Account and agrees to this Agreement while
                "We", "us" and "our" and "Lender" shall mean Hometown Fintech,
                and following an assignment, any person, company or bank to whom
                the rights and/or obligations of Hometown Fintech have been
                assigned. "Account" means the Customer's account with Hometown
                Fintech "Disbursement Date" means the date Hometown Fintech
                advanced the loan to the Customer's Wallet "Payment Due Date"
                means a maximum of 15 days, 30 days, 45 days after the loan has
                been disbursed "Credit Limit" means the maximum credit available
                to the Borrower on opening a Wallet account with Hometown
                Fintech "Loan" means the amount advanced to the Borrower by
                Hometown Fintech, which shall be no less than N1,000.00 (One
                Tousand Naira only).
              </p>

              <h4 className="font-weight-bold">
                2. Conditions for use of the Wallet
              </h4>
              <p>To use the Wallet –</p>

              <p>
                2.1. You must: (a) accept and agree to these Terms and
                Conditions; (b) register with us on the Hometown™ app; (c) be a
                Nigerian citizen (or a legal Nigerian resident) of at least 18
                years of age; (d) have a bank account with a Nigerian financial
                institution; and (e) provide all information as may be requested
                by us, such as your name, email address, mobile device number,
                online credentials for your bank account, and such other
                information as we may request from time to time (collectively,
                "Customer Information").
              </p>

              <p>
                2.2. You represent and warrant that all Customer Information you
                provide us from time to time is truthful, accurate, current, and
                complete, and you agree not to misrepresent your identity or
                your Customer Information.
              </p>

              <p>
                2.3. You agree to promptly notify us of changes to your Customer
                Information by updating your profile on the Hometown™ app and to
                notify us at least five business days before any changes to your
                bank account information, including, but not limited to, the
                closure of your bank account for any reason.
              </p>

              <p>
                2.4. If we approve your registration, you will be authorized to
                use the Wallet, subject to these terms –
              </p>

              <p>
                2.4.1. For our compliance purposes and to provide the Wallet
                services to you, you hereby authorize us to, directly or through
                a third-party, obtain, verify, and record information and
                documentation that helps us verify your identity and bank
                account information.
              </p>

              <p>
                2.4.2. When you register for the Wallet and from time to time
                thereafter, we may require you to provide and/or confirm
                information and documentation that will allow us to identify
                you, such as:
              </p>

              <p>
                A copy of your government-issued photo ID, such as a national
                ID, international passport, permanent voter's card or driver's
                license;
              </p>

              <p>
                A copy of a utility bill, bank statement, affidavit, or another
                bill, dated within three months of our request, with your name
                and Nigerian street address on it; and
              </p>

              <p>
                Such other information and documentation that we may require
                from time to time.
              </p>

              <p>
                2.5. By using the Wallet and providing Customer Information to
                us, you automatically authorize us to obtain, directly or
                indirectly through our third-party service providers and without
                any time limit or the requirement to pay any fees, information
                about you and your bank account from the financial institution
                holding your bank account and other third-party websites and
                databases as necessary to provide the Wallet to you. For
                purposes of such authorization, you hereby grant Hometown
                Fintech and our third-party service providers a limited power of
                attorney, and you hereby appoint Hometown Fintech and our
                third-party service providers as your true and lawful
                attorney-in-fact and agent, with full power of substitution and
                re-substitution, for you and in your name, place, and stead, in
                any capacities, to access third-party websites, servers, and
                documents; retrieve information; and use your Customer
                Information, all as described above, with the full power and
                authority to do and perform each and every act and thing
                requisite and necessary to be done in connection with such
                activities, as fully to all intents and purposes as you might or
                could do in person.
              </p>

              <p>
                2.6. YOU ACKNOWLEDGE AND AGREE THAT WHEN HOMETOWN FINTECH OR OUR
                THIRD-PARTY SERVICE PROVIDERS ACCESS AND RETRIEVE INFORMATION
                FROM SUCH THIRD-PARTY WEBSITES, HOMETOWN FINTECH AND OUR
                THIRD-PARTY SERVICE PROVIDERS ARE ACTING AS YOUR AGENT, AND NOT
                THE AGENT OF, OR ON BEHALF OF THE THIRD PARTY
              </p>

              <p>
                2.7. You agree that other third parties shall be entitled to
                rely on the foregoing authorization, agency, and power of
                attorney granted by you
              </p>

              <p>
                We make no effort to review information obtained from the
                financial institution holding your bank account and other
                third-party websites and databases for any purpose, including,
                but not limited to, accuracy, legality, or non-infringement. As
                between Hometown Fintech and our third-party service providers,
                Hometown Fintech owns your confidential Customer Information
              </p>

              <h4 className="font-weight-bold">
                3. Conditions for use of the Akawo Account
              </h4>

              <p>To use the investment account –</p>

              <p>
                3.1. You must: (a) accept and agree to these Terms and
                Conditions; (b) register with us on the Hometown™ app; (c) be a
                Nigerian citizen (or a legal Nigerian resident) of at least 18
                years of age; (d) have a bank account with a Nigerian financial
                institution; and (e) provide all information as may be requested
                by us, such as your name, occupation, email address, mobile
                device number, online credentials for your bank account, and
                such other information as we may request from time to time
                (collectively, "Customer Information").
              </p>

              <p>
                3.2. You represent and warrant that all Customer Information you
                provide us from time to time is truthful, accurate, current, and
                complete, and you agree not to misrepresent your identity or
                your Customer Information.
              </p>

              <p>
                3.3. You agree to promptly notify us of changes to your Customer
                Information by updating your profile on the Hometown™ app and to
                notify us at least five business days before any changes to your
                bank account information, including, but not limited to, the
                closure of your bank account for any reason.
              </p>

              <p>
                If we approve your registration, you will be authorized to use
                the Akawo® account, subject to these terms –
              </p>

              <p>
                For our compliance purposes and to provide the Akawo® investment
                services to you, you hereby authorize us to, directly or through
                a third-party, obtain, verify, and record information and
                documentation that helps us verify your identity and bank
                account information. When you set up your Akawo® and from time
                to time thereafter, we may require you to provide and/or confirm
                information and documentation that will allow us to identify
                you, such as:
              </p>

              <p>
                A copy of your government-issued photo ID, such as a national
                ID, international passport, permanent voter's card or driver's
                license;
              </p>

              <p>
                A copy of a utility bill, bank statement, affidavit, or other
                bills, dated within three months of our request, with your name
                and Nigerian street address on it;
              </p>

              <p>
                Such other information and documentation that we may require
                from time to time.
              </p>

              <p>
                3.5. Each time you invest, Hometown Fintech shall send an
                investment information to your designated email address, sms or
                app notification showing details of the investment made and any
                other information.
              </p>

              <p>
                You can increase existing investments which may change the terms
                of your initial investment. In the event you choose to increase
                your initial investment, an updated notification will be
                provided to you.
              </p>

              <p>
                3.6. You authorize Hometown Fintech to manage your investment
                funds, either directly, via its authorized subsidiaries or third
                party management partners.
              </p>

              <p>
                3.7. You consent to have any matured investments automatically
                rolled over into a new investment if a liquidation event is not
                initiated by you within 72 hours of an investment maturing.
              </p>

              <p>
                3.8. You confirm that you understand and agree that, in the
                event of an early redemption before maturity, you may be charged
                a liquidation fee on the amount being redeemed.
              </p>

              <p>
                3.9. You may be provided with a customized loan offer, based on
                any active investments you have on Hometown. If you accept such
                customized loan offer, and if same is in arrears on the maturity
                or early redemption date of the specific investment which formed
                the basis of the loan offer, you agree and authorize Hometown
                Fintech to take a portion of the investment and use same to
                offset the outstanding loan. Where the investment amount on the
                maturity or early redemption date is equal to the outstanding
                loan amount, you agree and authorize Hometown Fintech to use the
                entire investment amount to offset the outstanding loan.
              </p>

              <p>
                3.10. You authorize Hometown Fintech to debit any of your
                available payment method(s) on Hometown on scheduled investment
                dates on any recurring/periodic investment plans you set up.
              </p>

              <p>
                3.11. You may be provided with investment recommendations, where
                Hometown Fintech suggests investments deemed suitable to your
                profile. You agree that Hometown Fintech will not be liable for
                any loss or damages you suffer as a result of your accepting
                such investment recommendations.
              </p>

              <p>
                3.12. You confirm that you understand and agree that any
                liquidated investments will be processed by Hometown and paid
                out into your default receiving wallet within 3 working days of
                receiving the liquidation/withdrawal advice.
              </p>

              <p>
                3.13. You acknowledge that Hometown Fintech may be required by
                law, to liquidate your investment before maturity in the event
                that the Customer information provided is untrue, inaccurate or
                incomplete; and you hereby agree to be liable for any costs that
                may be reasonably incurred by the third party in connection with
                closing your account;
              </p>

              <p>
                3.14. In line with your decision to invest your savings,
                Hometown Fintech has the power to whether directly or commingled
                with the resources of another person, invest, convert from one
                form to another, buy, sell, re-invest and manage on your behalf
                any funds which have been indicated for investments.
              </p>

              <p>
                3.15. Although Hometown Fintech and its third-party investment
                partners carefully evaluate potential returns based on historic
                performance and collect information on market performances
                Hometown Fintech, and its third-party investment partners make
                no representation regarding the likelihood or probability that
                any actual or proposed investment will in fact achieve a
                particular outcome or goal.
              </p>

              <p>
                3.16. Past performance is not a guarantee of future success, and
                in fact, volatility means that returns in any period may be far
                above or below those of the previous period. Investments may
                lose value. Hometown Fintech and its third-party investment
                partners are unable to predict or forecast market fluctuations
                or other uncertainties that may affect the value of any
                investment.
              </p>

              <p>
                3.17. You are urged to use all available resources to educate
                yourself about investing in general, as well as the investments
                and the overall composition of your account. Additionally,
                market conditions and your personal financial circumstances may
                change – perhaps suddenly or gradually over time. Monitoring and
                adjusting your account to suit changing circumstances is your
                responsibility, and it is recommended that you reassess any
                investing program on a regular basis to ensure that it remains
                consistent with your current financial resources and investment
                objective.
              </p>

              <p>
                3.18. Hometown Fintech and its third-party investment partners
                will not be liable in any way for any indirect, special,
                punitive, consequential, or indirect damages (including without
                limitation lost profits, cost of procuring substitute service or
                lost opportunity) arising out of or in connection with your
                decision to invest your savings, whether or not they are made
                aware of the possibility of such damages.
              </p>

              <h3 className="font-weight-bold">4. Customer consent</h3>

              <p>
                By ticking the "I agree to the Terms and Conditions", on the
                application form, which you hereby adopt as your electronic
                signature, you consent and agree that:
              </p>
              <p>
                4.1. We can provide materials and other information about your
                legal rights and duties to you electronically.
              </p>
              <p>
                4.2. We are authorized to share, receive and use
                data/information collected from your transaction with other
                affiliated third parties including but not limited to switching
                companies, mobile network operators, electricity companies,
                aggregators, credit bureaus, other financial institutions,
                e-commerce platforms, etc.
              </p>
              <p>
                4.3. You consent to our transferring your data to recipients in
                foreign countries to process such data. You are aware of the
                risks involved in such transfer to such foreign countries and
                that such transfer is required for the performance of our
                contract with you.
              </p>
              <p>
                4.4. You hereby expressly consent and authorize Hometown Fintech
                to debit your Wallet and/or authorize us or any of our duly
                authorized agent or partner to debit your designated salary
                account, or any other bank account linked to your Bank
                Verification Number (BVN), for repaying the Loan with applicable
                interest as and when due.
              </p>

              <p>
                4.5. To access payroll-related offers, you have expressly
                consented and authorized us to verify your salary details with
                your employer and/or your employer's appointed salary processors
                without further recourse to you.
              </p>
              <p>
                4.6. Your electronic signature on agreements and documents has
                the same effect as if you signed them using ink on paper or any
                other physical means.
              </p>

              <p>
                4.7. We can send all important communications, billing
                statements and demand notes and reminders (collectively referred
                to as "Disclosures") to you electronically via our website or to
                an email address that you provide to us while applying for this
                loan.
              </p>
              <p>
                4.8. We will alert you when the Disclosures are available, by
                sending you an electronic communication.
              </p>
              <p>
                4.9. Our email or app notification will tell you how you can
                view the Disclosures.
              </p>
              <p>
                4.10. We will make the Disclosures available to you from the
                date it first becomes available, or the date we send you the
                email to alert you that it was available.
              </p>

              <p>
                4.11. You will be able to print a copy of the Disclosure or
                download the information for your records.
              </p>
              <p>
                4.12. This consent applies to this transaction, to all future
                Disclosures on this Account, to all future transactions in which
                you use the Hometown Payment option with us, at any time, and to
                other Disclosures that we provide to you by email, unless you
                have, prior to such transaction, withdrawn your consent by the
                procedure mentioned below.
              </p>
              <p>
                4.13. By consenting, you agree that electronic Disclosures have
                the same meaning and effect as if we provided paper Disclosures
                to you as we are required to do so. When we send you an email
                alerting you that the Disclosures are available electronically
                and make it available online, that shall have the same meaning
                and effect as if we provided paper Disclosures to you, whether
                or not you choose to view the Disclosures, unless you had
                previously withdrawn your consent to receive Disclosures
                electronically.
              </p>
              <h3 className="font-weight-bold">5. Transaction fee</h3>

              <p>
                5.1. The Transaction Fee for the use of the Hometown Account
                shall be up to one percent (1%) daily, fixed for the term of
                this loan and as set from time to time.
              </p>
              <p>
                5.2. The transaction fee does not preclude the Lender from
                charging default fees, a penalty fee and in the event of any
                dispute arising from these Terms and Conditions – the cost of
                Litigation/Solicitors' fees.
              </p>
              <p>
                5.3. The Transaction fee may be increased or decreased from time
                to time by the Lender. Such change in Transaction fee will take
                effect on the Borrower's account.
              </p>
              <p>
                5.4. A total transaction fee of the term of the loan shall
                remain applicable in the event of the Borrower liquidating the
                loan before expiration.
              </p>

              <h3 className="font-weight-bold">6. Borrower's obligations:</h3>

              <p>
                6.1. To pay to us, the loan sum, including any convenience,
                default or penalty fees, flat interest rate, and other amounts
                due to the Lender charged to this Account. Borrower promises to
                pay these amounts as agreed in this Agreement on or before the
                Payment Due Date.
              </p>

              <p>
                6.2. The Lender reserves the right to presume that the Borrower
                has authorized any loan application made in the name of the
                Borrower using this Account. The Borrower will be deemed
                responsible for any unauthorized application using this Account
                unless the Borrower notifies the Lender, in writing, of imminent
                fraud by another person on the Borrower's account within 24
                hours of such fraud.
              </p>
              <p>
                6.3. To repay the loan given to you and/or to someone you
                authorized to use this Account via direct cash transfer to a
                bank account listed by the Lender, an electronic debit from the
                card/account you provided on the due date, or through an
                acceptable electronic channel. The Lender reserves the right to
                accept early repayment before the Payment Due Date, provided the
                Borrower has given adequate notice and repays the full loan
                along with the flat interest rate. Loan repayment via electronic
                debit card may be affected by the Lender in the way and manner
                hereinafter specified –
              </p>
              <p>
                6.3.1. The Lender shall charge a one-time processing fee the
                first time a Customer sets up a new debit card on the Account.
                In the event the card is expired or about to expire, you will
                have to obtain a renewed card from your bank or provide us with
                another personal debit card.
              </p>
              <p>
                6.3.2. The Lender shall deduct from the card setup on the
                repayment due date. We will never make any transactions on the
                card outside of the scheduled loan repayments. In the event of a
                double repayment initiated in error by you, we will on a best
                effort basis process a refund within a reasonable time.
              </p>
              <p>
                6.3.3. You hereby indemnify us against any losses, liabilities
                or damages that may arise if you use a third party's card on
                your Account without the consent of such third party.
              </p>
              <p>
                6.3.4. In the event of a default or insufficiency of funds in
                your bank account, you hereby authorize us to charge any other
                cards you may have on your Account or profile other than the
                primary card listed.
              </p>
              <p>
                6.3.5. You understand and agree that we do not store card
                details on our platform thus card details shall be processed
                through a secure payment gateway that is PCI DSS compliant.
              </p>
              <p>
                6.4. To give The Lender authentic and up-to-date personal,
                social media, phone and financial records about you that we may
                reasonably request and analyze, from time to time.
              </p>
              <p>
                6.5. To pay all costs of collection if we take any action to
                collect this Account or take any action in a bankruptcy
                proceeding filed by or against you. This shall include unless
                prohibited by applicable law, reasonable attorneys' fees and
                expenses incurred while collection lasts.
              </p>
              <p>
                6.6. Not to give us false information or signatures, electronic
                or otherwise, at any time.
              </p>
              <p>
                6.7. To pay a Late Fee or penalty fee as may be provided in
                these terms and conditions.
              </p>
              <p>
                6.8. To make all payments via direct debit on cards or by
                electronic funds transfer as stated in the application form.
              </p>
              <p>
                6.9. To promptly notify us if you change your name, mailing
                address, e-mail address or telephone number.
              </p>
              <p>
                6.10. To honor any other promises that you make in this
                Agreement.
              </p>
              <p>
                6.11. That you will not accept this Account unless you are of
                legal age and have the capacity to enter into a valid contract.
              </p>
              <p>
                6.12. Not to use Hometown for any act of illegality or
                criminality and Hometown Fintech shall not be legally or
                criminally culpable for any illegality committed by you.
              </p>
              <p>
                6.13. To fill out the application form (as provided) with
                accurate information and details as required.
              </p>

              <h3 className="font-weight-bold">7. Lender's obligations:</h3>

              <p>
                7.1. To make available the loan/credit facility to the Borrower
                of a figure not less than N1,000.00 (One Thousand Naira only)
              </p>
              <p>
                7.2. To perform its obligation under 6.1 above upon confirmation
                by the Lender of the Borrower's identity and personal
                information
              </p>
              <p>7.3. To demand repayment for the borrower as and when due</p>
              <p>
                7.4. To conduct investigations on the Borrower prior to opening
                an account for the Borrower
              </p>
              <p>
                7.5. To use all reasonable and legitimate means to collect the
                amount extended to the Borrower, the default fee, the
                transaction fees and any other penalty fee imposed on the
                Borrower as a result of the loan.
              </p>
              <p>
                7.6. Not to store or save Borrower's debit or credit card
                details given by the Borrower in the application form
              </p>
              <p>
                7.7. In the event of a default in the payment obligations by the
                Borrower to Hometown Fintech arising out of or in connection
                with these Terms and Conditions, the Borrower agrees that,
                Hometown Fintech shall have the right to recover the outstanding
                principal and accrued interest payments only, by activating the
                Global Standing Instruction as authorized by the Central Bank of
                Nigeria, without any recourse to the Borrower. The Borrower
                agrees that by activation of the Global Standing Instruction,
                Hometown Fintech can directly set off the Borrower's outstanding
                payments from deposits and investments held in the Borrower's
                qualifying bank accounts in any other financial institution and
                that the Borrower's consent herein, shall also be deemed an
                instruction to such other financial institution to carry out the
                Global Standing Instruction mandate activated by Hometown
                Fintech. The Borrower further waives any right of
                confidentiality against Hometown Fintech in the event of such
                default.
              </p>

              <h3 className="font-weight-bold">8. Credit reference</h3>

              <p>
                8.1. The Lender or it's duly authorized representatives/agents
                will utilize dedicated Credit Agencies for a credit report on
                the Borrower in considering any application for credit.
              </p>
              <p>
                8.2. The Borrower authorizes the Lender to access any
                information available to the Lender as provided by the Credit
                Agency.
              </p>
              <p>
                8.3. The Borrower also agrees that the Borrower's details
                (excluding the card details) and the loan application decision
                may be registered with the Credit Agency.
              </p>

              <h3 className="font-weight-bold">9. Notices</h3>

              <p>
                The Borrower agrees that The Lender may communicate with them by
                sending notices, messages, alerts, and statements in relation to
                this Agreement in the following manner:
              </p>
              <p>
                To the most recent physical address, the Lender holds for the
                Borrower in its records
              </p>
              <p>
                By delivery to any email address provided by the Borrower during
                the application process.
              </p>
              <p>
                By delivery of an SMS to any mobile telephone number, the
                Borrower has provided to the Lender.
              </p>
              <p>By posting such notice on the Lender's website.</p>

              <h3 className="font-weight-bold">10. Event of default</h3>
              <p>10.1. Default in terms of this Agreement will occur if:</p>

              <p>
                The Borrower fails to make any scheduled repayment in full on or
                before the payment due date in accordance with the repayment
                plan given to the Borrower by the Lender;
              </p>
              <p>
                Any representation/information, warranty or assurance made or
                given by the Borrower in connection with the application for
                this loan or any information or documentation supplied by the
                Borrower is later discovered to be materially incorrect; or
              </p>
              <p>
                The Borrower does anything which may prejudice the Lender's
                rights in terms of this Agreement or causes the Lender to suffer
                any loss or damage.
              </p>
              <p>
                10.2. In the event of any default by the Borrower subject to
                clause 9.1 above –
              </p>
              <p>
                the Lender may, without notice to or demand on the Borrower
                (which notice or demand the Borrower expressly waives), set-off,
                appropriate or apply any balance outstanding on the Borrower's
                investment account held by the Lender, or any indebtedness at
                any time owed by the Lender to or for the account of the
                Borrower, against the Borrower's obligations to the Lender,
                whether or not those obligations have matured.
              </p>
              <p>
                The Lender reserves the right to assign its right, title and
                interest under the Agreement to an external Collections Agency
                who will take all reasonable steps to collect the outstanding
                loan amount.
              </p>
              <p>
                The Lender also reserves the right to institute legal
                proceedings against the defaulting Borrower and is under no
                obligation to inform the Borrower before such proceedings
                commence.
              </p>
              <p>
                The Borrower shall be responsible for all legal costs and
                expenses incurred by the Lender in attempting to obtain
                repayment of any outstanding loan balance owed by the Borrower
                or recovery of any kind. Interest on any amount which becomes
                due and payable shall be charged.
              </p>

              <h3 className="font-weight-bold">
                11. Termination of the account
              </h3>

              <p>
                11.1. In addition to Termination by default, we may terminate
                this Agreement at any time and for any reason subject to the
                requirements of applicable law.
              </p>

              <p>
                11.2. We may terminate your Account by sending app notification
                or written notice to the address on your application.
              </p>
              <p>
                11.3. If there is no debit or credit on your Account for twelve
                (12) consecutive months, we may terminate this Account without
                notice to you.
              </p>
              <p>
                11.4. When Termination arises as a result of the default, you
                shall not be able to apply for credit in the future.
              </p>

              <h3 className="font-weight-bold">12. General</h3>

              <p>
                12.1. This Agreement represents the entire understanding between
                the Lender and the Borrower.
              </p>
              <p>
                12.2. Any amendment to this Agreement shall be made by the
                Lender from time to time and communicated to the Borrower in
                writing.
              </p>
              <p>
                12.3. The Borrower agrees and undertakes that for the period of
                this Agreement, the Borrower will not close the Borrower's
                specified bank account.
              </p>
              <p>
                12.4. This Agreement shall be governed and construed under the
                laws of the Federal Republic of Nigeria and, in the event of a
                dispute, shall be subject to the jurisdiction of the courts of
                the Federal Republic of Nigeria.
              </p>
              <p>
                12.5. Notwithstanding any other provision in this Agreement,
                neither the Lender, its affiliates, officers, directors,
                employees, attorneys or agents shall have any liability with
                respect to, any claim for any special, indirect, incidental,
                consequential damages suffered or incurred by you in connection
                with, arising out of, or in any way related to, a breach of this
                Agreement.
              </p>
              <p>
                12.6. If The Lender does not strictly enforce its rights under
                this Agreement (including its right to insist on the repayment
                of all sums due on the Repayment Due Date) or grant the Borrower
                an indulgence, the Lender shall not be deemed to have waived or
                lost those rights and will not be prevented from insisting upon
                its strict rights at a later date.
              </p>
              <p>
                12.7. The Lender reserves the right to transfer or assign its
                rights and obligations under this Agreement (including its
                obligation to lend money to the Borrower or the amount owed
                under this Agreement) to another person. The Lender will only
                notify the Borrower if such a transfer causes the arrangements
                for the administration of this Agreement to change.
              </p>
              <p>
                12.8. The Borrower authorizes and consents to all lawful access,
                use or disclosure of the Borrower's particulars, data and
                information in the application by the Lender which may include
                but shall not be limited to purposes necessary to promote or
                sustain the business of the Lender; and the Borrower waives any
                claims the Borrower may have against the Lender arising from any
                such access, use or disclosure.
              </p>
              <p>
                12.9. Hometown Fintech may at any time vary revise these Terms
                and Conditions by updating this document. You agree to be bound
                by subsequent revisions and agree to review these Terms and
                Conditions periodically for changes. The most updated version of
                this document will be available for your review under the
                "Hometown™ Terms and Conditions" link that appears on the
                Hometown website and mobile application.
              </p>

              <h3 className="font-weight-bold">13. Indemnification</h3>

              <p>
                You will indemnify and hold harmless Hometown Fintech and the
                Third Party Providers, and the officers, directors, agents,
                partners, employees, licensors, distributors, and
                representatives of Hometown Fintech and the Third Party
                Providers, from and against any and all claims, demands,
                actions, causes of action, suits, proceedings, losses, damages,
                costs, and expenses, including reasonable attorneys' fees,
                arising from or relating to your access and/or use of, or
                interaction with the Hometown™ app (including, without
                limitation, Third Party Content), or any act, error, or omission
                of your use of your account or any user of your account, in
                connection therewith, including, but not limited to, matters
                relating to incorrect, incomplete, or misleading information;
                libel; invasion of privacy; infringement of a copyright, trade
                name, trademark, service mark, or other intellectual property;
                any defective product or any injury or damage to person or
                property caused by any products sold or otherwise distributed
                through or in connection with the Service; or violation of any
                applicable law.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
Terms.contextType = AppContext;
export default Terms;
