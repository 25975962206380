import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './assets/index.css';
import imgDeposit from './assets/deposit.png'
import Navbar from '../../../Components/dashComponents/navbar';
import axios from 'axios';
import moment from 'moment';
import AppContext from '../../../AppContext'
import VerificationBar from '../../../Components/dashComponents/verification_bar'
import ActionBar from '../../../Components/dashComponents/action_bar'
import Pin from '../../../Components/dashComponents/pin'
import NumberFormat from 'react-number-format'
import iziToast from "izitoast";

class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
        this.setAmount = this.setAmount.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    setAmount(e){

        let amount = e.target.value.toString();

        //strip amount of the commas and all
        if (amount !== ''){
            amount = parseFloat(amount.replace(/,/g, ''));
        }

        this.context.updateAmount({
            amount: amount
        })
    }
    render() {

        return (
            <div className="bckgrd-color">
                <div>
                    <Navbar />
                    <div className="container">
                        <VerificationBar email_verified_at={this.context.user.email_verified_at}/>
                    </div>
                </div>
                <div className="bckgrd-color">
                    <div className="container dashbord-con">
                        {/*<ActionBar/>*/}
                        <div className="row">
                            <div className="col-sm-3"/>
                            <div className="col-sm-6">
                                <div className="card-bill card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h4 style={{paddingTop:30}}>Deposit Funds</h4>
                                            <p>
                                                <img style={{height:100}} src={imgDeposit}/>
                                            </p>
                                        </div>
                                        <div style={{padding:20}}>
                                            <div style={{marginBottom:20}}>
                                                <label>Amount you want to deposit</label>
                                                <NumberFormat
                                                    value={this.context.amount}
                                                    allowNegative={false}
                                                    placeholder="Amount (minimum is 50)"
                                                    onChange={this.setAmount}
                                                    thousandSeparator={true}
                                                    className="form-control"
                                                />
                                                <NumberFormat
                                                    style={{borderBottom:'1px dashed #eee',display:'inline-block',marginTop:10}}
                                                    value={truncate(this.context.user.available_bal, 2)}
                                                    className="text-blue"
                                                    displayType={'text'}
                                                    prefix={'Balance: NGN '}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                />
                                            </div>
                                            <Link
                                                to="/dashboard/deposit/option"
                                                disabled={!(this.context.amount_ok)}
                                                className="col-sm-12 pl-0 btn signup-btn text-white">
                                                Proceed
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Dashboard.contextType = AppContext;
export default Dashboard;

function truncate (num, places) {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}