import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import imgTransfer from "./assets/transfer.png";
import Navbar from "../../../Components/dashComponents/navbar";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import SearchableBanks from "../../../Components/dashComponents/searchable_banks";
import AppContext from "../../../AppContext";
import NumberFormat from "react-number-format";
import Pin from "../../../Components/dashComponents/pin";
import Pin2 from "../../../Components/dashComponents/pin2";
import axios from "axios/index";
import * as ConstantVar from "../../../constants_var";

let typingTimer,
  doneTypingInterval = 2000;

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerifyingPhone: false,
      isEmpty: true,
      account: "invalid",
      phone_number: "",
      bank: "",
      account_number: "",
      is_account_ok: false,
      account2: "invalid",
      bank_name: "",
    };

    this.setPhone = this.setPhone.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.handleBank = this.handleBank.bind(this);
    this.setAccount = this.setAccount.bind(this);
  }

  handleBank(bank) {
    this.setState({
      bank: bank.value,
      bank_name: bank.label,
    });
  }
  setAccount(e) {
    let accountNo = e.target.value,
      self = this;

    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      //start loader
      self.setState({
        isVerifyingAccount: true,
        account_number: accountNo,
        is_account_ok: accountNo.length === 10,
      });

      //verify eos account details
      axios
        .post(ConstantVar.API_BASE_URL + "/resolve_account", {
          account_number: accountNo,
          bank: self.state.bank,
        })
        .then((response) => {
          //check response
          if (response.data.success === "true" && response.data.data !== null) {
            //user found, load profile picture in picture-box
            let data = response.data.data;
            self.setState({
              isVerifyingAccount: false,
              account2: data,
            });
          } else {
            //notify user that account is invalid
            self.setState({
              isVerifyingAccount: false,
              account2: "invalid",
            });
          }
        });
    }, doneTypingInterval);
  }
  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      amount: amount,
      amount_ok: amount >= 50,
    });
  }
  setPhone(e) {
    let phoneNo = e.target.value,
      self = this;

    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      //start loader
      self.setState({
        isVerifyingPhone: true,
        phone_number: phoneNo,
        isEmpty: !(phoneNo.length === 11),
      });

      //verify eos account details
      axios
        .get(
          ConstantVar.API_BASE_URL +
            "/phone_available?reg_name=true&phone=" +
            phoneNo
        )
        .then((response) => {
          //check response
          if (
            response.data.success === "false" &&
            response.data.data !== undefined
          ) {
            //user found, load profile picture in picture-box
            let data = response.data.data;
            self.setState({
              isVerifyingPhone: false,
              account: data,
            });
          } else {
            //notify user that account is invalid
            self.setState({
              isVerifyingPhone: false,
              account: "invalid",
            });
          }
        });
    }, doneTypingInterval);
  }

  render() {
    let transferHometownPayload = {
        amount: this.state.amount,
        recipient: this.state.phone_number,
      },
      transferBankPayload = {
        amount: this.state.amount,
        bank: this.state.bank_name,
        bank_code: this.state.bank,
        account_number: this.state.account_number,
        name: this.state.account2,
      };

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card card-trans">
                  <div className="text-center">
                    <h4 style={{ paddingTop: 30 }}>Transfer Money</h4>
                    <p>
                      <img style={{ height: 100 }} src={imgTransfer} />
                    </p>
                  </div>
                  <ul className="nav" id="myTab" role="tablist">
                    <li className="nav-item btn-trans-ht">
                      <a
                        className="nav-link my active btn-trans-ht-p"
                        id="home-tab"
                        data-toggle="tab"
                        href="#wallet"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Hometown wallet
                      </a>
                    </li>
                    <li className="nav-item btn-trans-bank">
                      <a
                        className="nav-link my btn-trans-bank-p"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#bank"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Bank account
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="wallet"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="modal-body">
                        <div className="card akawo-card">
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="formGroupExampleInput"
                                    className="profile-input-label text-muted"
                                  >
                                    Hometown phone number
                                  </label>
                                  <input
                                    required
                                    type="number"
                                    name="phone"
                                    onChange={this.setPhone}
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder="Enter hometown phone number"
                                  />
                                </div>
                                <div style={{ marginTop: 10 }}>
                                  <div
                                    style={{ marginTop: 0 }}
                                    className={
                                      "text-center " +
                                      (this.state.isEmpty
                                        ? "hide"
                                        : this.state.isVerifyingPhone
                                        ? "show"
                                        : "hide")
                                    }
                                  >
                                    <div
                                      className="spinner-border"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{ marginTop: 0 }}
                                    className={
                                      this.state.isEmpty
                                        ? "hide"
                                        : this.state.isVerifyingPhone
                                        ? "hide"
                                        : "show"
                                    }
                                  >
                                    <div
                                      className="item"
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <div className="text-center">
                                        {(() => {
                                          if (
                                            this.state.account === "invalid"
                                          ) {
                                            return (
                                              <div
                                                className="alert alert-warning"
                                                style={{ width: "100%" }}
                                              >
                                                <p className="text-warning mb-0">
                                                  <i className="fa fa-exclamation-circle" />
                                                  &nbsp;Could not verify User
                                                  phone number.
                                                </p>
                                              </div>
                                            );
                                          } else
                                            return (
                                              <div
                                                className="alert alert-success"
                                                style={{ width: "100%" }}
                                              >
                                                <p className="text-success mb-0">
                                                  <i className="fa fa-check" />
                                                  &nbsp;{this.state.account}
                                                </p>
                                              </div>
                                            );
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="formGroupExampleInput2"
                                    className="profile-input-label text-muted"
                                  >
                                    Amount
                                  </label>
                                  <NumberFormat
                                    allowNegative={false}
                                    placeholder="Amount (minimum is 50)"
                                    onChange={this.setAmount}
                                    thousandSeparator={true}
                                    className="form-control"
                                  />
                                  <NumberFormat
                                    style={{
                                      borderBottom: "1px dashed #eee",
                                      display: "inline-block",
                                      marginTop: 10,
                                    }}
                                    value={truncate(
                                      this.context.user.available_bal,
                                      2
                                    )}
                                    className="text-blue"
                                    displayType={"text"}
                                    prefix={"Balance: NGN "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              data-toggle="modal"
                              data-target="#pinModal"
                              disabled={
                                !(
                                  this.state.account !== "invalid" &&
                                  !this.state.isEmpty &&
                                  this.state.amount_ok
                                )
                              }
                              type="submit"
                              className="col-sm-12 pl-0 btn signup-btn text-white"
                            >
                              Click to Transfer
                            </button>
                          </div>
                          <Pin
                            endpoint="/user/transfer/hometown"
                            data={transferHometownPayload}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="bank"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="modal-body">
                        <div className="card akawo-card">
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="formGroupExampleInput"
                                    className="profile-input-label text-muted"
                                  >
                                    Bank name
                                  </label>
                                  <SearchableBanks handleChange={this.handleBank} />
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="formGroupExampleInput"
                                    className="profile-input-label text-muted"
                                  >
                                    Account number
                                  </label>
                                  <input
                                    required
                                    type="number"
                                    onChange={this.setAccount}
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder="Enter account number"
                                  />
                                </div>
                                <div style={{ marginTop: 10 }}>
                                  <div
                                    style={{ marginTop: 0 }}
                                    className={
                                      "text-center " +
                                      (!this.state.is_account_ok
                                        ? "hide"
                                        : this.state.isVerifyingAccount
                                        ? "show"
                                        : "hide")
                                    }
                                  >
                                    <div
                                      className="spinner-border"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{ marginTop: 0 }}
                                    className={
                                      !this.state.is_account_ok
                                        ? "hide"
                                        : this.state.isVerifyingAccount
                                        ? "hide"
                                        : "show"
                                    }
                                  >
                                    <div
                                      className="item"
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <div className="text-center">
                                        {(() => {
                                          if (
                                            this.state.account2 === "invalid"
                                          ) {
                                            return (
                                              <div
                                                className="alert alert-warning"
                                                style={{ width: "100%" }}
                                              >
                                                <p className="text-warning mb-0">
                                                  <i className="fa fa-exclamation-circle" />
                                                  &nbsp;Could not verify account
                                                  details.
                                                </p>
                                              </div>
                                            );
                                          } else
                                            return (
                                              <div
                                                className="alert alert-success"
                                                style={{ width: "100%" }}
                                              >
                                                <p className="text-success mb-0">
                                                  <i className="fa fa-check" />
                                                  &nbsp;{this.state.account2}
                                                </p>
                                              </div>
                                            );
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="formGroupExampleInput"
                                    className="profile-input-label text-muted"
                                  >
                                    Amount
                                  </label>
                                  <NumberFormat
                                    allowNegative={false}
                                    placeholder="Amount (minimum is 50)"
                                    onChange={this.setAmount}
                                    thousandSeparator={true}
                                    className="form-control"
                                  />
                                  <NumberFormat
                                    style={{
                                      borderBottom: "1px dashed #eee",
                                      display: "inline-block",
                                      marginTop: 10,
                                    }}
                                    value={truncate(
                                      this.context.user.available_bal,
                                      2
                                    )}
                                    className="text-blue"
                                    displayType={"text"}
                                    prefix={"Balance: NGN "}
                                    suffix={", transfer fee of 10 NGN applies"}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              data-toggle="modal"
                              data-target="#pinModal2"
                              disabled={
                                !(
                                  this.state.account2 !== "invalid" &&
                                  this.state.is_account_ok &&
                                  this.state.amount_ok
                                )
                              }
                              type="submit"
                              className="col-sm-12 pl-0 btn signup-btn text-white"
                            >
                              Click to transfer
                            </button>
                          </div>
                          <Pin2
                            endpoint="/user/transfer/bank"
                            data={transferBankPayload}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Transfer.contextType = AppContext;
export default Transfer;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
