import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './assets/index.css';
import Navbar from '../../../Components/dashComponents/navbar';
import axios from 'axios';
import moment from 'moment';
import AppContext from '../../../AppContext'
import VerificationBar from '../../../Components/dashComponents/verification_bar'
import ActionBar from '../../../Components/dashComponents/action_bar'
import Pin from '../../../Components/dashComponents/pin'
import NumberFormat from 'react-number-format'
import iziToast from "izitoast";

class Dashboard extends Component {
    render() {

        return (
            <div className="bckgrd-color">
                <div>
                    <Navbar />
                    <div className="container">
                        <VerificationBar email_verified_at={this.context.user.email_verified_at}/>
                    </div>
                </div>
                <div className="bckgrd-color">
                    <div className="container dashbord-con">
                        {/*<ActionBar/>*/}
                        <div className="row">
                            <div className="col-sm-3"/>
                            <div className="col-sm-6">
                                <div className="card-bill card">
                                    <div className="card-body">
                                        <h4 style={{padding:30,paddingBottom:0}} className="text-center font-weight-bold">Complete payment</h4>
                                        <div style={{padding:20}} className="text-center">
                                            <p>You will be redirected to your internet banking for authorization</p>
                                            <a href={this.context.authUrl}>
                                                <button type="submit" className="btn btn-success text-white mt-2">Continue Payment</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Dashboard.contextType = AppContext;
export default Dashboard;