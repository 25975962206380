import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import Pin from "../../../Components/dashComponents/pin";
import nigeria from "./assets/img/nigeria.svg";
import dstv from "./assets/img/dstv.jpg";
import gotv from "./assets/img/gotv.jpg";
import startimes from "./assets/img/startimes.jpg";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";
import { MdDelete } from "react-icons/md";

let typingTimer,
  doneTypingInterval = 2000;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "dstv",
      iuc_number: null,
      phone_number: null,
      phone_ok: false,
      isSubmitting: false,
      isLoading: true,
      isVerifyingIuc: false,
      isEmpty: true,
      account: "invalid",
      dstv_subs: [],
      gotv_subs: [],
      startimes_subs: [],
      plan_options: [],
      key_code: "",
      beneficiary: false,
      checked: false,
      beneficiaries: [],
      beneficiary_type: "cable",
      save_as_beneficiary: false,
    };

    this.setPhone = this.setPhone.bind(this);
    this.setIuc = this.setIuc.bind(this);
  }
  handleChange = (e) => {
    this.setState({ checked: !this.state.checked }, () => {
      console.log(this.state.checked, "this.state.checked");
    });
  };

  deletePost = (beneficiary) => {
    axios
      .post("/user/remove_beneficiary", {
        token: localStorage.getItem("token"),
        beneficiary_type: beneficiary.type,
        beneficiary_number: beneficiary.number,
        // pin: pin,
      })
      .then((response) => {
        console.log(response, "removeBeneficiary");
      });
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    //get subscriptions
    let self = this;

    // get beneficiaries
    axios
      .post(ConstantVar.API_BASE_URL + "/user/beneficiaries", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        console.log(response, "dashboard beneficiaries");
        if (response.data.success === "true") {
          self.setState({
            beneficiaries: response.data.data.filter(item => item.type === "cable"),
            isLoading: false,
          });
        }
        console.log(this.state.beneficiaries.number, "empy");
      });

    //get dstv first
    axios
      .get(ConstantVar.API_BASE_URL + "/cable/subscriptions?provider=dstv")
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            dstv_subs: response.data.data,
            plan_options: response.data.data,
            isLoading: false,
          });
        }

        //get gotv
        axios
          .get(ConstantVar.API_BASE_URL + "/cable/subscriptions?provider=gotv")
          .then((response) => {
            if (response.data.success === "true") {
              self.setState({
                gotv_subs: response.data.data,
                isLoading: false,
              });
            }

            //get startimes
            axios
              .get(
                ConstantVar.API_BASE_URL +
                  "/cable/subscriptions?provider=startimes"
              )
              .then((response) => {
                if (response.data.success === "true") {
                  self.setState({
                    startimes_subs: response.data.data,
                    isLoading: false,
                  });
                }
              });
          });
      });
  }
  setIuc(e) {
    let iucNo = e.target.value.replace(/\s+/g, ""),
      self = this;

    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      //start loader
      self.setState({
        isVerifyingIuc: true,
        iuc_number: iucNo,
        isEmpty: !(iucNo.length > 0),
      });

      //verify eos account details
      axios
        .post(ConstantVar.API_BASE_URL + "/verify_iuc", {
          provider: self.state.selected,
          iuc_no: iucNo,
        })
        .then((response) => {
          //check response
          if (
            response.data.success === "true" &&
            response.data.data.cust_name !== undefined
          ) {
            //user found, load profile picture in picture-box
            let data = response.data.data;
            self.setState({
              isVerifyingIuc: false,
              account: data.cust_name,
            });
          } else {
            //notify user that account is invalid
            self.setState({
              isVerifyingIuc: false,
              account: "invalid",
            });
          }
        });
    }, doneTypingInterval);
  }
  setPhone(e) {
    let phoneNo = e.target.value.replace(/\s+/g, "");

    this.setState({
      phone_number: phoneNo,
      phone_ok: phoneNo.length === 11,
    });
  }
  render() {
    let options = this.state.plan_options,
      plans = [],
      placeholder = "";
    options.map((option, index) => {
      placeholder = index === 0 ? option.name : placeholder;
      plans.push({
        key: option.variation_code,
        value: option.variation_amount,
        text: option.name,
      });
    });

    let cablePayload = {
      provider: this.state.selected,
      iuc_no: this.state.iuc_number,
      phone_no: this.state.phone_number,
      sub_plan: this.state.key_code,
    };

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card-bill card">
                  <div className="card-body">
                    <h4
                      style={{ padding: 30 }}
                      className="text-center font-weight-bold"
                    >
                      Pay for TV Subscription
                    </h4>
                    <div style={{ padding: 20 }}>
                      <span>Select provider</span>
                      <div className="row mt-2" style={{ marginBottom: 15 }}>
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({
                                selected: "dstv",
                                plan_options: this.state.dstv_subs,
                              });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "dstv"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" src={dstv} />
                          </div>
                        </div>
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({
                                selected: "gotv",
                                plan_options: this.state.gotv_subs,
                              });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "gotv"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" src={gotv} />
                          </div>
                        </div>
                        <div className="col-sm-4 col-4">
                          <div
                            onClick={() => {
                              this.setState({
                                selected: "startimes",
                                plan_options: this.state.startimes_subs,
                              });
                            }}
                            className={
                              "cursor-pointer" +
                              (this.state.selected === "startimes"
                                ? " selected"
                                : "")
                            }
                          >
                            <img className="airtime-card" src={startimes} />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="mt-4">
                          Subscription plan
                        </label>
                        <div>
                          <select
                            onChange={(e) =>
                              this.setState({ key_code: e.target.value })
                            }
                            className="custom-select form-control form-control-pad"
                          >
                            <option hidden className="text-muted" selected>
                              Select subscription plan
                            </option>
                            {plans.map((plan, index) => {
                              return (
                                <option key={index} value={plan.key}>
                                  {plan.text}
                                </option>
                              );
                            })}
                          </select>
                          <NumberFormat
                            style={{
                              borderBottom: "1px dashed #eee",
                              display: "inline-block",
                              marginTop: 10,
                            }}
                            value={truncate(this.context.user.available_bal, 2)}
                            className="text-blue"
                            displayType={"text"}
                            prefix={"Balance: NGN "}
                            decimalScale={2}
                            thousandSeparator={true}
                          />
                        </div>
                        <div>
                          <label htmlFor="exampleInputEmail1" className="mt-4">
                            IUC number
                          </label>
                          <input
                            required
                            type="number"
                            name="iucNumber"
                            className="form-control login-input phone-input"
                            placeholder="Enter IUC number"
                            onChange={this.setIuc}
                          />
                          <div style={{ marginTop: 10 }}>
                            <div
                              style={{ marginTop: 0 }}
                              className={
                                "text-center " +
                                (this.state.isEmpty
                                  ? "hide"
                                  : this.state.isVerifyingIuc
                                  ? "show"
                                  : "hide")
                              }
                            >
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                            <div
                              style={{ marginTop: 0 }}
                              className={
                                this.state.isEmpty
                                  ? "hide"
                                  : this.state.isVerifyingIuc
                                  ? "hide"
                                  : "show"
                              }
                            >
                              <div
                                className="item"
                                style={{ marginTop: 0, marginBottom: 0 }}
                              >
                                <div className="text-center">
                                  {(() => {
                                    if (this.state.account === "invalid") {
                                      return (
                                        <div
                                          className="alert alert-warning"
                                          style={{ width: "100%" }}
                                        >
                                          <p className="text-warning mb-0">
                                            <i className="fa fa-exclamation-circle" />
                                            &nbsp;Could not verify IUC number.
                                          </p>
                                        </div>
                                      );
                                    } else
                                      return (
                                        <div
                                          className="alert alert-success"
                                          style={{ width: "100%" }}
                                        >
                                          <p className="text-success mb-0">
                                            <i className="fa fa-check" />
                                            &nbsp;{this.state.account}
                                          </p>
                                        </div>
                                      );
                                  })()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              style={{
                                width: 50,
                                backgroundColor: "white",
                                borderColor: this.state.phoneFocus
                                  ? "#495057"
                                  : "#eee",
                              }}
                            >
                              <img
                                src={nigeria}
                                style={{ width: "100%", marginLeft: 8 }}
                              />
                            </div>
                          </div>
                          <input
                            required
                            onClick={() => {
                              this.setState({ phoneFocus: true });
                            }}
                            onBlur={() => {
                              this.setState({ phoneFocus: false });
                            }}
                            type="number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.setPhone}
                            style={{ borderLeft: 0 }}
                            className="form-control login-input phone-input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Phone number"
                          />
                        </div>
                      </div>

                      <div>
                        <input
                          type="checkbox"
                          onChange={this.handleChange}
                          defaultChecked={this.state.checked}
                          style={{ marginRight: 10 }}
                        />
                        <label>Save as beneficiary</label>
                      </div>

                      <button
                        data-toggle="modal"
                        data-target="#cable_pinModal"
                        disabled={
                          !(
                            this.state.account !== "invalid" &&
                            this.state.phone_ok
                          )
                        }
                        type="submit"
                        className="col-sm-12 pl-0 btn signup-btn text-white"
                      >
                        Pay subscription now
                      </button>
                    </div>
                    {/* <Pin endpoint="/user/pay_cable" data={cablePayload} /> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
        {this.state.beneficiaries.length > 0 ? (
          <div className="col-sm-8 container dashbord-con">
            <p className="card-inner-pad-tran-text text-muted">
              Saved Beneficiaries
            </p>
            <table className="table  card-bill ">
              <tbody>
                {this.state.beneficiaries.map((beneficiary, index) =>
                 
                    <tr className="table-hover">
                      <td
                        className="table-dash-img text-center"
                        style={{ width: "17%" }}
                      >
                        <div className="tv-bckg">
                          <i className="fas fa-tv tv-color" />
                        </div>
                      </td>
                      <td>
                        <div style={{ marginTop: 1 }}>
                          <p>{beneficiary.number}</p>
                          <br />
                          <p className="table-dash-details2">
                            {beneficiary.name}{" "}
                          </p>
                        </div>
                      </td>
                      <td>
                        <button
                          data-toggle="modal"
                          type="submit"
                          onClick={() => this.setState({ beneficiary: true })}
                          data-target="#pinModal"
                          style={{ color: "red", border: "none" }}
                        >
                          <MdDelete />
                        </button>

                        <Pin
                          endpoint={
                            !this.state.beneficiary
                              ? "/user/pay_cable"
                              : "/user/remove_beneficiary"
                          }
                          data={
                            this.state.beneficiary
                              ? {
                                  beneficiary_type: beneficiary.type,
                                  beneficiary_number: beneficiary.number,
                                }
                              : {
                                  provider: this.state.selected,
                                  iuc_no: this.state.iuc_number,
                                  phone_no: this.state.phone_number,
                                  sub_plan: this.state.key_code,
                                  save_as_beneficiary: `${this.state.checked}`,
                                  use_loan_wallet: "false",
                                }
                          }
                        />
                      </td>
                    </tr>
                 
                )}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
