import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import ActionBar from "../../../Components/dashComponents/action_bar";
import Pin from "../../../Components/dashComponents/pin";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSendingOtp: false,
    };
    this.submitOtp = this.submitOtp.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  submitOtp(event) {
    //retrieve transaction reference from context
    let ref = this.context.txRef,
      self = this;

    //show loader
    this.setState({
      isSendingOtp: true,
    });

    console.log(event.target.otp.value);
    console.log(ref);

    //send otp to server
    axios
      .post(ConstantVar.API_BASE_URL + "/user/deposit/validate_otp", {
        txRef: ref,
        otp: event.target.otp.value,
      })
      .then(function (response) {
        //remove loader
        self.setState({
          isSendingOtp: false,
        });

        //check if deposit successful or failed
        if (response.data.message === "transaction complete") {
          //send user to payment successful page
          document.getElementById("gotoSuccess").click();
        } else {
          //error during validation
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    event.preventDefault();
  }
  render() {
    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card-bill card">
                  <div className="card-body">
                    <h4
                      style={{ padding: 30, paddingBottom: 0 }}
                      className="text-center font-weight-bold"
                    >
                      Enter OTP
                    </h4>
                    <div style={{ padding: 20 }} className="text-center">
                      <p>
                        An otp was sent to your mobile number, kindly input it
                        below
                      </p>
                      <form
                        className={this.state.isSendingOtp ? "loading" : ""}
                        onSubmit={this.submitOtp}
                      >
                        <NumberFormat
                          required
                          name="otp"
                          className="form-control"
                          placeholder="XXXXXXXX"
                        />
                        <button
                          type="submit"
                          className="btn btn-success text-white mt-3"
                        >
                          Verify OTP
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;
