import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import AppContext from "../AppContext";

class Policies extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar />
        <section>
          <div className="container">
            <div className="card all-card p-5">
              <h3 className="font-weight-bold">
                Privacy Policy for Hometown Fintech Limited
              </h3>
              <p>
                At Hometown App, accessible from https://gethometown.app/, one
                of our main priorities is the privacy of our visitors. This
                Privacy Policy document contains types of information that is
                collected and recorded by Hometown App and how we use it.
              </p>
              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Hometown App.
                This policy is not applicable to any information collected
                offline or via channels other than this website.
              </p>
              <h3 className="font-weight-bold">Consent</h3>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
              <h3 className="font-weight-bold">Information we collect</h3>
              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </p>
              <p>
                During the loan application process an applicant is required to
                upload proof of address in the form of a utility bill document,
                and we use this data to determine the eligibility for a loan.
              </p>
              <p>
                We access images and other information from your device's
                gallery and/or camera after granting required permissions in
                order to verify your identity by accessing your camera to take
                pictures or from your gallery to upload images. We use this
                information to provide features of Our Service and we may
                cross-check your identity with third parties. The information
                may be uploaded to the Company's servers and/or a Service
                Provider's server or it may be simply stored on Your device.
              </p>

              <p>
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </p>
              <p>
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>
              <h3 className="font-weight-bold">How we use your information</h3>
              <p>
                We use the information we collect in various ways, including to:
              </p>
              <ul>
                <li>a. Provide, operate, and maintain our webste</li>
                <li>b. Improve, personalize, and expand our webste</li>
                <li>c. Understand and analyze how you use our webste</li>
                <li>
                  d. Develop new products, services, features, and functionality
                </li>
                <li>
                  e. Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the webste, and for
                  marketing and promotional purposes
                </li>
                <li>f. Send you emails</li>
                <li>g. Find and prevent fraud</li>
                <li>
                  h. To determine whether to provide a loan to you, the amount
                  of such loan and the terms and conditions applicable to such
                  loan or for the provision of any of our product and services
                  to you.
                  <br />
                  Lawful basis- Contract performance, consent, legal obligation.
                </li>
                <li>
                  i. To investigate or resolve any complaint and issues you may
                  have.
                  <br />
                  Lawful basis- Contract performance, consent
                </li>
                <li>
                  j. To exercise our rights under contracts we have entered into
                  with you like recovering any payments due to us and where
                  necessary to enforcing recovery through debt collection
                  agencies or taking other legal action, including instituting
                  an action in the courts of law.
                  <br />
                  Lawful basis- Contract performance.
                </li>
                <li>
                  k. To fulfil our legal, compliance, regulatory and risk
                  management obligations.
                  <br />
                  Lawful basis- Legal obligation
                </li>
              </ul>
              <h3 className="font-weight-bold">
                Information collected when you use our services
              </h3>
              <p>
                When you use our services, we collect location data which allows
                us to determine your precise or approximate location. This
                information is collected during the loan application process,
                and we use this data to enhance our credit risk and underwriting
                framework, as well as to prevent fraud.
              </p>
              <p>
                When you use our services we collect contact list information
                saved on your smartphone. This information is collected during
                the loan application process, which serves as additional layer
                of safety security measure to detect and prevent fraud and or
                impersonation, and we use this data to determine eligibility for
                a loan.
              </p>
              <p>
                We collect this information through your mobile device operating
                system, by requesting your express consent to your device’s
                permissions on the App. The collection of this information may
                take place in the background even when you aren’t using our
                service, if the permission you gave us expressly permits such
                collection.
              </p>
              <h3 className="font-weight-bold">Log Files</h3>
              <p>
                Hometown App follows a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services' analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
                Our Privacy Policy was created with the help of the{" "}
                <a href="https://www.privacypolicygenerator.info">
                  Privacy Policy Generator
                </a>{" "}
                and the{" "}
                <a href="https://www.privacypolicytemplate.net/">
                  Privacy Policy Template
                </a>
                .
              </p>
              <h3 className="font-weight-bold">
                Advertising Partners Privacy Policies
              </h3>
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Hometown App.
              </p>
              <p>
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on Hometown App,
                which are sent directly to users' browser. They automatically
                receive your IP address when this occurs. These technologies are
                used to measure the effectiveness of their advertising campaigns
                and/or to personalize the advertising content that you see on
                websites that you visit.
              </p>
              <p>
                Note that Hometown App has no access to or control over these
                cookies that are used by third-party advertisers.
              </p>
              <h3 className="font-weight-bold">Third Party Privacy Policies</h3>
              <p>
                Hometown App's Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options. You
                may find a complete list of these Privacy Policies and their
                links here: Privacy Policy Links.
              </p>
              <p>
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites. What Are Cookies?
              </p>
              <h3 className="font-weight-bold">
                CCPA Privacy Rights (Do Not Sell My Personal Information)
              </h3>
              <p>
                Under the CCPA, among other rights, California consumers have
                the right to:
              </p>
              <p>
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </p>
              <p>
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </p>
              <p>
                Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
              <h3 className="font-weight-bold">GDPR Data Protection Rights</h3>
              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p>
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </p>
              <p>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </p>
              <p>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </p>
              <p>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </p>
              <p>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </p>
              <p>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
              <h3 className="font-weight-bold">Children's Information</h3>
              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>
              <p>
                Hometown App does not knowingly collect any Personal
                Identifiable Information from children under the age of 13. If
                you think that your child provided this kind of information on
                our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such
                information from our records.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
Policies.contextType = AppContext;
export default Policies;
