import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import img from "./assets/img/hometown-img.png";
import person from "./assets/img/person.jpg";
import AppContext from "../../AppContext";
import * as ConstantVar from "../../constants_var";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.logOut = this.logOut.bind(this);
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  //for sidebar on mobile
  openNav() {
    document.getElementById("mySidenav").style.width = "300px";
    setTimeout(function () {
      document.getElementById("back-drop").style.display = "block";
    }, 5000);
  }

  /* Set the width of the side navigation to 0 */
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    setTimeout(function () {
      document.getElementById("back-drop").style.display = "none";
    }, 200);
  }

  logOut() {
    localStorage.clear();

    window.location.href = "/";
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light navbar-bckgrd mt-0 mobile-hide">
          <div className="container">
            <Link className="navbar-brand" to="/" target="_blank">
              <img src={img} width="30" height="38" alt="" />
            </Link>
            <button
              className="navbar-toggler bars-icon"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars text-white bars-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto ml-auto">
                <li className="nav-item">
                  <Link
                    to="/dashboard/overview"
                    className={
                      "nav-link navbar-btn text-white" +
                      (window.location.href.includes("/overview")
                        ? " navbar-btn-active"
                        : "")
                    }
                    href="#"
                  >
                    Overview
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/dashboard/akawo"
                    className={
                      "nav-link navbar-btn text-white" +
                      (window.location.href.includes("/akawo")
                        ? " navbar-btn-active"
                        : "")
                    }
                  >
                    Akawo
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/dashboard/myloans"
                    className={
                      "nav-link navbar-btn text-white" +
                      (window.location.href.includes("/myloans")
                        ? " navbar-btn-active"
                        : "")
                    }
                    href="#"
                  >
                    My loans
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/dashboard/transactions"
                    className={
                      "nav-link navbar-btn text-white" +
                      (window.location.href.includes("/transactions")
                        ? " navbar-btn-active"
                        : "")
                    }
                    href="#"
                  >
                    Transactions
                  </Link>
                </li>
                {/*<li className="nav-item">
                                    <Link to="/cards" className={"nav-link navbar-btn text-white" + (window.location.href.includes('/cards')?" navbar-btn-active":"")} href="#">Cards</Link>
                                </li>*/}
                <li className="nav-item">
                  <Link
                    to="/dashboard/user/profile"
                    className={
                      "nav-link navbar-btn text-white" +
                      (window.location.href.includes("/user/profile")
                        ? " navbar-btn-active"
                        : "")
                    }
                    href="#"
                  >
                    Settings
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={
                      "nav-link navbar-btn text-white desktop-hide" +
                      (window.location.href.includes("/settings")
                        ? " navbar-btn-active"
                        : "")
                    }
                    href="#"
                  >
                    <i className="fas fa-sign-out-alt drop-icon" />
                    Sign out
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav float-right mobile-hide">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link navbar-btn-settings"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.context.user.fname} {this.context.user.lname}
                    <div className="navbar-brand navbar-pro-img">
                      <img
                        className="rounded-circle"
                        width="35"
                        height="35"
                        alt=""
                        src={
                          ConstantVar.API_BASE_URL +
                          "/static/profile_pics/" +
                          this.context.user.phone +
                          "/dp.png"
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = person;
                        }}
                      />
                    </div>
                  </a>
                  <div
                    className="dropdown-menu showDrop"
                    aria-labelledby="navbarDropdown"
                  >
                    <i className="fas fa-stop drop-arrow" />
                    <Link
                      to="/dashboard/user/profile"
                      className="dropdown-item dropdown-text"
                    >
                      <i className="fas fa-user-circle drop-icon" />
                      Profile
                    </Link>
                    <Link
                      to="/contact"
                      target="_blank"
                      className="dropdown-item dropdown-text"
                    >
                      <i className="fas fa-question-circle drop-icon" />
                      Help
                    </Link>
                    <Link
                      to="/faqs"
                      target="_blank"
                      className="dropdown-item dropdown-text"
                    >
                      <i className="fas fa-info-circle drop-icon" />
                      About Hometown
                    </Link>
                    <span
                      onClick={this.logOut}
                      className="dropdown-item dropdown-text"
                    >
                      <i className="fas fa-sign-out-alt drop-icon" />
                      Sign out
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light navbar-bckgrd mt-0 desktop-hide">
          <div className="container">
            <div className="row">
              <div className="col-2">
                <button
                  onClick={this.openNav}
                  className="navbar-toggler"
                  type="button"
                  aria-expanded="false"
                >
                  <i className="header-icon text-white fas fa-bars" />
                </button>
                <div
                  className="back-drop"
                  id="back-drop"
                  onClick={this.closeNav}
                />
                <div id="mySidenav" className="sidenav desktop-romove">
                  <a className="closebtn text-white" onClick={this.closeNav}>
                    &times;
                  </a>

                  <ul className="navbar-nav mr-auto ml-auto">
                    <li className="nav-item">
                      <Link
                        to="/dashboard/overview"
                        className={
                          "nav-link navbar-btn text-white" +
                          (window.location.href.includes("/overview")
                            ? " navbar-btn-active"
                            : "")
                        }
                        href="#"
                      >
                        Overview
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/akawo"
                        className={
                          "nav-link navbar-btn text-white" +
                          (window.location.href.includes("/akawo")
                            ? " navbar-btn-active"
                            : "")
                        }
                      >
                        Akawo
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/myloans"
                        className={
                          "nav-link navbar-btn text-white" +
                          (window.location.href.includes("/myloans")
                            ? " navbar-btn-active"
                            : "")
                        }
                        href="#"
                      >
                        My loans
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/transactions"
                        className={
                          "nav-link navbar-btn text-white" +
                          (window.location.href.includes("/transactions")
                            ? " navbar-btn-active"
                            : "")
                        }
                        href="#"
                      >
                        Transactions
                      </Link>
                    </li>
                    {/*<li className="nav-item">
                                                <Link to="/cards" className={"nav-link navbar-btn text-white" + (window.location.href.includes('/cards')?" navbar-btn-active":"")} href="#">Cards</Link>
                                            </li>*/}
                    <li className="nav-item">
                      <Link
                        to="/dashboard/user/profile"
                        className={
                          "nav-link navbar-btn text-white" +
                          (window.location.href.includes("/user/profile")
                            ? " navbar-btn-active"
                            : "")
                        }
                        href="#"
                      >
                        Settings
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={
                          "nav-link navbar-btn text-white desktop-hide" +
                          (window.location.href.includes("/settings")
                            ? " navbar-btn-active"
                            : "")
                        }
                        href="#"
                      >
                        <i className="fas fa-sign-out-alt drop-icon" />
                        Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <Link className="navbar-brand" to="/" target="_blank">
                <img
                  className="rounded-circle"
                  width="35"
                  height="35"
                  alt=""
                  src={
                    ConstantVar.API_BASE_URL +
                    "/static/profile_pics/" +
                    this.context.user.phone +
                    "/dp.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = person;
                  }}
                />
              </Link>
            </div>
            <div className="col-1" />
            <div className="col-6">
              <Link
                to="/dashboard/deposit/amount"
                className="btn btn-success text-white"
                style={{ float: "right", padding: "7%" }}
              >
                Take a loan
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.contextType = AppContext;
export default Navbar;
