import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import NumberFormat from "react-number-format";
import bet9ja from "./assets/img/bet9ja.jpeg";
import betking from "./assets/img/betking.jpeg";
import _1xbet from "./assets/img/1xbet.jpeg";
import betting from "./assets/img/betting.png";
import nairabet from "./assets/img/nairabet.jpeg";
import sportybet from "./assets/img/sportybet.png";
import nigeria from "./assets/img/nigeria.svg";
import Pin from "../../../Components/dashComponents/pin";
import axios from "axios";
import iziToast from "izitoast";

import * as ConstantVar from "../../../constants_var";
import { MdDelete } from "react-icons/md";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "bet9ja",
      bet_option: "",
      amount: "",
      customer_id: "",
      beneficiary: false,
      isLoading: false,
      checked: false,
      beneficiaries: [],
      beneficiary_type: "betting",
    };

    this.setAmount = this.setAmount.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.handleBetProvider = this.handleBetProvider.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios
      .post(ConstantVar.API_BASE_URL + "/user/beneficiaries", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        console.log(response, "dashboard beneficiaries");
        if (response.data.success === "true") {
          self.setState({
            beneficiaries: response.data.data.filter(item => item.type === "betting"),
            isLoading: false,
          });
        }
        console.log(this.state.beneficiaries.number, "empy");
      });
    let self = this;
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  deletePost = (beneficiary) => {
    axios
      .post("/user/remove_beneficiary", {
        token: localStorage.getItem("token"),
        beneficiary_type: beneficiary.type,
        beneficiary_number: beneficiary.number,
        // pin: pin,
      })
      .then((response) => {
        console.log(response, "removeBeneficiary");
      });
  };

  handleBetProvider = () => {
    const provider = this.state.selected;
    let paymentCode =
      provider === "nairabet"
        ? "04276809"
        : provider === "bet9ja"
        ? "04226901"
        : provider === "1xbet"
        ? "04296801"
        : provider === "betking"
        ? "04314801"
        : provider === "sportybet"
        ? "04347201"
        : "null";

    return paymentCode;
  };

  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      amount: amount,
      amount_ok: amount >= 500,
    });
  }

  setPhone(e) {
    let phoneNo = e.target.value.replace(/\s+/g, "");

    this.setState({
      phone_number: phoneNo,
      phone_ok: phoneNo.length === 11,
    });
  }

  validateCustomerID = async () => {
    const bettingData = {
      provider: this.state.selected,
      customer_id: this.state.customer_id,
      amount: this.state.amount,
      paymentCode: this.handleBetProvider(),
    };

    const { data } = await axios.post(
      `${ConstantVar.API_BASE_URL}/quickteller_validate_betting_customer`,
      bettingData
    );
    console.log(" data::", data);
    if (data.success !== "false") {
      this.setState({
        fullName: data.data.fullName,
        payment_code: data.data.paymentCode,
        isLoading: true,
      });
    } else {
      // console.log('got here')
      // alert("Unable to verify customer ID, try again later")
      iziToast.error({
        title: "Unable to verify customer ID, try again later",
        message: "",
        position: "center",
        theme: "dark",
        color: "red",
      });
    }
  };

  render() {
    var errorElement;

    if (parseFloat(this.state.amount) < 100) {
      errorElement = (
        <span className="text-danger">Enter NGN 100 at least</span>
      );
    } else if (
      this.state.selected === "nairabet" &&
      this.state.bet_option === "agent" &&
      parseFloat(this.state.amount) < 5000
    ) {
      errorElement = (
        <span className="text-danger">Enter NGN 5000 at least</span>
      );
    }

    var extraErrorElement;
    if (
      (this.state.selected === "nairabet" && this.state.bet_option === "") ||
      (this.state.selected === "_1xbet" && this.state.bet_option === "")
    ) {
      extraErrorElement = (
        <span className="text-danger p-1">Select One Option Type</span>
      );
    }

    let bettingPayload = {
      amount: this.state.amount,
      payment_code: this.state.payment_code?.toString(),
      customer_name: this.state.customer_name,
      service_provider: this.state.selected,
      betting_option: this.state.bet_option.toLowerCase(),
      customer_id: this.state.customer_id,
      save_as_beneficiary: this.state.beneficiary.toString(),
      phone_no: this.state.phone_number,
    };

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              {this.state.isLoading === false ? (
                <div className="col-sm-6">
                  <div className="card-bill card">
                    <div className="card-body">
                      <h4
                        style={{ padding: 30 }}
                        className="text-center font-weight-bold"
                      >
                        Fund Bet Account
                      </h4>
                      <div>
                        <hr className="fundbet-hr" />
                      </div>
                      <div style={{ padding: 20 }}>
                        <span>Select provider</span>
                        <div className="row mt-2" style={{ marginBottom: 15 }}>
                          <div className="col-sm-3 col-3">
                            <div
                              onClick={() => {
                                this.setState({
                                  selected: "bet9ja",
                                  bet_option: "",
                                });
                              }}
                              className={
                                "cursor-pointer" +
                                (this.state.selected === "bet9ja"
                                  ? " selected"
                                  : "")
                              }
                            >
                              <img className="airtime-card" src={bet9ja} />
                            </div>
                          </div>
                          <div className="col-sm-3 col-3">
                            <div
                              onClick={() => {
                                this.setState({
                                  selected: "betking",
                                  bet_option: "",
                                });
                              }}
                              className={
                                "cursor-pointer" +
                                (this.state.selected === "betking"
                                  ? " selected"
                                  : "")
                              }
                            >
                              <img className="airtime-card" src={betking} />
                            </div>
                          </div>
                          <div className="col-sm-3 col-3">
                            <div
                              onClick={() => {
                                this.setState({
                                  selected: "_1xbet",
                                  bet_option: "",
                                });
                              }}
                              className={
                                "cursor-pointer" +
                                (this.state.selected === "_1xbet"
                                  ? " selected"
                                  : "")
                              }
                            >
                              <img className="airtime-card" src={_1xbet} />
                            </div>
                          </div>
                          <div className="col-sm-3 col-3">
                            <div
                              onClick={() => {
                                this.setState({
                                  selected: "nairabet",
                                  bet_option: "",
                                });
                              }}
                              className={
                                "cursor-pointer" +
                                (this.state.selected === "nairabet"
                                  ? " selected"
                                  : "")
                              }
                            >
                              <img className="airtime-card" src={nairabet} />
                            </div>
                          </div>
                          <div className="col-sm-3 col-3">
                            <div
                              onClick={() => {
                                this.setState({
                                  selected: "sportybet",
                                  bet_option: "",
                                });
                              }}
                              className={
                                "cursor-pointer" +
                                (this.state.selected === "sportybet"
                                  ? " selected"
                                  : "")
                              }
                            >
                              <img className="airtime-card" src={sportybet} />
                            </div>
                          </div>
                        </div>
                        <div className="mb-2 font-weight-bold">
                          {(this.state.selected === "nairabet" ||
                            this.state.selected === "_1xbet") && (
                            <>
                              <label>Bet Option </label>
                              <select
                                className="custom-select form-control form-control-pad"
                                onChange={this.handleChange}
                                name="bet_option"
                              >
                                <option>Select One Bet Option</option>
                                {this.state.selected === "nairabet" && (
                                  <>
                                    <option value="player ">
                                      Nairabet Online Player
                                    </option>
                                    <option value="agent">
                                      Nairabet Shop Agent (NGN 5,000.00)
                                    </option>
                                  </>
                                )}
                                {this.state.selected === "_1xbet" && (
                                  <>
                                    <option value="repayment">Repayment</option>
                                    <option value="wallet_funding">
                                      Wallet Funding
                                    </option>
                                  </>
                                )}
                              </select>
                            </>
                          )}
                        </div>
                        {extraErrorElement} <br />
                        <label>Customer ID</label>
                        <input
                          className="form-control"
                          name="customer_id"
                          onChange={this.handleChange}
                          type="text"
                        />
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              style={{
                                width: 50,
                                backgroundColor: "white",
                                borderColor: this.state.phoneFocus
                                  ? "#495057"
                                  : "#eee",
                              }}
                            >
                              <img
                                src={nigeria}
                                style={{ width: "100%", marginLeft: 8 }}
                              />
                            </div>
                          </div>
                          <input
                            required
                            onClick={() => {
                              this.setState({ phoneFocus: true });
                            }}
                            onBlur={() => {
                              this.setState({ phoneFocus: false });
                            }}
                            type="number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.setPhone}
                            style={{ borderLeft: 0 }}
                            className="form-control login-input phone-input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Phone number"
                          />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                          <label>Enter Amount</label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder="Amount (minimum is NGN 100)"
                            onChange={this.setAmount}
                            thousandSeparator={true}
                            className="form-control"
                          />
                          {errorElement} <br />
                          <input
                            type="checkbox"
                            id="beneficiary"
                            name="beneficiary"
                            onChange={() => {
                              this.setState({ beneficiary: true });
                            }}
                          />{" "}
                          <label for="beneficiary">Save as beneficiary</label>
                          <br />
                          <NumberFormat
                            style={{
                              borderBottom: "1px dashed #eee",
                              display: "inline-block",
                              marginTop: 10,
                            }}
                            value={truncate(this.context.user.available_bal, 2)}
                            className="text-blue"
                            displayType={"text"}
                            prefix={"Balance: NGN "}
                            decimalScale={2}
                            thousandSeparator={true}
                          />
                        </div>
                        <button
                          disabled={
                            !this.state.amount || !this.state.customer_id
                          }
                          onClick={() => this.validateCustomerID()}
                          // className="col-sm-12 pl-0 btn signup-btn text-white"
                          className="col-sm-12 pl-0 btn signup-btn text-white"
                        >
                          Proceed
                        </button>
                        <h4>{this.state.isLoading}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-sm-6">
                  <div className="card-bill card">
                    <div className="card-body">
                      <h4
                        style={{ padding: 30 }}
                        className="text-center font-weight-bold"
                      >
                        Confirm Betting
                      </h4>
                      <div>
                        <p>
                          Customer name:{" "}
                          <strong>{this.state.customer_name}</strong>
                        </p>
                        <p>
                          Customer ID: <strong>{this.state.customer_id}</strong>
                        </p>
                        <p>
                          Amount: <strong>{this.state.amount}</strong>
                        </p>
                        <p>
                          Betting Company:{" "}
                          <strong>{this.state.selected.toUpperCase()}</strong>
                        </p>
                      </div>
                      <div>
                        <button
                          data-toggle="modal"
                          data-target="#pinModal"
                          disabled={
                            !this.state.amount && !this.state.customer_id
                          }
                          className="col-sm-12 pl-0 btn btn-success text-white"
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <Pin
                    endpoint="/quickteller/pay_betting"
                    data={bettingPayload}
                  /> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.beneficiaries.length > 0 ? (
          <div className="col-sm-8 container dashbord-con">
            <p className="card-inner-pad-tran-text text-muted">
              Saved Beneficiaries
            </p>
            <table className="table  card-bill">
              <tbody>
                {this.state.beneficiaries.map((beneficiary, index) => (
                  <tr className="table-hover">
                    <td
                      className="table-dash-img text-center"
                      style={{ width: "17%" }}
                    >
                      <div className="phone-bckg">
                        <i className="fas fa-money-bill-alt phone-color" />
                      </div>
                    </td>
                    <td>
                      <div style={{ marginTop: 1 }}>
                        <p>{beneficiary.number}</p>
                        <br />
                        <p className="table-dash-details2">
                          {beneficiary.name}{" "}
                        </p>
                      </div>
                    </td>
                    <td>
                      <button
                        data-toggle="modal"
                        type="submit"
                        onClick={() => this.setState({ beneficiary: true })}
                        data-target="#pinModal"
                        style={{ color: "red", border: "none" }}
                      >
                        <MdDelete />
                      </button>

                      <Pin
                        endpoint={
                          !this.state.beneficiary
                            ? "/quickteller/pay_betting"
                            : "/user/remove_beneficiary"
                        }
                        data={
                          this.state.beneficiary
                            ? {
                                beneficiary_type: beneficiary.type,
                                beneficiary_number: beneficiary.number,
                              }
                            : {
                                bettingPayload,
                                save_as_beneficiary: `${this.state.checked}`,
                                use_loan_wallet: "false",
                              }
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}

Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}
