import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../../constants_var";

class Myloans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      unpaid: 0,
      isLoading: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/user/loans", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            isLoading: false,
            loans: response.data.data,
            unpaid: response.data.total_unpaid,
          });
        }
      });
  }

  render() {
    return (
      <div>
        <Navbar />
        <div>
          <div className="container dashbord-con">
            <div className="row">
              <div className="col-sm-12">
                <h4>
                  My Loans
                  <button
                    className="pull-right btn btn-success"
                    style={{ float: "right" }}
                  >
                    Take loan
                  </button>
                </h4>
                <hr />
                {(() => {
                  if (this.state.isLoading)
                    return (
                      <div style={{ marginTop: 0 }} className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    );
                  else
                    return (
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-body text-center">
                              <small className="text-muted d-block">
                                Total Unpaid Loan
                              </small>
                              <hr />
                              <h5 style={{ padding: 20 }}>
                                <NumberFormat
                                  style={{ fontSize: 30, padding: 20 }}
                                  value={this.state.unpaid}
                                  className="card-bal-your"
                                  displayType={"text"}
                                  prefix={"NGN "}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                />
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-9">
                          <div className="card">
                            {(() => {
                              if (this.state.loans.length === 0)
                                return (
                                  <div className="p-5">
                                    <div className="text-center">
                                      <img />
                                      <h6>
                                        You have no loans, apply for one to
                                        begin
                                      </h6>
                                      <Link
                                        to="/dashboard/deposit/amount"
                                        className="btn btn-success text-white"
                                      >
                                        Take a loan
                                      </Link>
                                    </div>
                                  </div>
                                );
                              else {
                                return (
                                  <div>
                                    {this.state.loans.map((loan, index) => {
                                      return (
                                        <div
                                          className="loan-card p-4"
                                          key={index}
                                        >
                                          <div className="row">
                                            <div className="col-sm-1">
                                              <small className="text-muted">
                                                Status
                                              </small>
                                              <div>
                                                {loan.status === 1 ? (
                                                  <i className="fa text-success fa-dot-circle" />
                                                ) : loan.status === 2 ? (
                                                  <i className="fa text-danger fa-dot-circle" />
                                                ) : (
                                                  <i className="fa text-muted fa-dot-circle" />
                                                )}
                                              </div>
                                            </div>
                                            <div className="col-sm-2">
                                              <small className="text-muted">
                                                Amount
                                              </small>
                                              <p>
                                                <NumberFormat
                                                  value={loan.requested_amount}
                                                  className="font-weight-bold"
                                                  displayType={"text"}
                                                  prefix={"NGN "}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                />
                                              </p>
                                            </div>
                                            <div className="col-sm-2">
                                              <small className="text-muted">
                                                Repayment Amount
                                              </small>
                                              <p>
                                                <NumberFormat
                                                  value={loan.repayment_amount}
                                                  className="font-weight-bold"
                                                  displayType={"text"}
                                                  prefix={"NGN "}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                />
                                              </p>
                                            </div>
                                            <div className="col-sm-2">
                                              <small className="text-muted">
                                                Duration
                                              </small>
                                              <p className="font-weight-bold">
                                                {loan.duration} days
                                              </p>
                                            </div>
                                            <div className="col-sm-2">
                                              <small className="text-muted">
                                                Rate
                                              </small>
                                              <p className="font-weight-bold">
                                                {loan.rate}%
                                              </p>
                                            </div>
                                            <div className="col-sm-2">
                                              <small className="text-muted">
                                                Due Date
                                              </small>
                                              <p className="font-weight-bold">
                                                {moment(loan.due_at).format(
                                                  "MMM Do YYYY"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            })()}
                          </div>
                        </div>
                      </div>
                    );
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Myloans;
