import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";
import Verify from "./verify";
import Success from "./success";
import Failed from "./failed";

class Pay extends React.Component {
  render() {
    //get buy stage

    let stage = this.props.match.params.stage;

    if (stage === "verify") {
      return (
        <div>
          <Verify />
          <Link id="gotoError" to="/payment/failed" type="hidden" />
          <Link id="gotoSuccess" to="/payment/success" type="hidden" />
        </div>
      );
    } else if (stage === "success") {
      return <Success />;
    } else if (stage === "failed") {
      return <Failed />;
    }
  }
}
export default Pay;
