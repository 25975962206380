import React from 'react'
import Airtime from './airtime'
import Data from './data'
import Cable from './cable'
import Electricity from './electricity'
import Betting from './betting'
import Waec from './waec'
import Smile from './smile'

class Servies extends React.Component{

    constructor(props){
        super(props);

    }
    render(){
        let service = this.props.match.params.service;

        if (service === 'airtime')
            return(
                <Airtime/>
            );
        else if (service === 'betting')
            return(
                <Betting/>
            );
        else if (service === 'data')
            return(
                <Data/>
            );
        else if (service === 'cable')
            return(
                <Cable/>
            );
        else if (service === 'electricity')
            return(
                <Electricity/>
            )
        else if (service === "waec") return <Waec />;
        else if (service === "smile") return <Smile />;
    }
}
export default Servies