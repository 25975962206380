import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import ReactCodeInput from "react-code-input";
import axios from "axios";
import iziToast from "izitoast";
import * as ConstantVar from "../../constants_var";

class Action_Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      isSubmitting: false,
    };

    this.submitForm = this.submitForm.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
  }

  handlePinChange(evt) {
    this.setState({ pin: evt });
  }
  submitForm(e) {
    e.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    let self = this,
      url = ConstantVar.API_BASE_URL + "",
      data = this.props.data;
    data.token = localStorage.getItem("token");
    data.pin = this.state.pin;

    axios.post(url + this.props.endpoint, data).then((response) => {
      self.setState({
        isSubmitting: false,
      });

      if (response.data.success === "true") {
        //show success message
        iziToast.success({
          title:
            response.data.message.charAt(0).toUpperCase() +
            response.data.message.slice(1),
          message: "",
          position: "topRight",
          theme: "light",
          color: "green",
        });

        setTimeout(() => {
          //go to dashboard
          window.location.href = "/dashboard/overview";
        }, 100);
      } else {
        //show error message
        iziToast.error({
          title:
            response.data.message.charAt(0).toUpperCase() +
            response.data.message.slice(1),
          message: "",
          position: "topRight",
          theme: "light",
          color: "yellow",
        });
      }
    });
  }
  render() {
    return (
      <div
        className="modal fade"
        id="pinModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form onSubmit={this.submitForm}>
              <div className="modal-header bckgrd-color">
                <h5 className="modal-title" id="exampleModalLabel">
                  Verify Transaction
                </h5>
                <button
                  type="button"
                  className="close akawo-modal-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body bckgrd-color">
                <div className="card akawo-card">
                  <label>Enter Pin</label>
                  <ReactCodeInput
                    type="password"
                    inputMode="number"
                    fields={4}
                    value={this.state.pin}
                    onChange={this.handlePinChange}
                  />
                </div>
              </div>
              <div className="modal-footer bckgrd-color">
                <button
                  disabled={this.state.isSubmitting}
                  type="submit"
                  className="btn signup-btn text-white m-auto"
                >
                  Complete Transaction
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Action_Bar;
