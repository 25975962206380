import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import transimg from "./assets/img/transactions.png";
import ReactLoading from "react-loading";
import NumberFormat from "react-number-format";
import * as ConstantVar from "../../../constants_var";

import ReactPaginate from "react-paginate";

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      isLoading: true,
      perPage: 30,
      offset: 0,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTransactions()
  }
  
  getTransactions = () => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/user/web_transactions", {
        token: localStorage.getItem("token"),
        current_page: this.state.currentPage,
        per_page: this.state.perPage,
      })
      .then((res) => {
        console.log("data:: ", res.data);

        if (res.data.success === "true") {
          self.setState({
            transactions: res.data.data.data,
            isLoading: false,
            _from: res.data.data.pagination.from,
            _to: res.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = res.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: res.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getTransactions();
    });
  };
  

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div>
        <Navbar />
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-inner-pad-tran your-transactions">
                  <p className="card-inner-pad-tran-text text-muted your-transactions-txt">
                    Transaction history
                  </p>
                
                  {(() => {
                    if (this.state.isLoading)
                      return (
                        <div className="row">
                          <div className="col-sm-5" />
                          <div className="col-sm-2">
                            <ReactLoading
                              type="spin"
                              color="#fffff"
                              className="spin-s"
                            />
                            <div className="col-sm-5" />
                          </div>
                        </div>
                      );
                    else
                      return (
                        <table className="table">
                          <tbody>
                            {this.state.transactions.length > 0 ? (
                              <div>
                                <div className="pl-2 ml-5 ">
                                  {paginationElement}
                                </div>
                                {this.state.transactions.map((trx, index) => {
                                  return (
                                    <tr className="table-hover" key={trx.id}>
                                      <td
                                        className="table-dash-img text-start"
                                        style={{ width: "17%" }}
                                      >
                                        {trx.slug === "wallet_deposit" ? (
                                          <div className="wallet-bckg">
                                            <i className="fas fa-wallet wallet-color" />
                                          </div>
                                        ) : trx.slug === "airtime_recharge" ? (
                                          <div className="phone-bckg">
                                            <i className="fas fa-phone phone-color" />
                                          </div>
                                        ) : trx.slug === "pay_cable" ? (
                                          <div className="tv-bckg">
                                            <i className="fas fa-tv tv-color" />
                                          </div>
                                        ) : trx.slug === "tv_subscription" ? (
                                          <div className="phone-bckg">
                                            <i className="fas fa-phone phone-color" />
                                          </div>
                                        ) : trx.slug === "pay_electric" ? (
                                          <div className="phone-bckg">
                                            <i className="fas fa-bolt phone-color" />
                                          </div>
                                        ) : trx.slug === "fund_bet" ? (
                                          <div className="phone-bckg">
                                            <i className="fas fa-money-bill-alt phone-color" />
                                          </div>
                                        ) : trx.slug === "transfer" ? (
                                          <div className="phone-bckg">
                                            <i className="fas fa-money-bill-alt phone-color" />
                                          </div>
                                        ) : trx.slug === "loan" ? (
                                          <div className="phone-bckg">
                                            <i className="fas fa-money-bill-alt phone-color" />
                                          </div>
                                        ) : (
                                          <p className="reverse-bckg">
                                            Hometown
                                          </p>
                                        )}
                                      </td>
                                      <td
                                        className="table-dash  text-center"
                                        style={{ width: "17%" }}
                                      >
                                        <h6 className="text-muted trans-date">
                                          {moment(trx.created_at).format(
                                            "MMM Do, YYYY"
                                          )}
                                        </h6>{" "}
                                        <br />
                                      </td>
                                      <td>
                                        <div>
                                          <p className="font-weight-bold table-dash-details1 table-trans-details1">
                                            {trx.type}
                                          </p>{" "}
                                          <br />
                                          <p className="table-dash-details2">
                                            {trx.description}{" "}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        {trx.direction === "credit" ? (
                                          <span className="float-right trans-credit-color font-weight-normal">
                                            <NumberFormat
                                              value={trx.amount}
                                              className="card-bal-your"
                                              displayType={"text"}
                                              prefix={"+ NGN "}
                                              decimalScale={0}
                                              thousandSeparator={true}
                                            />
                                          </span>
                                        ) : (
                                          <span className="float-right trans-debit-color font-weight-normal">
                                            <NumberFormat
                                              value={trx.amount}
                                              className="card-bal-your"
                                              displayType={"text"}
                                              prefix={"- NGN "}
                                              decimalScale={0}
                                              thousandSeparator={true}
                                            />
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <div className="p-5 ml-5">
                                  {paginationElement}
                                </div>
                              </div>
                            ) : (
                              <tr>
                                <td>
                                  <div className="text-center no-data full-width-data">
                                    <img
                                      className="no-trans-overview"
                                      src={transimg}
                                    />
                                    <p className="text-muted no-trans-text-o">
                                      No transaction done yet!
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default Transactions;
