import React from 'react'
import Email from './Verification/email'
import Phone from './Verification/phone'
import Success from './Verification/success'
import Failure from './Verification/failed'


class Verification extends React.Component{
  
    constructor(props){
        super(props);

    }
    render(){
        let stage = this.props.match.params.stage;
        let token = this.props.match.params.token;

        if (stage === 'email')
            return(
                <Email token={token}/>
            );
        else if (stage === 'success')
            return(
                <Success/>
            );
        else if (stage === 'failed')
            return(
                <Failure/>
            )
    }
}
export default Verification