import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import ActionBar from "../../../Components/dashComponents/action_bar";
import Pin from "../../../Components/dashComponents/pin";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaying: false,
      depositAmount: "",
      charge: "",
      cardNo: "",
      netAmount: "",
      expirymonth: "",
      expiryyear: "",
      cvv: null,
      pin: null,
      selected_card: null,
      new_card: false,
      save_card: "true",
      isSaved: true,
    };
    this.submitDeposit = this.submitDeposit.bind(this);
    this.changeCardPin = this.changeCardPin.bind(this);
    this.changeCardNo = this.changeCardNo.bind(this);
    this.changeCardDate = this.changeCardDate.bind(this);
    this.changeCardCvv = this.changeCardCvv.bind(this);
    this.handleSaveCard = this.handleSaveCard.bind(this);
  }

  submitDeposit(event) {
    //send amount to server for billing
    let stateVariables = this.state;

    this.setState({
      isPaying: true,
    });

    //prepare payload
    let payload = {
        cardno: stateVariables.cardNo,
        cvv: stateVariables.cvv,
        expirymonth: stateVariables.expirymonth,
        expiryyear: stateVariables.expiryyear,
        amount: parseFloat(this.context.amount),
        token: localStorage.getItem("token"),
        pin: stateVariables.pin,
        firstname: "",
        lastname: "",
        save_card: this.state.isSaved ? "true" : "false",
      },
      self = this;

    //send payload to charge api
    axios
      .post(ConstantVar.API_BASE_URL + "/user/deposit", payload)
      .then(function (response) {
        //remove loading
        self.setState({
          isPaying: false,
        });

        //checks returned message
        if (response.data.message === "otp required") {
          //save transaction reference to context
          self.context.updateTxRef(response.data.data.txRef);

          //send user to finalise page
          document.getElementById("gotoOtp").click();
        } else if (response.data.message === "auth url returned") {
          //save transaction auth url to context
          self.context.updateAuthUrl(response.data.data.authUrl);

          //send user to finalize page
          document.getElementById("gotoAuthUrl").click();
        } else if (response.data.success === "token_failure") {
          //problem with token

          //show message
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });

          setTimeout(function () {
            window.location = "/signin";
          }, 1000);
        } else {
          //show user error
          iziToast.error({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "yellow",
          });
        }
      });

    event.preventDefault();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSaveCard(event) {
    this.setState({
      isSaved: event.target.checked,
    });
  }
  changeCardPin(event) {
    //strips all format from card no
    let cardPin = event.target.value;

    this.setState({
      pin: cardPin,
    });
  }
  changeCardNo(event) {
    //strips all format from card no
    let cardNo = event.target.value.replace(/\s+/g, "");

    this.setState({
      cardNo: cardNo,
    });
  }
  changeCardDate(event) {
    //strips all format from date and separates them
    let dateArray = event.target.value.replace(/\s+/g, "").split("/"),
      month = dateArray[0],
      year = dateArray[1];

    this.setState({
      expirymonth: month,
      expiryyear: year,
    });
  }
  changeCardCvv(event) {
    this.setState({
      cvv: event.target.value,
    });
  }
  render() {
    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card-bill card">
                  <div className="card-body">
                    <h4
                      style={{ padding: 30 }}
                      className="text-center font-weight-bold"
                    >
                      Deposit Funds
                    </h4>
                    <div style={{ padding: 20 }}>
                      <div style={{ marginBottom: 20 }}>
                        <form
                          autoComplete="off"
                          className={
                            "ui form " + (this.state.isPaying ? "loading" : "")
                          }
                          onSubmit={this.submitDeposit}
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <label>Card Number</label>
                              <NumberFormat
                                className="form-control"
                                required
                                onChange={this.changeCardNo}
                                placeholder="Card Number"
                                format="####  ####  ####  #### ###"
                              />
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <div className="col-sm-4">
                              <label>Expiration Date</label>
                              <NumberFormat
                                className="form-control"
                                required
                                onChange={this.changeCardDate}
                                placeholder="MM/YY"
                                mask={["", "", "", ""]}
                                format="## / ##"
                              />
                            </div>
                            <div className="col-sm-4">
                              <label>CVV Code</label>
                              <NumberFormat
                                className="form-control"
                                value={this.state.cvv}
                                required
                                onChange={this.changeCardCvv}
                                placeholder="e.g 111"
                                format="###"
                              />
                            </div>
                            <div className="col-sm-4">
                              <label>Pin</label>
                              <NumberFormat
                                className="form-control"
                                type="password"
                                autocomplete="new-password"
                                onChange={this.changeCardPin}
                                placeholder="XXXX"
                                format="####"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-sm-12"
                              style={{
                                paddingLeft: 40,
                                marginBottom: 20,
                                marginTop: 20,
                              }}
                            >
                              <input
                                checked={this.state.isSaved}
                                onChange={this.handleSaveCard}
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gridCheck1"
                              >
                                Save card for next time
                              </label>
                            </div>
                          </div>
                          <button
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            disabled={
                              !(this.context.amount_ok && !this.state.isPaying)
                            }
                            className="col-sm-12 pl-0 btn signup-btn text-white"
                          >
                            Pay now
                          </button>
                          <small
                            className="text-center d-inline-block w-100"
                            style={{ display: "inline-block" }}
                          >
                            <i className="fa fa-lock" />
                            &nbsp;&nbsp;Payment secured by Rave
                          </small>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;
