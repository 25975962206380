import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import About from "./Pages/about";
import Contact from "./Pages/contact";
import FAQs from "./Pages/faqs";
import Login from "./Pages/signin";
import Signup from "./Pages/signup.js";
import Verification from "./Pages/verification.js";
import Terms from "./Pages/terms";
import Policies from "./Pages/policies";
import Payments from "./Pages/Payment/index";
import Dashboard from "./Pages/dashboardPages/DashboardHome/index.js";
import Akawo from "./Pages/dashboardPages/Akawo/index.js";
import Services from "./Pages/dashboardPages/Services/index.js";
// import Okra from "./Pages/dashboardPages/Okra/index.js";
import Myloans from "./Pages/dashboardPages/Myloan/index.js";
import Deposit from "./Pages/dashboardPages/Deposit/index.js";
import Transfer from "./Pages/dashboardPages/Transfer/index.js";
import Invest from "./Pages/dashboardPages/Invest/index.js";
import Transactions from "./Pages/dashboardPages/Transactions/index.js";
import Cards from "./Pages/dashboardPages/Cards/index.js";
import Settings from "./Pages/dashboardPages/Settings/index.js";
import Profile from "./Pages/dashboardPages/Profile/index.js";
import PasswordRecovery from "./Pages/Recovery/index";
import PasswordRecoverySent from "./Pages/Recovery/sent";
import PasswordRecoveryUpdate from "./Pages/Recovery/update";
import PasswordRecoveryFailed from "./Pages/Recovery/failed";
import PasswordRecoveryToken from "./Pages/Recovery/token";
import axios from "axios";
import AppContext from "./AppContext";
import NotFound from "./NotFound";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import * as ConstantVar from "./constants_var";

loadProgressBar();
class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      isLoading: true,
      logginStatus: true,
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    window.addEventListener("onunload", this.endSession);

    this.setTimeout();
  }

  endSession(e) {
    localStorage.clear();
  }

  resetTimeout(e) {
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    alert("You will be logged out automatically in 1 minute.");
  }

  logout() {
    localStorage.clear();
    window.location.href = "/";
    this.setState({ logginStatus: false });
    // this.destroy(); // Cleanup
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }
  
  setTimeout() {
    if (window.location.href.includes("/dashboard")) {
      this.warnTimeout = setTimeout(this.warn, 280 * 10000);

      this.logoutTimeout = setTimeout(this.logout, 300 * 10000);
    } else {
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let self = this;

    axios
      .post(ConstantVar.API_BASE_URL + "/user/getinfo", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            user: response.data.data,
            isLoading: false,
          });
        } else {
          if (window.location.href.includes("dashboard"))
            //invalid
            window.location.href = "/login/account";
        }
      });
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact/:token" component={Contact} />
          <Route path="/faqs" component={FAQs} />
          <Route path="/login/:stage" component={Login} />
          <Route path="/signup/:stage" component={Signup} />
          <Route
            path="/account/verification/:stage/:token"
            component={Verification}
          />
          <Route path="/terms" component={Terms} />
          <Route path="/policies" component={Policies} />
          <Route path="/payment/:stage" component={Payments} />
          <Route path="/account/recovery" component={PasswordRecovery} />
          <Route path="/account/sent" component={PasswordRecoverySent} />
          <Route
            path="/account/verify/failed"
            component={PasswordRecoveryFailed}
          />
          <Route path="/account/update" component={PasswordRecoveryUpdate} />
          <Route
            path="/account/token/:token"
            component={PasswordRecoveryToken}
          />
          <Route path="/dashboard/overview" component={Dashboard} />
          <Route path="/dashboard/akawo" component={Akawo} />
          <Route path="/dashboard/deposit/:stage" component={Deposit} />
          <Route path="/dashboard/transfer" component={Transfer} />
          <Route path="/dashboard/invest" component={Invest} />
          <Route path="/dashboard/myloans" component={Myloans} />
          <Route path="/dashboard/transactions" component={Transactions} />
          <Route path="/dashboard/cards" component={Cards} />
          <Route path="/dashboard/settings" component={Settings} />
          <Route path="/dashboard/user/profile" component={Profile} />
          <Route path="/dashboard/services/:service" component={Services} />
          {/* <Route path="/dashboard/okra/" component={Okra} /> */}

          <Route component={NotFound} />
        </Switch>
      </AppContext.Provider>
    );
  }
}
export default Routes;
