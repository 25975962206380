import React from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import "./homeAssets/home.css";
import Easy from "./homeAssets/easy.png";
import Fast from "./homeAssets/fast.png";
import Reliable from "./homeAssets/reliable.png";
import loanApproval from "./homeAssets/loanApproval.png";
import payBill from "./homeAssets/payBills.png";
import secure from "./homeAssets/secureTransaction.png";
import support from "./homeAssets/support.png";
import wave from "./homeAssets/wave-sec-3.svg";
import dot from "./homeAssets/dot-grid-sec-2.svg";
import googleButton from "./homeAssets/googleButton.png";
import appleButton from "./homeAssets/appleButton.png";
import large2 from "./homeAssets/cover_img.png";

import phone from "./homeAssets/phone.jpg";
import invest from "./homeAssets/invest.png";
import AppContext from "./AppContext";
import Slider from "react-slick";
import quote from "./homeAssets/quote-icon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

class Home extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div style={{ backgroundColor: "white" }} className="home-section">
        <Navbar />
        <section className="first-section">
          <div className="container">
            <div className="d-flex align-items-center ">
              <div className="col-5 col-xs-12 mt-n5 col-sm-7">
                <div className="">
                  <h2 className="head-text">
                    Instant <span className="text-success">Loans.</span>
                    <br /> Pay Bills.
                    <br /> Your Financial{" "}
                    <span className="text-success">Lifeline.</span>
                  </h2>

                  <p className="head-para">
                    Access instant loans up to N5m loan without any collateral
                  </p>

                  <p>
                    You no longer have to forfeit or let go of your business
                    idea because of lack of Collacteral on a different platform.
                  </p>
                </div>
                <div className="row">
                  <div
                    className="col-5 col-sm-3"
                    style={{ marginRight: "inherit" }}
                  >
                    <a className="">
                      <img
                        onClick={() => {
                          window.location.href =
                            "https://play.google.com/store/apps/details?id=com.hometown.app&hl=en";
                        }}
                        className="google-btn"
                        src={googleButton}
                        alt="googleButton"
                      />
                    </a>
                  </div>

                  <div className="col-5 col-sm-3">
                    <a className="">
                      <img
                        onClick={() => {
                          window.location.href =
                            "https://apps.apple.com/us/app/hometown-app/id1525335534?app=itunes&ign-mpt=uo%3D4";
                        }}
                        className="google-btn"
                        src={appleButton}
                        alt="appleButton"
                      />
                    </a>
                  </div>

                  <div className="col-sm-3" />
                </div>
              </div>

              <div className="col-2"/>
              <div className="col-5  col-sm-5 ml-4 pay">
                <img
                  className=" float-right phone-img"
                  src={large2}
                  height={"300%"}
                  width={"200%"}
                  alt="paymentIcon"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="third-section ">
          <div className="container">
            <div  className="section-2-header">
              <p className="">
                Why do you have to
                <span className="text-success"> choose</span>
                <br />
                when you can have <span className="text-success">ALL</span>
              </p>
            </div>
            <div className="d-flex">
              <div
                className="p-3 m-2 rounded d-flex flex-column justify-content-center "
                style={{ backgroundColor: "#9AD3CC" }}
              >
                <div className=" ">
                  Get approved for loans instantly <br /> without the need for
                  collateral.
                </div>

                <img
                  src={loanApproval}
                  width={"60%"}
                  className="align-self-end"
                  height={"40%"}
                  alt="instant loan approval"
                />

                <h6 className="p-3 h4">
                  Instant Loan <br />
                  Approval
                </h6>
              </div>

              <div
                className="p-3 m-2 rounded d-flex flex-column justify-content-center "
                style={{ backgroundColor: "#EEEDEA" }}
              >
                <div className="">
                  Conveniently pay your utility
                  <br /> bills, mobile recharges, and more.
                </div>
                <img
                  src={payBill}
                  className="align-self-end"
                  width={"60%"}
                  height={"40%"}
                  alt="bill payments"
                />
                <h6 className="p-3 h4">
                  Bill <br />
                  Payments
                </h6>
              </div>

              <div
                className="p-3 m-2 rounded d-flex flex-column justify-content-center "
                style={{ backgroundColor: "#E8CAA2" }}
              >
                <div className="">
                  Ensure all your financial transactions
                  <br /> are secure and encrypted.
                </div>
                <img
                  src={secure}
                  width={"60%"}
                  height={"40%"}
                  className="align-self-end"
                  alt="secure transactions"
                />
                <h6 className="p-3 h4">
                  Secure <br />
                  Transactions
                </h6>
              </div>
            </div>
          </div>
        </section>

         <section className="second-section mt-5">
          <div className="container ">
            <div className="row ">
              <div className="text-light col-4">
                <div className="text1 text-light">What we stand for</div>
                <p>
                  We believe in fast, secure and ease of use for
                  <br /> our customers
                </p>

                <button className="btn border-white text-light">
                  Get Started
                </button>
              </div>

              <div className="row col-8 col-sm-8">
                <div className="col-12 col-sm-4">
                  <div className="card all-card">
                    <div className="text-center">
                      <a className="">
                        <img className="pic-1-sec3" src={Fast} alt="" />
                      </a>
                    </div>
                    <div className="text-center">
                      <h4>Fast</h4>
                      <p>
                      Quickly access short-term low interest <br /> loans with
                        no collecteral now
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card all-card">
                    <div className="text-center">
                      <a className="">
                        <img className="pic-2-sec3" src={Easy} alt="" />
                      </a>
                    </div>
                    <div className="text-center">
                      <h4>Easy</h4>
                      <p>
                      Easy-to-use interface for a seamless <br /> experience for
                        all users
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card all-card">
                    <div className="text-center">
                      <a className="">
                        <img className="pic-3-sec3" src={Reliable} alt="" />
                      </a>
                    </div>
                    <div className="text-center">
                      <h4>Reliable</h4>
                      <p>
                      Paying of bills and utilities is reliable <br /> using
                        Hometown wallet
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light">
          <div className="container">
            <div className="d-flex align-items-center justify-content-around">
              <div className="col-1"/>
              
              <div className="col-4">
                <img alt="customer-support_image" src={support} width={"100%"} height={"200%"} />
              </div>
              <div className="col-5">
                <div className="text-blue customer-section">
                  <h6 className="section-2-header section ">
                    Customer support you can rely on
                  </h6>
                  <p className="text-dark">
                    If you ever need assistance, our friendly Hometown customer
                    service team is happy to help. Contact us via our phone
                    numbers or email.
                  </p>
                  <Link to="/contact" className="btn-lg bg-success text-light">
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="col-1"/>

            </div>
          </div>
        </section>
        <section className="fifth-section">
          <img className="fifth1-section" src={wave} alt="paymentIcon" />
          <h1 className="text1 text-center ">
            What our customers and staff have to say
          </h1>
          <div className="container">
            <Slider {...settings}>
              <div>
                <div className="row ">
                  <div className="col-sm-6">
                    <div className="testimonial-img">
                      <img
                        style={{ borderRadius: 5 }}
                        className="w-100"
                        src={process.env.PUBLIC_URL + "/testimony1.jpeg"}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <img className="quote-img" src={quote} alt="" />
                    <p
                      className=""
                      style={{ margin: " 0 auto 50px 0", lineHeight: "2.1rem" }}
                    >
                      "The Hometown App has given me an opportunity to save, get
                      loans, spend and bank, all in one place - my phone. And it
                      works for me here in London! I no longer have an issue
                      doing cross border transactions. This is innovative and
                      commendable, particularly coming from Nigeria."
                    </p>
                    <h6>
                      <strong>Fejiro Tracy Perevimone</strong>
                    </h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="row ">
                  <div className="col-sm-6">
                    <div className="testimonial-img">
                      <img
                        style={{ borderRadius: 5 }}
                        className="w-100"
                        src={process.env.PUBLIC_URL + "/testimony2.jpeg"}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <img className="quote-img" src={quote} alt="" />
                    <p
                      className=""
                      style={{ margin: " 0 auto 50px 0", lineHeight: "2.1rem" }}
                    >
                      "At Hometown, our primary focus is to provide a platform
                      where every Nigerian, irrespective of their income level,
                      can have unencumbered access to credit facilities to meet
                      up with their immediate need and allowing them a flexible
                      repayment plan and period, while at the same time allowing
                      the opportunity to save, invest and spend, all in one
                      location."
                    </p>
                    <h6>
                      <strong>Tony Osborg</strong>
                    </h6>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Home.contextType = AppContext;
export default Home;
