import React from "react";
import { Link } from "react-router-dom";
import Amount from "./amount";
import Option from "./option";
import Bank from "./bank";
import Otp from "./otp";
import Url from "./url";
import Card from "./card";
import AppContext from "../../../AppContext";
import axios from "axios/index";
import * as ConstantVar from "../../../constants_var";
// import Verify from './Signup/verify'

class Servies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      amount: "",
      authUrl: localStorage.getItem("authUrl") || "",
      txRef: "",
      amount_ok: false,

      //for adding the amount to deposit to context
      updateAmount: (data) => {
        this.setState({
          amount: data.amount,
          amount_ok: data.amount >= 50,
        });
      },
      //for adding the auth url of a transaction to deposit context
      updateAuthUrl: (link) => {
        this.setState(
          {
            authUrl: link,
          },
          () => {
            //persist token to local storage
            localStorage.setItem("authUrl", link);
          }
        );
      },
      //for adding the reference of a transaction to deposit context
      updateTxRef: (ref) => {
        this.setState({
          txRef: ref,
        });
      },
    };
  }
  componentDidMount() {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "v1/user/getinfo", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            user: response.data.data,
            isLoading: false,
          });
        } else {
          if (window.location.href.includes("dashboard"))
            //invalid
            window.location.href = "/login";
        }
      });
  }
  render() {
    //get deposit stage
    let stage = this.props.match.params.stage;

    return (
      <AppContext.Provider value={this.state}>
        <div>
          {(() => {
            if (stage === "amount") {
              return (
                <div>
                  <Amount />
                  <Link id="gotoError" to="/payment/failed" type="hidden" />
                  <Link id="gotoSuccess" to="/payment/success" type="hidden" />
                </div>
              );
            } else if (stage === "option") {
              return (
                <div>
                  <Option />
                </div>
              );
            } else if (stage === "bank") {
              return (
                <div>
                  <Bank />
                </div>
              );
            } else if (stage === "otp") {
              return (
                <div>
                  <Otp />
                  <Link id="gotoError" to="/payment/failed" type="hidden" />
                  <Link id="gotoSuccess" to="/payment/success" type="hidden" />
                </div>
              );
            } else if (stage === "card") {
              return (
                <div>
                  <Card />
                  <Link
                    id="gotoAuthUrl"
                    to="/dashboard/deposit/url"
                    type="hidden"
                  />
                  <Link
                    id="gotoOtp"
                    to="/dashboard/deposit/otp"
                    type="hidden"
                  />
                </div>
              );
            } else if (stage === "url") {
              return <Url />;
            }
          })()}
        </div>
      </AppContext.Provider>
    );
  }
}
export default Servies;
