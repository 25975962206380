import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import './assets/index.css';
import loanimg1 from "../DashboardHome/assets/img/loan-img.jpg";
import loanimg2 from "../DashboardHome/assets/img/loan-img2.jpg";
import loanimg3 from "../DashboardHome/assets/img/loan-img3.jpg";
import loanimg4 from "../DashboardHome/assets/img/loan-img4.jpg";


class SlickSlider extends Component {

    render() {
        const settings = {
            dots: true,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div  className="ver-m-view">
                <Slider {...settings}>
                    <div>
                        <img src={loanimg3} className="loan-landing-img " />
                    </div>
                    <div>
                        <img src={loanimg2} className="loan-landing-img " />
                    </div>
                    <div>
                        <img src={loanimg4} className="loan-landing-img " />
                    </div>
                    <div>
                        <img src={loanimg1} className="loan-landing-img " />
                    </div>
                </Slider>
            </div>
        );
    }
}

export default SlickSlider;