import React from 'react'
import Details from './Signup/details'
import Verify from './Signup/verify'


class Signup extends React.Component{
  
    constructor(props){
        super(props);

    }
    render(){
        let stage = this.props.match.params.stage;

        if (stage === 'details')
            return(
                <Details/>
            );
        else if (stage === 'verify')
            return(
                <Verify/>
            )
    }
}
export default Signup