import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import Key from '../../homeAssets/key.png'

class Signup extends React.Component{

    render(){

        return(
            <div style={{backgroundColor: "white"}}>
                <Navbar/>
                <section>
                    <div className="container">
                        <img className="fifth1-section" src={Key} alt="paymentIcon" />
                        <div className="row">
                            <div className="col-sm-3"/>
                            <div className=" col-sm-6">
                                <div className="card all-card login-card text-center">
                                    <h3 className="font-weight-bold">
                                        Verify your email address!
                                    </h3>
                                    <p>A link has been sent to the email address you provided, kindly click on it to complete your registration.</p>
                                </div>
                            </div>
                            <div className="col-sm-3"/>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Signup